/* RESPONSIBLE TEAM: team-help-desk-experience */
import type Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type Brand from 'embercom/models/brand';
import type Session from 'embercom/services/session';
export default class BrandService extends Service {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare session: Session;

  @tracked brands: Array<Brand> = [];

  get isPaywallOverrideActive() {
    return this.appService.app.canByPassMultibrandPaywall ? false : undefined;
  }

  get isAppOnRightMultibrandPlan() {
    if (!this.appService?.app) {
      if (this.session.workspace.isFeatureEnabled('multibrand-ignore-paywall')) {
        return true;
      } else if (this.session.workspace.isFeatureEnabled('messenger_multibrand')) {
        return true;
      }
      return false;
    }

    if (this.appService.app.canUseMultibrandBillingFeature) {
      return true;
    } else if (this.appService.app.canByPassMultibrandPaywall) {
      return true;
    }
    return false;
  }

  get hasLoadedBrands() {
    return taskFor(this.loadAllBrands).performCount > 0;
  }

  async loadBrands() {
    return taskFor(this.loadAllBrands).perform();
  }

  @task({ restartable: true }) *loadAllBrands(): TaskGenerator<void> {
    let results = yield this.store.findAll('brand');
    this.brands = results.toArray();
  }

  get isLoading() {
    return taskFor(this.loadAllBrands).isRunning;
  }
}

declare module '@ember/service' {
  interface Registry {
    brandService: BrandService;
  }
}
