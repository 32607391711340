/* RESPONSIBLE TEAM: team-self-serve */
import ajax from 'embercom/lib/ajax';

const checkEmailIsPersonal = async (domain: string): Promise<boolean> => {
  let response: {
    personal_email_domain: boolean;
  } = await ajax({
    url: '/marketing_onboarding/signups/personal_email_domain',
    type: 'GET',
    data: {
      domain,
    },
  });
  return response?.personal_email_domain;
};

export default checkEmailIsPersonal;
