/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { isEmpty } from '@ember/utils';
import { type IntercomCtiData } from 'embercom/objects/phone/cti-types';
import { formatSeconds } from 'embercom/lib/duration-formatter';
import type AdminAwayService from 'embercom/services/admin-away-service';
import type Session from 'embercom/services/session';
import { tracked } from '@glimmer/tracking';

export default class PhoneCallService extends Service {
  @tracked conversationId: number | null = null;

  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare adminAwayService: AdminAwayService;
  @service declare session: Session;
  @service intercomEventService: any;

  async acceptInboundCall(phoneNumber: string) {
    await this.setAdminOnCall();

    try {
      let appId = this.appService.app.id;
      let adminId = this.appService.app.currentAdmin.id;

      let response = await post('/ember/phone_call/accept_inbound', {
        app_id: appId,
        admin_id: adminId,
        phone_number: phoneNumber,
      });

      this.conversationId = Number(response.conversation_id);
      return response.conversation_id;
    } catch (e) {
      if (e.jqXHR && !isEmpty(e.jqXHR.responseJSON) && !isEmpty(e.jqXHR.responseJSON.errors)) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          'There was a problem creating a conversation for this phone call.',
        );
      }
    }
  }

  async acceptOutboundCall(phoneNumber: string) {
    this.setAdminOnCall();

    try {
      let appId = this.appService.app.id;
      let adminId = this.appService.app.currentAdmin.id;

      let response = await post('/ember/phone_call/accept_outbound', {
        app_id: appId,
        admin_id: adminId,
        phone_number: phoneNumber,
      });

      this.conversationId = Number(response.conversation_id);
      return response.conversation_id;
    } catch (e) {
      if (e.jqXHR && !isEmpty(e.jqXHR.responseJSON) && !isEmpty(e.jqXHR.responseJSON.errors)) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          'There was a problem creating a conversation for this phone call.',
        );
      }
    }
  }

  async startEscalationCall(conversationId: number) {
    this.setAdminOnCall();
    this.conversationId = conversationId;

    try {
      let appId = this.appService.app.id;
      let adminId = this.appService.app.currentAdmin.id;

      await post('/ember/phone_call/start_escalation', {
        app_id: appId,
        admin_id: adminId,
        conversation_id: conversationId,
      });
    } catch (e) {
      if (e.jqXHR && !isEmpty(e.jqXHR.responseJSON) && !isEmpty(e.jqXHR.responseJSON.errors)) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          'There was a problem updating the conversation for this phone call.',
        );
      }
    }
  }

  async endCall(metadata: IntercomCtiData) {
    if (!this.conversationId) {
      return;
    }

    try {
      let appId = this.appService.app.id;
      let adminId = this.appService.app.currentAdmin.id;

      this.intercomEventService.trackAnalyticsEvent({
        action: 'cti_call_ended',
        object: 'cti',
        metadata: {
          duration: metadata.payload.duration,
        },
      });

      metadata.payload.duration = formatSeconds(metadata.payload.duration);

      await post('/ember/phone_call/end', {
        app_id: appId,
        admin_id: adminId,
        conversation_id: this.conversationId,
        metadata: metadata.payload,
      });
    } catch (e) {
      if (e.jqXHR && !isEmpty(e.jqXHR.responseJSON) && !isEmpty(e.jqXHR.responseJSON.errors)) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          'There was a problem updating the conversation at the end of this phone call.',
        );
      }
    } finally {
      this.setAdminAvailable();
      this.conversationId = null;
    }
  }

  private async setAdminOnCall() {
    if (!this.session.isWorkspaceLoaded) {
      this.session.switchWorkspace(this.appService.app.id);
    }

    if (!this.session.isTeammateLoaded) {
      await this.session.getTeammate(this.appService.app.id);
    }

    this.adminAwayService.setAdminAsOnACall();
  }

  private setAdminAvailable() {
    if (this.adminAwayService.awayModeEnabled) {
      this.adminAwayService.toggleAwayMode();
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    phoneCallService: PhoneCallService;
    'phone-call-service': PhoneCallService;
  }
}
