/* RESPONSIBLE TEAM: team-ai-agent */
import Service, { inject as service } from '@ember/service';
import type FinFreeUsageWindow from 'embercom/models/fin-free-usage-window';
import { AiAnswersState } from 'embercom/lib/operator/fin/types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { FIN_PRICE_PER_RESOLUTION } from 'embercom/lib/billing';
import moment from 'moment-timezone';

export default class FinTrialService extends Service {
  @service declare appService: $TSFixMe;
  @service declare store: $TSFixMe;

  @tracked showFinRetrialConfirmationModal = false;

  // 15 days to account for the 14 day trial and 1 day to account for the current day
  FIN_FREE_USAGE_WINDOW_LENGTH = 15;

  get isTrialCustomer() {
    return (
      (this.freeTrialNotStarted || this.freeTrialStarted || this.freeTrialExpired) &&
      !this.freeUsageWindow?.hasExtendedFinFreeUsageWindow
    );
  }

  get resolutionPrice() {
    return FIN_PRICE_PER_RESOLUTION;
  }

  get freeUsageWindow(): FinFreeUsageWindow {
    return this.appService.app.finFreeUsageWindow;
  }

  get trialDaysRemaining() {
    return this.freeUsageWindow?.daysRemaining;
  }

  get aiAnswersState(): AiAnswersState {
    return this.appService.app.aiAnswersState;
  }

  get isSalesforceContracted() {
    return this.appService.app.isSalesforceContracted;
  }

  get freeTrialNotStarted() {
    return (
      this.aiAnswersState === AiAnswersState.trialNotStarted ||
      this.aiAnswersState === AiAnswersState.canRequestTrial
    );
  }

  get freeTrialStarted() {
    return this.aiAnswersState === AiAnswersState.trialStarted;
  }

  get freeTrialExpired() {
    return this.aiAnswersState === AiAnswersState.trialExpired;
  }

  get canRequestTrial() {
    return this.aiAnswersState === AiAnswersState.canRequestTrial;
  }

  get showCTAs() {
    if (this.freeTrialExpired) {
      return false;
    }
    return this.isTrialCustomer;
  }

  @action
  openFinRetrialConfirmationModal() {
    if (this.appService.app.aiAnswersState === AiAnswersState.trialNotStarted) {
      this.showFinRetrialConfirmationModal = true;
      // Set the state to trialStarted to ensure the banner is shown correctly
      // The app data should refresh the next time the app is loaded and display based on state from backend
      this.appService.app.set('ai_answers_state', AiAnswersState.trialStarted);
      let now = moment();
      let freeUsageWindow = this.store.createRecord('fin-free-usage-window');
      freeUsageWindow.set('startDate', now.format('YYYY-MM-DD'));
      freeUsageWindow.set(
        'endDate',
        now.add(this.FIN_FREE_USAGE_WINDOW_LENGTH, 'days').format('YYYY-MM-DD'),
      );
      freeUsageWindow.set('state', 1);
      this.appService.app.set('fin_free_usage_window', freeUsageWindow);
    }
  }

  @action
  closeFinRetrialConfirmationModal() {
    this.showFinRetrialConfirmationModal = false;
  }
}

declare module '@ember/service' {
  interface Registry {
    finTrialService: FinTrialService;
    'fin-trial-service': FinTrialService;
  }
}
