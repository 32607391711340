/* RESPONSIBLE TEAM: team-tickets-1 */
import type AwayStatusReason from 'embercom/models/away-status-reason';
import { inject as service } from '@ember/service';
import BaseValidator from 'ember-cp-validations/validators/base';

export default class AwayStatusReasonLabelUnique extends BaseValidator {
  @service appService: any;
  @service store: any;

  validate(value: any, _options: any, model: AwayStatusReason) {
    let allReasons = this.store.peekAll('away-status-reason');
    let results = allReasons.filter((reason: AwayStatusReason) => {
      return reason.label.toLowerCase() === value.toLowerCase() && reason.id !== model.id;
    });

    if (results.length > 0) {
      model.set('duplicateOf', results[0]);
      return false;
    }

    model.set('duplicateOf', null);
    return true;
  }

  static getDependentsFor() {
    return ['model.label', 'model.id'];
  }
}
