/* RESPONSIBLE TEAM: team-frontend-tech */
import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import storage from 'embercom/vendor/intercom/storage';

const NAV_BAR_LOCAL_STORAGE_KEY = 'primary-nav-bar-pinned';
export const NAV_BAR_COLLAPSED_WIDTH_PX = 44;
export const NAV_BAR_EXPANDED_WIDTH_PX = 196;
export const WINDOW_PADDING_RIGHT_PX = 4;

export default class CommonPrimaryNavBarService extends Service {
  @tracked workspaceID: string | null = null;
  @tracked pinned = false;

  initialise(workspaceID: string) {
    this.workspaceID = workspaceID;
    this.pinned = storage.get(`${NAV_BAR_LOCAL_STORAGE_KEY}-${workspaceID}`) ?? false;
  }

  get navBarWidth() {
    if (this.pinned) {
      return NAV_BAR_EXPANDED_WIDTH_PX;
    }
    return NAV_BAR_COLLAPSED_WIDTH_PX;
  }

  @action
  toggleNavBarPinStatus() {
    this.pinned = !this.pinned;
    storage.set(`${NAV_BAR_LOCAL_STORAGE_KEY}-${this.workspaceID}`, this.pinned);
  }
}

declare module '@ember/service' {
  interface Registry {
    CommonPrimaryNavBarService: CommonPrimaryNavBarService;
    'common-primary-nav-bar-service': CommonPrimaryNavBarService;
  }
}
