/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  adminHasSeen,
  adminHasSeenFromPageviews,
  fetchAdminPageviews,
  markViewAsSeen,
} from 'embercom/lib/admin-view-tracking';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';

export default class BannerDismissalService extends Service {
  @service declare store: Store;
  @tracked isAdminPageviewsLoaded = false;

  async loadAdminPageviews() {
    await fetchAdminPageviews(this.store);

    this.isAdminPageviewsLoaded = true;
  }

  @action
  async dismiss(key: string) {
    return await markViewAsSeen(this.store, key);
  }

  @action
  async hasDismissed(key: string): Promise<boolean> {
    if (this.isAdminPageviewsLoaded) {
      return adminHasSeenFromPageviews(this.pageviews, key);
    } else {
      return await adminHasSeen(this.store, key);
    }
  }

  get pageviews() {
    return this.store.peekAll('admin-pageview');
  }
}
