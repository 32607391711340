/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import {
  type EmberCPError,
  toErrorResult,
  type EmberCPValidationsResult,
} from 'embercom/validations/typescript/ember-cp-validations-compat';
import containerLookup from 'embercom/lib/container-lookup';
import type HelpCenterRedirect from 'embercom/models/help-center-redirect';
import { EntityType } from 'embercom/models/data/entity-types';

export function validateHelpCenterRedirect(redirect: HelpCenterRedirect): EmberCPValidationsResult {
  let intl = containerLookup('service:intl');
  let helpCenterService = containerLookup('service:helpCenterService');
  let errors: Array<EmberCPError> = [];

  if (!redirect.fromUrl) {
    errors.push({
      attribute: 'fromUrl',
      message: intl.t(
        'articles.settings.redesign.general.domain.redirects.modify.drawer.url-missing',
      ),
    });
  } else if (!redirect.fromUrl.startsWith(helpCenterService?.site?.url)) {
    errors.push({
      attribute: 'fromUrl',
      message: intl.t(
        'articles.settings.redesign.general.domain.redirects.modify.drawer.url-does-not-match-domain',
        { domain: helpCenterService?.site?.url },
      ),
    });
  }

  if (redirect.entityType === EntityType.ArticleContent && !redirect.articleId) {
    errors.push({
      attribute: 'articleId',
      message: intl.t(
        'articles.settings.redesign.general.domain.redirects.modify.drawer.entity-missing',
      ),
    });
  }

  if (redirect.entityType === EntityType.ArticleCollection && !redirect.collectionId) {
    errors.push({
      attribute: 'collectionId',
      message: intl.t(
        'articles.settings.redesign.general.domain.redirects.modify.drawer.entity-missing',
      ),
    });
  }

  if (!redirect.locale) {
    errors.push({
      attribute: 'locale',
      message: intl.t(
        'articles.settings.redesign.general.domain.redirects.modify.drawer.locale-missing',
      ),
    });
  }

  return toErrorResult(errors);
}
