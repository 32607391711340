/* RESPONSIBLE TEAM: team-phone */

import Service, { inject as service } from '@ember/service';
import { get, post } from 'embercom/lib/ajax';
import { formatSeconds } from 'embercom/lib/duration-formatter';
import type AdminAwayService from 'embercom/services/admin-away-service';
import type Session from 'embercom/services/session';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type PhoneNumber from 'embercom/models/inbox/phone-number';
import { type TaskGenerator } from 'ember-concurrency';
import { enqueueTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';

const LAST_DIALLED_COUNTRY_CODE_KEY = 'last-dialled-country-code';

export type DiallerContext = 'outbound' | 'transfer' | 'conference' | 'warm-transfer';

export interface DiallerConfig {
  toPhoneNumber?: string;
  diallerContext?: DiallerContext;
  returnToCallAction?: () => void;
  analyticsConfig?: {
    place: string;
    [key: string]: string;
  };
}

export default class IntercomCallService extends Service {
  @tracked conversationId: number | null = null;
  @tracked workspacePhoneNumbers: PhoneNumber[] = [];
  @tracked isDiallerOpen = false;
  @tracked diallerContext: DiallerContext = 'outbound';
  @tracked toPhoneNumber?: string;
  @tracked defaultCountryCode?: string;
  @tracked isWorkspacePhoneNumbersLoaded = false;
  @tracked returnToCallAction?: () => void;
  @tracked listeningCallId?: string;
  @tracked nextCallIsTestCall = false;
  @tracked callingEventMetadata = {
    current_pricing_plan: 'not_set',
    paid_phone_numbers_present: false,
    trial_phone_numbers_present: false,
    free_usage_trial_ongoing: false,
  };

  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare adminAwayService: AdminAwayService;
  @service declare session: Session;
  @service declare store: Store;
  @service intercomEventService: any;

  constructor() {
    super(...arguments);
    this.initialize();
  }

  async initialize() {
    this.callingEventMetadata = await this.getCallMetricsSpecificMetadata();
  }

  async startEscalationCall(conversationId: number, externalCallId: string | undefined) {
    this.conversationId = conversationId;

    let appId = this.appService.app.id;
    let adminId = this.appService.app.currentAdmin.id;

    await post('/ember/phone_call/start_escalation', {
      app_id: appId,
      admin_id: adminId,
      conversation_id: conversationId,
      external_call_id: externalCallId,
    });
  }

  async endCall(conversationId: number, duration?: number | null) {
    let metadata = {
      callType: 'outbound',
      duration: duration ? formatSeconds(duration) : null,
    };

    let appId = this.appService.app.id;
    let adminId = this.appService.app.currentAdmin.id;

    await post('/ember/phone_call/end', {
      app_id: appId,
      admin_id: adminId,
      conversation_id: conversationId,
      metadata,
    });

    await this.setEndCallAdminState();
  }

  async stopRecording(conversationId: number) {
    let appId = this.appService.app.id;

    await post('/ember/phone_call/stop_recording', {
      app_id: appId,
      conversation_id: conversationId,
    });
  }

  async startRecording(conversationId: number) {
    let appId = this.appService.app.id;

    await post('/ember/phone_call/start_recording', {
      app_id: appId,
      conversation_id: conversationId,
    });
  }

  async startListeningToCallInProgess(callId: string) {
    this.listeningCallId = callId;

    await post('/ember/phone_call/listen', {
      app_id: this.appService.app.id,
      call_id: callId,
      admin_id: this.appService.app.currentAdmin.id,
    });
  }

  async stopListeningToCallInProgress(callId: string) {
    this.adminAwayService.setAdminAsAvailable();
    this.listeningCallId = undefined;

    await post('/ember/phone_call/listen_stop', {
      app_id: this.appService.app.id,
      call_id: callId,
      admin_id: this.appService.app.currentAdmin.id,
    });
  }

  async setEndCallAdminState() {
    try {
      let callWrapUpSettings = await this.callWrapUpEnabled();
      if (callWrapUpSettings.wrapUpTimeEnabled) {
        await this.adminAwayService.forceAdminConsistency(callWrapUpSettings.wrapUpTimeAmount);
      } else {
        this.adminAwayService.setAdminAsAvailable();
      }
    } catch (error) {
      console.error('Error setting end call admin state', error);
    }
  }

  async setAdminOnCall() {
    if (!this.session.isWorkspaceLoaded) {
      this.session.switchWorkspace(this.appService.app.id);
    }

    if (!this.session.isTeammateLoaded) {
      await this.session.getTeammate(this.appService.app.id);
    }

    this.adminAwayService.setAdminAsOnACall();
  }

  async setAdminAsCoaching() {
    if (!this.session.isWorkspaceLoaded) {
      this.session.switchWorkspace(this.appService.app.id);
    }

    if (!this.session.isTeammateLoaded) {
      await this.session.getTeammate(this.appService.app.id);
    }

    this.adminAwayService.setAdminAsCoaching();
  }

  private async callWrapUpEnabled() {
    let callingSettings = await this.store.queryRecord('settings/calling', {});
    return {
      wrapUpTimeEnabled: callingSettings?.wrapUpTimeEnabled,
      wrapUpTimeUnit: callingSettings?.wrapUpTimeUnit,
      wrapUpTimeAmount: callingSettings?.wrapUpTimeAmount,
    };
  }

  async checkCallingSupport() {
    try {
      let result = await get('/ember/calling_settings/check_pricing_plan', {
        app_id: this.appService.app.id,
      });

      return result.supported;
    } catch (err) {
      return false;
    }
  }

  async getWorkspacePhoneNumbers() {
    this.workspacePhoneNumbers = (await this.store.findAll('inbox/phone-number')).filter(
      (number: PhoneNumber) => number.isActive,
    );
    this.isWorkspacePhoneNumbersLoaded = true;
  }

  openDialler(config: DiallerConfig = {}) {
    this.toPhoneNumber = config?.toPhoneNumber;
    this.diallerContext = config?.diallerContext || 'outbound';

    this.returnToCallAction = config?.returnToCallAction;

    if (config?.analyticsConfig) {
      this.registerCallEvent({
        action: 'opened',
        object: 'dialler',
        section: 'inbox2',
        ...config.analyticsConfig,
      });
    }

    if (!this.toPhoneNumber) {
      this.defaultCountryCode =
        window.localStorage.getItem(LAST_DIALLED_COUNTRY_CODE_KEY) ?? undefined;
    }

    this.isDiallerOpen = true;
  }

  closeDialler(skipReturnAction = false) {
    this.isDiallerOpen = false;
    if (this.returnToCallAction && !skipReturnAction) {
      this.returnToCallAction();
    }

    this.toPhoneNumber = undefined;
    this.diallerContext = 'outbound';
    this.nextCallIsTestCall = false;
    this.toPhoneNumber = undefined;
    this.defaultCountryCode = undefined;
    this.returnToCallAction = undefined;
  }

  saveLastDialledCountry(country?: string) {
    if (!country) {
      return;
    }

    window.localStorage.setItem(LAST_DIALLED_COUNTRY_CODE_KEY, country);
  }

  async findOrCreateContact(phoneNumber: string) {
    return await get('/ember/inbox/phone_numbers/find_or_create_contact', {
      app_id: this.appService.app.id,
      phone_number: phoneNumber,
    });
  }

  async getValidatedPhoneNumber(phoneNumber: string, countryCode: string) {
    let response = await get('/ember/inbox/phone_numbers/validate_phone_number', {
      app_id: this.appService.app.id,
      phone_number: phoneNumber,
      country_code: countryCode.toUpperCase(),
    });
    if (response.success) {
      return response.e164;
    } else {
      return null;
    }
  }

  get hasActiveOutboundPhoneNumber() {
    return this.workspacePhoneNumbers.length > 0;
  }

  async setupFreeUsageExperience() {
    let result = await post('/ember/calling_settings/set_up_free_usage_trial', {
      app_id: this.appService.app.id,
    });

    this.store.pushPayload({ 'calling-phone-number': result });
    return result;
  }

  @enqueueTask
  *registerEventWithForceFetch(basicEventData: any): TaskGenerator<void> {
    this.callingEventMetadata = yield this.getCallMetricsSpecificMetadata();
    this.registerCallEvent(basicEventData);
  }

  async getCallMetricsSpecificMetadata() {
    let callingSettings = await this.store.queryRecord('settings/calling', {});

    return {
      current_pricing_plan: callingSettings?.currentPricingPlan,
      paid_phone_numbers_present: callingSettings?.paidPhoneNumbersPresent,
      trial_phone_numbers_present: callingSettings?.trialPhoneNumbersPresent,
      free_usage_trial_ongoing: callingSettings?.freeUsageTrialOngoing,
    };
  }

  registerCallEvent(basicEventData: any) {
    if (this.callingEventMetadata.current_pricing_plan === 'not_set') {
      taskFor(this.registerEventWithForceFetch).perform(basicEventData);
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      ...basicEventData,
      ...this.callingEventMetadata,
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    intercomCallService: IntercomCallService;
    'intercom-call-service': IntercomCallService;
  }
}
