/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { EntityType } from 'embercom/models/data/entity-types';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    parentFolder: { key: 'parent_entity_id' },
  },
  serialize(snapshot: any, options: any) {
    let json = this._super(snapshot, options);
    json.parent_entity_type ??= EntityType.ContentLibraryFolder.toString();
    return json;
  },
  keyForAttribute(attr: any) {
    if (attr === '_entityType') {
      return 'entity_type';
    }
    return this._super(attr);
  },
});
