/* RESPONSIBLE TEAM: team-help-desk-experience */
import IntlService from 'ember-intl/services/intl';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { isEmpty, isNone } from '@ember/utils';
import { inject as service } from '@ember/service';
import { assert } from '@ember/debug';

import { captureException } from 'embercom/lib/sentry';
import formatList from './intl/-format-list';
import formatDuration from './intl/-format-duration';
import {
  default as formatRelative,
  calculateRefreshSeconds,
  isUsingLegacyFormatRelativeApi,
} from './intl/-format-relative';
import { ensureLocaleFallback } from './intl/-t';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { buildWaiter } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';
import enTranslations from 'embercom/translations/en.json';
import ENV from 'embercom/config/environment';

let waiter = buildWaiter('translation-waiter');

import messengerPreviewTranslations from '@intercom/intercom-messenger-preview-translations/translations-esm.js';

// english is the default locale and is included in the intial payload so we don't need to include it in this list
export const TRANSLATIONS_URL_MAP = {
  'de-DE': assetUrl('/translations/de-de.json'),
  'fr-FR': assetUrl('/translations/fr-fr.json'),
  'es-MX': assetUrl('/translations/es-mx.json'),
  'pt-BR': assetUrl('/translations/pt-br.json'),
  'it-IT': assetUrl('/translations/it-it.json'),
  'ja-JP': assetUrl('/translations/ja-jp.json'),
};

export const BASE_LANG = 'en';
export const DEFAULT_LOCALE = `${BASE_LANG}-US`;
export const SUPPORTED_LANGUAGES = ['en-US', 'de-DE', 'es-MX', 'fr-FR', 'it-IT', 'ja-JP', 'pt-BR'];
const FEATURE_FLAGGED_LANGUAGES = ['it-IT', 'ja-JP'];

export const FEATURE_OVERRIDES = {
  // You can add a {feature-flag} : {overrides-file-name} pair in here to easily override translations
  // behind a FF (for more context see: https://github.com/intercom/embercom/pull/68710)
};

export { calculateRefreshSeconds, isUsingLegacyFormatRelativeApi };

function upcase(s) {
  return s[0].toUpperCase() + s.slice(1);
}

class UnsupportedLocaleError extends Error {
  locale;

  constructor(locale) {
    super(`Attempting to switch to an unsupported locale ${locale}`);
    this.locale = locale;
  }
}

export default class CustomIntlService extends IntlService {
  @service router;
  @service appService;
  @service reactTeammateAppIsland;

  messengerTranslationsLoaded = false;
  @tracked embercomTranslationsLoaded = [];

  timezoneFallbackMappings = {
    'Europe/Kiev': 'Europe/Kyiv',
    'Europe/Kyiv': 'Europe/Kiev',
    'America/Ciudad_Juarez': 'America/Ojinaga',
  };

  languageNames = new Intl.DisplayNames(['en'], { type: 'language' });

  init() {
    super.init(...arguments);
    this.loadBaseTranslations();
    this.embercomTranslationsLoaded = [BASE_LANG.toLowerCase()];
    this.setLocale([BASE_LANG]);
  }

  loadBaseTranslations() {
    this.addTranslations(BASE_LANG, enTranslations);
  }

  get supportedLocales() {
    if (this.appService.app?.canUseFeature?.('team-frontend-tech-new-languages')) {
      return SUPPORTED_LANGUAGES;
    }
    return SUPPORTED_LANGUAGES.filter((locale) => !FEATURE_FLAGGED_LANGUAGES.includes(locale));
  }

  get supportedLanguages() {
    return this.supportedLocales.map((locale) => {
      let [lang, region] = locale.split('-');

      return {
        locale,
        language: `${upcase(
          new Intl.DisplayNames([locale], { type: 'language' }).of(lang),
        )} (${region})`,
      };
    });
  }

  get hasLoadedCurrentLocale() {
    return this.hasLoadedLocale(this.primaryLocale);
  }

  hasLoadedLocale(locale) {
    return this.embercomTranslationsLoaded.includes(this.canonicalLocaleName(locale).toLowerCase());
  }

  setLocale(localeName) {
    super.setLocale(localeName);

    if (this.primaryLocale) {
      this.languageNames = new Intl.DisplayNames([this.primaryLocale], { type: 'language' });
    }

    this.reactTeammateAppIsland.dispatchAppStateChangeEvent({
      locale: this.primaryLocale,
    });
  }

  async switchLocale(locale) {
    try {
      await this.fetchTranslationsFor(locale);
      let locales = locale === BASE_LANG ? [locale] : [locale, BASE_LANG];
      this.setLocale(locales);
    } catch (e) {
      if (e instanceof UnsupportedLocaleError) {
        console.error(e);
        captureException(e, {
          fingerprint: ['custom-intl-service', 'switchLocale', locale],
          tags: {
            responsibleTeam: 'team-frontend-tech',
            responsible_team: 'team-frontend-tech',
          },
        });
      } else {
        console.error(`Error fetching translations file for locale: ${locale}`, e);
        captureException(new Error(`Error fetching translations file for locale: ${locale}`), {
          fingerprint: ['custom-intl-service', 'switchLocale', 'error-fetching-locale', locale],
          tags: {
            responsibleTeam: 'team-frontend-tech',
            responsible_team: 'team-frontend-tech',
          },
        });
      }
    }
  }

  canonicalLocaleName(locale) {
    return locale.toLowerCase() === 'en-us' ? BASE_LANG : locale;
  }

  async fetchTranslationsFor(locale) {
    let lookupLocale = this.canonicalLocaleName(locale);

    if (this.hasLoadedLocale(lookupLocale)) {
      return;
    }

    let url = TRANSLATIONS_URL_MAP[lookupLocale];

    if (!url) {
      throw new UnsupportedLocaleError(lookupLocale);
    }

    let translationsForLocale = await this._fetch(url);

    this.addTranslations(locale, translationsForLocale);

    this.embercomTranslationsLoaded = [
      ...this.embercomTranslationsLoaded,
      lookupLocale.toLowerCase(),
    ];
  }

  // mainly split out to make this easier to mock in tests
  // as we passthrough the translations requests and so it's
  // hard to use mirage/pretender to mock the requests again
  async _fetch(url) {
    let token = waiter.beginAsync();
    let response;
    let translationsForLocale;
    try {
      response = await fetch(url);
      translationsForLocale = await response.json();
    } finally {
      waiter.endAsync(token);
    }
    return translationsForLocale;
  }

  maybeLoadMessengerTranslations() {
    if (this.messengerTranslationsLoaded) {
      return;
    }

    Object.entries(messengerPreviewTranslations).forEach(([locale, strings]) => {
      this.addTranslations(locale, strings);
    });

    this.messengerTranslationsLoaded = true;
  }

  generateOverrideKey(feature, key) {
    return `${FEATURE_OVERRIDES[feature]}.${key}`;
  }

  maybeUseOverride(key) {
    let availableOverrideKey = Object.keys(FEATURE_OVERRIDES)
      .filter((feature) => this.appService.app?.canUseFeature?.(feature))
      .map((feature) => this.generateOverrideKey(feature, key))
      .find((overrideKey) => super.exists(overrideKey, BASE_LANG));

    return availableOverrideKey || key;
  }

  /**
   * @param {string | undefined} key
   * @param {any} options
   * @returns {string}
   */
  t(key, options = {}) {
    let translation = this.lookup(key);
    let named = Object.assign({}, options);

    if (translation && translation.includes('{')) {
      let ast = this.lookupAst(key);
      for (let i = 0; i < ast.length; i++) {
        let part = ast[i];

        // @formatjs requires all context params be present even if undefined
        if (part.type === 1 && !(part.value in options)) {
          if (ENV.environment === 'production') {
            // send error to Sentry in production && replace with empty string

            captureException(
              new Error(`Missing value for "${part.value}" in translation "${key}"`),
              {
                tags: {
                  responsibleTeam: 'team-frontend-tech',
                  responsible_team: 'team-frontend-tech',
                },
              },
            );
            named[part.value] = '';
            options = { ...named };
          }
        }
      }
    }

    assert(
      `You must specify a locale for messenger preview translations (given "${key}")`,
      !(isNone(options.locale) && key in messengerPreviewTranslations.en),
    );

    if (!isNone(options.locale)) {
      let fallbackLocales = this.locale;
      options.locale = ensureLocaleFallback(options.locale, fallbackLocales, BASE_LANG);
    }

    let val = super.t(this.maybeUseOverride(key), options);

    if (options.htmlSafe) {
      val = sanitizeHtml(val.toString());
    }

    let show_l10n = this.router._router?.currentRoute?.queryParams.show_l10n;

    if (show_l10n) {
      let translationNotFound = /Missing translation.+? for locale.*?/g.test(val);
      let character = translationNotFound ? '❗️' : '🐹 ';
      let length = translationNotFound ? 6 : val.toString().length;
      return character.repeat(Math.floor(length / 2)).trim();
    }

    let show_l10n_keys = this.router._router?.currentRoute?.queryParams.show_l10n_keys;

    if (show_l10n_keys) {
      return key;
    }

    return val;
  }

  formatList(/*list, options = {}*/) {
    return formatList.apply(this, arguments);
  }

  // overridden to handle browsers which haven't got the latest timezone database
  // and so fail to handle changes such as Europe/Kyiv being renamed from Europe/Kiev
  formatTime(time, options = {}) {
    try {
      return super.formatTime(time, options);
    } catch (e) {
      if (e instanceof RangeError && options?.timeZone) {
        let alternative = this.timezoneFallbackMappings[options.timeZone];
        if (alternative) {
          options = { ...options, timeZone: alternative };
          return super.formatTime(time, options);
        }
      }
      throw e;
    }
  }

  // As per notes on formatTime
  formatDate(date, options = {}) {
    try {
      return super.formatDate(date, options);
    } catch (e) {
      if (e instanceof RangeError && options?.timeZone) {
        let alternative = this.timezoneFallbackMappings[options.timeZone];
        if (alternative) {
          options = { ...options, timeZone: alternative };
          return super.formatDate(date, options);
        }
      }
      throw e;
    }
  }

  formatRelative(value, options = {}) {
    let legacy = isUsingLegacyFormatRelativeApi(options);

    assert(
      'You are using a legacy API for formatting relative times. Please refer to our [relative formatting localization guide](https://coda.io/d/_duvlW1VbQXK/Guidance_suFHS#_luY_p) for more info',
      !legacy,
      {
        id: 'intl.use-of-legacy-format-relative',
      },
    );

    return formatRelative.apply(this, arguments);
  }

  formatDuration(/*value, options = {}*/) {
    return formatDuration.apply(this, arguments);
  }

  formatNumber(value, options = {}) {
    if (isEmpty(value) || isNaN(value)) {
      value = options.fallbackValue || 0;
      if (isNaN(value)) {
        return value;
      }
    }
    return super.formatNumber.apply(this, [value, options]);
  }

  languageNameFromCode(languageCode) {
    try {
      return this.languageNames.of(languageCode);
    } catch {
      return '';
    }
  }
}

let cachedTranslationModels;

export function clearCachedTranslationModels() {
  cachedTranslationModels = undefined;
}

export class CachedCustomIntlService extends CustomIntlService {
  loadBaseTranslations() {
    if (cachedTranslationModels) {
      this._translationContainer._translationModels = cachedTranslationModels;
    } else {
      super.loadBaseTranslations(...arguments);
      cachedTranslationModels = this._translationContainer._translationModels;
    }
  }

  addTranslations() {
    super.addTranslations(...arguments);
    // if a test modifies the translations then we want to clear the cache to
    // to prevent other tests from being affected
    cachedTranslationModels = undefined;
  }
}
