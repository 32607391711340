/* RESPONSIBLE TEAM: team-knowledge-foundations */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default class ArticleContent extends EmbercomBaseSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    folder: { key: 'folder_id' },
    folderMembership: { embedded: 'always' },
  };
  serialize(content: any) {
    let json = super.serialize(...arguments);

    json._isDirty = content.record.get('hasChanges');

    return json;
  }
}
