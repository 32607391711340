/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    contents: { embedded: 'always' },
    folderMembership: { embedded: 'always' },
    tags: { key: 'tag_ids' },
  },
});
