/* RESPONSIBLE TEAM: team-tickets-1 */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    listOptions: { embedded: 'always' },
    relationship: { embedded: 'always' },
    reference: { embedded: 'always' },
    conditions: { embedded: 'always' },
    dependentConditions: { embedded: 'always', key: 'dependent_conditions' },
  },
  normalize(modelClass, resourceHash) {
    if (resourceHash.list_options) {
      resourceHash.list_options.forEach((option) => {
        option.list_option_id = option.id;
        delete option.id;
      });
    }
    return this._super(modelClass, resourceHash);
  },

  serialize(snapshot, options) {
    let json = this._super(snapshot, options);
    json.list_options.forEach((option) => {
      option.id = option.list_option_id;
      delete option.list_option_id;
    });
    return json;
  },
});
