/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    latestImportRun: { embedded: 'always' },
    previousImportRun: { embedded: 'always' },
    removeCssSelectors: 'remove_element_css_selectors',
    clickCssSelector: 'click_element_css_selector',
  },
});
