/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';
import { FieldMetric } from 'embercom/objects/reporting/unified/metrics/types';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { flatten, pick } from 'underscore';
import { type Attribute } from 'embercom/objects/reporting/unified/datasets/types';
import { dropTask } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';
import type IntlService from 'embercom/services/intl';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import { type LogicalFilter } from 'embercom/components/reporting/custom/filters';
import { type FlexibleQueryTimeRange } from 'embercom/services/reporting-csv-export';
import ajax from 'embercom/lib/ajax';
import { isPresent } from '@ember/utils';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
const ADMIN_STATUS_CHANGE = 'admin_status_change';
const CONVERSATION_STATE = 'conversation_state';

export const CACHE_KEY = 'reporting.columnAttributesSelected';

export interface DrillInResult {
  data: Record<string, string>[];
  totalHits: number;
}

export interface AttributeMapping {
  [key: string | number]: string | Record<string, any>;
}

export default class ReportingUnderlyingDataService extends Service {
  @service declare appService: any;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare intl: IntlService;

  attributeMappings: Record<Attribute['id'], AttributeMapping> = {};

  getSuggestedAttributeIds(metric: Metric, isStandalone = false): string[] {
    // TODO find a better way of including id attributes in drill in
    let datasetAttributes = this.reportingMetrics.getDatasetAttributesFor(metric.datasetId);
    let idAttributes = [
      'conversation.id',
      'conversation_part.comment_id',
      'conversation_sla_status_log.triggering_comment_id',
    ].filter((id) => datasetAttributes.some((attribute) => attribute.id === id));

    return [
      ...idAttributes,
      metric.timeAttribute?.id,
      metric instanceof FieldMetric ? metric.attribute?.id : null,
      ...metric.getSuggestedAttributeIds(isStandalone),
    ]
      .compact()
      .uniq();
  }

  getDefaultColumns(
    chartSeries: ChartSeries,
    renderableChart: RenderableChart,
    isStandalone = false,
  ): string[] {
    if (isPresent(renderableChart.defaultDrillInAttributes)) {
      return renderableChart.defaultDrillInAttributes;
    }

    let metric = chartSeries.metric;
    let datasetId = metric.allFieldMetrics[0].datasetId;

    // TODO remove this once `viewBy` and `segmentBy` are attribute ids
    let viewByAttributeId = this.reportingMetrics.getAttributeByField(
      renderableChart.viewBy,
      datasetId,
    )?.id;
    let segmentByAttributeId = this.reportingMetrics.getAttributeByField(
      renderableChart.segmentBy,
      datasetId,
    )?.id;

    return flatten([
      this.getSuggestedAttributeIds(metric, isStandalone),
      viewByAttributeId,
      segmentByAttributeId,
    ])
      .compact()
      .uniq();
  }

  getDatasetDefaultColumns(datasetId: string): string[] {
    return this.reportingMetrics
      .getDatasetById(datasetId)
      .suggestedAttributes.map((attribute) => attribute.id);
  }

  getOrderedAttributeIds(metric: Metric, currentSelection: string[], previousSelection: string[]) {
    let desiredOrder = [
      'conversation.id',
      'conversation_part.comment_id',
      'conversation_sla_status_log.triggering_comment_id',
      metric.timeAttribute?.id,
      metric instanceof FieldMetric ? metric.attribute?.id : null,
      ...previousSelection,
    ]
      .compact()
      .uniq();

    let additionalProperties = currentSelection.filter((column) => !desiredOrder.includes(column));
    let sortedColumns = currentSelection.filter((column) => !additionalProperties.includes(column));

    sortedColumns = sortedColumns.sort((a, b) => {
      let indexA = desiredOrder.indexOf(a);
      let indexB = desiredOrder.indexOf(b);
      return indexA - indexB;
    });

    return [...sortedColumns, ...additionalProperties].uniq();
  }

  getOrderedDatasetAttributeIds(
    currentSelection: string[],
    previousSelection: string[],
    datasetId: string,
  ) {
    let desiredOrder = [
      'conversation.id',
      this.getDatasetTimeAttribute(datasetId),
      ...previousSelection,
    ]
      .compact()
      .uniq();

    let additionalProperties = currentSelection.filter((column) => !desiredOrder.includes(column));
    let sortedColumns = currentSelection.filter((column) => !additionalProperties.includes(column));

    sortedColumns = sortedColumns.sort((a, b) => {
      let indexA = desiredOrder.indexOf(a);
      let indexB = desiredOrder.indexOf(b);
      return indexA - indexB;
    });

    return [...sortedColumns, ...additionalProperties].uniq();
  }

  getSortByAttributeId(datasetId: string, metric?: Metric) {
    if (datasetId === ADMIN_STATUS_CHANGE) {
      return 'admin_status_change.period_started_at';
    } else if (datasetId === CONVERSATION_STATE) {
      return 'conversation_state.state_start';
    } else if (metric) {
      return metric.timeAttribute!.id;
    } else {
      return this.getDatasetTimeAttribute(datasetId);
    }
  }

  getDatasetTimeAttribute(datasetId: string): string {
    let dataset = this.reportingMetrics.getDatasetById(datasetId);
    return dataset.timeAttributeForKnownValues.id;
  }

  @dropTask({ enqueue: true, maxConcurrency: 12 })
  *loadAttributeMappings(
    attributeId: Attribute['id'],
    datasetId: string,
    includeAllValues: boolean,
  ) {
    if (this.attributeMappings[attributeId] === undefined) {
      this.attributeMappings[attributeId] = yield taskFor(this.performMappingLoad).perform(
        attributeId,
        includeAllValues,
        datasetId,
      );
    }
  }

  @dropTask({ enqueue: true, maxConcurrency: 12 })
  *searchAttributeMappings(
    attributeId: Attribute['id'],
    searchTerm: string | null = null,
  ): TaskGenerator<AttributeMapping> {
    let searchResults: AttributeMapping = yield taskFor(this.performMappingLoad).perform(
      attributeId,
      true,
      null,
      searchTerm,
    );
    // cache the results
    this.attributeMappings[attributeId] ||= {};
    this.attributeMappings[attributeId] = {
      ...this.attributeMappings[attributeId],
      ...searchResults,
    };
    return searchResults;
  }

  @dropTask({ enqueue: true, maxConcurrency: 2 })
  *mapAttributeValues(
    attributeId: Attribute['id'],
    valuesToMap: any[],
  ): TaskGenerator<AttributeMapping> {
    if (valuesToMap.length === 0) {
      return {};
    }
    // Check if we already have the mappings loaded
    if (this.attributeMappings[attributeId]) {
      if (valuesToMap.every((value) => this.attributeMappings[attributeId][value])) {
        return pick(this.attributeMappings[attributeId], valuesToMap) as AttributeMapping;
      }
    }

    let results: AttributeMapping = yield taskFor(this.performMappingLoad).perform(
      attributeId,
      true,
      null,
      null,
      valuesToMap,
    );
    // cache the results
    this.attributeMappings[attributeId] ||= {};
    this.attributeMappings[attributeId] = {
      ...this.attributeMappings[attributeId],
      ...results,
    };
    return results;
  }

  @dropTask({ enqueue: true, maxConcurrency: 12 })
  *performMappingLoad(
    attributeId: Attribute['id'],
    includeAllValues: boolean,
    datasetId: string | null,
    searchTerm: string | null = null,
    valuesToMap: any[] | null = null,
  ): TaskGenerator<AttributeMapping> {
    try {
      return yield get('/ember/reporting/known_attribute_values', {
        app_id: this.appService.app.id,
        attribute_id: attributeId,
        dataset_id: datasetId,
        locale: Array.from(this.intl.locale)[0],
        include_all_values: includeAllValues,
        filter: searchTerm,
        ids: valuesToMap,
      }) as AttributeMapping;
    } catch (error) {
      if (error.errorThrown === 'Forbidden') {
        // If we lack permissions to fetch known values, we should just return nothing.
        console.warn(
          'Forbidden from fetching known values for datasetId: %s, and attributeId: %s',
          datasetId,
          attributeId,
        );
        this.attributeMappings[attributeId] = {};
      } else {
        console.error(
          'Failed to load attribute mappings for attributeId: %s, datasetId: %s',
          attributeId,
          datasetId,
          error,
        );
      }
      return {};
    }
  }

  getAttributeKnownValues(attributeId: Attribute['id']) {
    if (!this.attributeMappings[attributeId]) {
      console.error('Attribute mappings not loaded for attribute: %s', attributeId);
      return {};
    }
    return this.attributeMappings[attributeId];
  }

  mappedValueFor(rawValue: string | number, attributeId: Attribute['id']) {
    if (!this.attributeMappings[attributeId]) {
      console.error('Attribute mappings not loaded for attribute: %s', attributeId);
      return null;
    } else {
      let mappedValue = this.attributeMappings[attributeId][rawValue];
      if (mappedValue === undefined) {
        console.error('No mapping found for value: %s, with attribute %s', rawValue, attributeId);
        return this.intl.t('reporting.unknown-attribute-value');
      } else {
        return mappedValue;
      }
    }
  }

  private cacheKey(chartId: string, chartSeries: ChartSeries) {
    return `${CACHE_KEY}_${chartId}_${chartSeries.metricId}`;
  }

  private datasetCacheKey(datasetId: string) {
    return `${CACHE_KEY}_${datasetId}`;
  }

  setColumnSelectionInCache(chartId: string, chartSeries: ChartSeries, columns: string[]) {
    localStorage.setItem(this.cacheKey(chartId, chartSeries), columns.join(','));
  }

  loadSelectedColumnFromCache(chartId: string, chartSeries: ChartSeries): string[] {
    return localStorage.getItem(this.cacheKey(chartId, chartSeries))?.split(',') ?? [];
  }

  setDatasetColumnSelectionInCache(datasetId: string, columns: string[]) {
    localStorage.setItem(this.datasetCacheKey(datasetId), columns.join(','));
  }

  loadSelectedDatasetColumnFromCache(datasetId: string): string[] {
    return localStorage.getItem(this.datasetCacheKey(datasetId))?.split(',') ?? [];
  }

  async loadDrillInResult(
    appId: string,
    datasetId: string,
    filter: LogicalFilter,
    pageFrom: number,
    pageSize: number,
    drillInTimeRange: FlexibleQueryTimeRange,
    attributeIds: string[],
    sortBy: string,
    sortDirection: 'asc' | 'desc',
    useSyntheticData: string,
    timezone: string,
    isBeingRenderedAsAChart: boolean,
  ): Promise<DrillInResult> {
    let params: Record<string, string> = {
      app_id: appId,
      dataset_id: datasetId,
      filter: JSON.stringify(filter),
      page_from: JSON.stringify(pageFrom),
      page_size: JSON.stringify(pageSize),
      time: JSON.stringify(drillInTimeRange),
      attribute_ids: JSON.stringify(attributeIds),
      sort_by_attribute_id: sortBy,
      sort_direction: sortDirection,
      use_synthetic_data: useSyntheticData,
      time_zone: timezone,
    };

    let response;
    if (isBeingRenderedAsAChart) {
      response = await ajax({
        url: `/ember/reporting/documents/data_table_drill_in?${new URLSearchParams(params).toString()}`,
        type: 'GET',
      });
    } else {
      response = await ajax({
        url: `/ember/reporting/documents/drill_in?${new URLSearchParams(params).toString()}`,
        type: 'GET',
      });
    }

    return {
      data: response.data,
      totalHits: response.total_hits,
    };
  }
}
