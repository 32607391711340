/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';
import type KnowledgeHubEditorService from './knowledge-hub-editor-service';
import type IntercomConfirmService from './intercom-confirm-service';
import type IntlService from 'ember-intl/services/intl';
import type Controller from '@ember/controller';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { isBlank } from '@ember/utils';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import type InternalArticle from 'embercom/models/content-service/internal-article';
import type ArticleContent from 'embercom/models/articles/article-content';
export const DRAWER_EDITOR_QUERY_PARAMS = [
  'activeContentId',
  'activeContentType',
  'editorMode',
  'folderId',
  'collectionId',
  'contentLocale',
];

const DRAWER_EDITOR_FULLSCREEN_KEY = 'is_drawer_editor_fullscreen';

export default class KnowledgeHubDrawerEditorService extends Service {
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare intercomConfirmService: IntercomConfirmService;

  @trackedInLocalStorage({ keyName: DRAWER_EDITOR_FULLSCREEN_KEY, defaultValue: false })
  isFullScreen = false;
  isNewContent = false;

  get editorMode(): EditorMode | null {
    if (this.router?.currentRoute?.queryParams?.editorMode) {
      return this.router.currentRoute.queryParams.editorMode as EditorMode;
    }

    return null;
  }

  @action
  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }

  @action
  async closeDrawer() {
    if (this.isNewContent) {
      if (this.isEmptyContent()) {
        await this.knowledgeHubEditorService.deleteActiveContent();
        this.removeQueryParams();
        return;
      }
    }

    if (!this.hasDirtyActiveContent()) {
      this.removeQueryParams();
      return;
    }

    let confirmationResult = await this.confirmClose();
    if (confirmationResult === true) {
      await this.knowledgeHubEditorService.saveActiveContent({ redirectToView: false });
      this.removeQueryParams();
    } else if (confirmationResult.canceled) {
      this.knowledgeHubEditorService.cancelEditActiveContent({ redirectToView: false });
      this.removeQueryParams();
    } else if (confirmationResult.closed) {
      return;
    }
  }

  isEmptyContent() {
    let activeContent = this.knowledgeHubEditorService.activeContentModel;
    if (!activeContent) {
      return false;
    }
    let title = activeContent.title;
    let isDefaultTitle = isBlank(title) || title === activeContent.defaultTitle;

    if (this.knowledgeHubEditorService.activeContentIsContentSnippet) {
      let contentSnippet = activeContent as ContentSnippet;
      let hasBody = contentSnippet.jsonBlocksHasContent;
      return isDefaultTitle && !hasBody;
    }

    if (this.knowledgeHubEditorService.activeContentIsInternalArticle) {
      let internalArticle = activeContent as InternalArticle;
      let hasBody =
        internalArticle.currentVersion?.jsonBlocks &&
        internalArticle.currentVersion.jsonBlocks.length > 0;
      return isDefaultTitle && !hasBody;
    }

    if (this.knowledgeHubEditorService.activeContentIsPublicArticle) {
      let articleContent = activeContent as unknown as ArticleContent;
      let hasBody = articleContent.jsonBlocks && articleContent.jsonBlocks.length > 0;
      return isDefaultTitle && !hasBody;
    }

    return false;
  }

  @action
  openViewDrawer({
    activeContentId,
    activeContentType,
    routeName,
  }: {
    activeContentId: number | string;
    activeContentType: string;
    routeName?: string;
  }) {
    let queryParams = {
      activeContentId,
      activeContentType,
      editorMode: EditorMode.VIEW,
    };

    this.knowledgeHubEditorService.trackAnalyticsEvent('view', 'knowledge_hub_in_context_editor');
    this.isNewContent = false;
    if (routeName) {
      this.router.transitionTo(routeName, {
        queryParams,
      });
    } else {
      this.router.transitionTo({
        queryParams,
      });
    }
  }

  @action
  openEditDrawer({
    activeContentId,
    activeContentType,
    routeName,
  }: {
    activeContentId: number | string;
    activeContentType: string;
    routeName?: string;
  }) {
    let queryParams = {
      activeContentId,
      activeContentType,
      editorMode: EditorMode.EDIT,
    };

    this.knowledgeHubEditorService.trackAnalyticsEvent('edit', 'knowledge_hub_in_context_editor');

    if (routeName) {
      this.router.transitionTo(routeName, {
        queryParams,
      });
    } else {
      this.router.transitionTo({
        queryParams,
      });
    }
  }

  @action
  openReviewDrawer({
    activeContentId,
    activeContentType,
    routeName,
  }: {
    activeContentId: number | string;
    activeContentType: string;
    routeName?: string;
  }) {
    let queryParams = {
      activeContentId,
      activeContentType,
      editorMode: EditorMode.REVIEW,
    };

    this.knowledgeHubEditorService.trackAnalyticsEvent('review', 'knowledge_hub_in_context_editor');
    this.isNewContent = false;
    if (routeName) {
      this.router.transitionTo(routeName, {
        queryParams,
      });
    } else {
      this.router.transitionTo({
        queryParams,
      });
    }
  }

  @action
  createNewContent({
    activeContentType,
    collectionId,
    contentLocale,
    folderId,
    folder_id,
    routeName,
    openInNewTab,
  }: {
    activeContentType: string;
    collectionId?: string;
    contentLocale?: string;
    folderId?: string;
    folder_id?: string;
    routeName?: string;
    openInNewTab?: boolean;
  }) {
    folderId = folderId ?? folder_id;

    this.isNewContent = true;

    let queryParams = {
      editorMode: EditorMode.NEW,
      activeContentType,
      ...(collectionId ? { collectionId } : {}),
      ...(contentLocale ? { contentLocale } : {}),
      ...(folderId ? { folderId } : {}),
    };

    if (openInNewTab && routeName) {
      safeWindowOpen(this.router.urlFor(routeName, { queryParams }), '_blank');
    } else if (routeName) {
      this.router.transitionTo(routeName, {
        queryParams,
      });
    } else {
      this.router.transitionTo({
        queryParams,
      });
    }
  }

  hasDirtyActiveContent() {
    if (this.knowledgeHubEditorService.activeContentModel) {
      return this.knowledgeHubEditorService.activeContentModel.hasUnsavedChanges;
    }

    return false;
  }

  confirmClose() {
    return this.intercomConfirmService.confirm({
      title: this.intl.t('knowledge-hub.content-editor.drawer-editor.confirmation-dialog.title'),
      body: this.intl.t('knowledge-hub.content-editor.drawer-editor.confirmation-dialog.body'),
      confirmButtonText: this.intl.t(
        'knowledge-hub.content-editor.drawer-editor.confirmation-dialog.confirmButtonText',
      ),
      cancelButtonText: this.intl.t(
        'knowledge-hub.content-editor.drawer-editor.confirmation-dialog.cancelButtonText',
      ),
      distinguishCancelFromClose: true,
    });
  }

  // This is a workaround to remove the drawer editors query params when transitioning to a new route
  // This is needed because query params in ember are sticky and not being removed when transitioning to a new route
  // When you transition away and come back, the query params are still there and the drawer is opened (which is not what we want)
  // https://guides.emberjs.com/v3.7.0/routing/query-params/#toc_sticky-query-param-values
  removeEmberStickyQueryParams(
    controller: Controller & {
      activeContentId?: number;
      activeContentType?: string;
      editorMode?: EditorMode;
    },
  ) {
    controller.set('activeContentId', null);
    controller.set('activeContentType', null);
    controller.set('editorMode', null);
  }

  private removeQueryParams() {
    this.knowledgeHubEditorService.trackAnalyticsEvent('close', 'knowledge_hub_in_context_editor');
    this.isNewContent = false;
    this.router.transitionTo({
      queryParams: {
        activeContentId: null,
        activeContentType: null,
        editorMode: null,
        collectionId: null,
        contentLocale: null,
        folderId: null,
      },
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
    'knowledge-hub-drawer-editor-service': KnowledgeHubDrawerEditorService;
  }
}
