/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Service from '@ember/service';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type AiContentSegment from 'embercom/models/ai-content-segment';

export const MAX_NB_CHARS_FOR_AUDIENCES_NAME = 20;

export default class AiContentSegmentsService extends Service {
  @service declare store: Store;

  @tracked segments: Array<AiContentSegment> = [];

  get hasLoadedSegments() {
    return taskFor(this.loadContentSegments).performCount > 0;
  }

  async loadSegments() {
    return taskFor(this.loadContentSegments).perform();
  }

  async refreshSegments() {
    return taskFor(this.refreshContentSegments).perform();
  }

  @task({ restartable: true }) *loadContentSegments(): TaskGenerator<void> {
    let results = yield this.store.findAll('ai-content-segment');
    this.segments = results.toArray();
  }

  @task({ restartable: true }) *refreshContentSegments(): TaskGenerator<void> {
    let results = yield this.store.peekAll('ai-content-segment');
    this.segments = results.rejectBy('isNew').toArray();
  }

  get isLoading() {
    return taskFor(this.loadContentSegments).isRunning;
  }
}

declare module '@ember/service' {
  interface Registry {
    aiContentSegmentsService: AiContentSegmentsService;
    'ai-content-segments-service': AiContentSegmentsService;
  }
}
