/* RESPONSIBLE TEAM: team-knowledge-foundations */
export default function (tmap) {
  let options = { duration: 275, easing: 'easeOutCubic' };

  tmap.transition(
    tmap.hasClass('animate__articles-site-navigation-stack'),
    tmap.toValue((toValue, fromValue) => toValue > fromValue),
    tmap.use('toLeft', options),
    tmap.reverse('toRight', options),
  );
}
