/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import type Session from './session';
import { get, put } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { type NexusEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { NexusEventName, NexusStatusEvents } from 'embercom/services/nexus';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import storage from 'embercom/vendor/intercom/storage';
import type Inbox2AssigneeSearch from './inbox2-assignee-search';
import { type ChannelAvailabilityCode } from './inbox2-teammate-activity-service';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { LbaTriggerEvent } from 'embercom/services/lba-metrics-service';
import { later, cancel } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import type LogService from 'embercom/services/log-service';
import { AwayStatusReason } from 'embercom/objects/inbox/away-reason';
import HasFeatureHelper from 'embercom/helpers/has-feature';

interface AwaySettingsWireFormat {
  away_status_reason: string;
  away_status_reason_id: string;
  away_mode_enabled: boolean;
  reassign_conversations: boolean;
  default_assignee_id: number;
  is_auto_changed: boolean;
  auto_changed_time: any;
  channel_availability: string;
}

enum SupportedOperations {
  ToggleAwayMode,
  TogglereassignConversations,
  SetAwayReason,
  SetAvailableChannel,
  SetAvailableChannelForTeammate,
}

export enum PhoneAwayStatusReasonTypes {
  OnACall = 'on_a_call',
  WrapUp = 'wrap_up',
  Coaching = 'coaching',
}

const LegacyPhoneReasonTypeToReasonMap = {
  [PhoneAwayStatusReasonTypes.OnACall]: '📞 On a call',
  [PhoneAwayStatusReasonTypes.WrapUp]: '📝 Call wrap up',
  [PhoneAwayStatusReasonTypes.Coaching]: '🧑‍🏫 Coaching',
};

export const AWAY_REASONS = [
  '🧑‍🏫 Coaching',
  '😌 On a break',
  '🍔 On lunch',
  '🗓️ In a meeting',
  '📞 On a call',
  '📝 Call wrap up',
  '🏡 Done for the day',
  '🌴 On holidays',
  '🤒 Out sick',
];
export type AwayReason = (typeof AWAY_REASONS)[number] | null;

export const HAS_CHANGED_OWN_STATUS_KEY = 'has_changed_own_away_status';
export const SILENCE_AWAY_WARNING_KEY = 'silence_away_mode_warning';

export default class AdminAwayService extends Service {
  @service declare session: Session;
  @service declare nexus: Nexus;
  @service declare intl: IntlService;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare trackedLocalStorage: any;
  @service declare lbaMetricsService: LbaMetricsService;
  @service declare logService: LogService;

  @tracked awayStatusReason?: AwayReason | AwayStatusReason;
  @tracked awayModeEnabled?: boolean;
  @tracked reassignConversations?: boolean;
  @tracked defaultAssigneeId?: number;
  @tracked isAutoChanged?: boolean;
  @tracked autoChangedTime: any;
  @tracked displayModalPrompt = false;
  @tracked channelAvailability = 'conversations';
  @tracked _awayStatusReasons: AwayStatusReason[] = [];
  @tracked isLoadingReasons = false;

  private nexusListener?: (e: NexusEvent) => void;

  private refreshTimer: any;
  // @ts-ignore
  hasFeatureHelper = HasFeatureHelper.create(this);

  constructor() {
    super(...arguments);

    // We rely on Nexus + polling to keep the away status in sync.
    //
    // Nexus is used for real time updates.
    //
    // Polling is used as a fallback to Nexus in case Nexus events are lost (Nexus is known to have some reliability issues [1]).
    //
    // [1] https://github.com/intercom/intercom/issues/355592#issuecomment-2358477226
    this.subscribe();
    this.nexus.on(NexusStatusEvents.Reconnected, () => this.getAwaySettings());
    this.scheduleRefresh();
    this.getAwaySettings();
    this.setup();
  }

  async setup() {
    if (
      this.canEditAwayStatusReasons &&
      this.awayStatusReasons.length === 0 &&
      !this.isLoadingReasons
    ) {
      await this.fetchAwayStatusReasons();
    }
  }

  get canEditAwayStatusReasons() {
    return this.hasFeatureHelper.compute(['hsg-tickets-away-mode-agent-status-custom-reasons']);
  }

  get awayStatusReasons(): AwayStatusReason[] {
    return this._awayStatusReasons;
  }

  get phoneAwayStatusReasons(): AwayStatusReason[] {
    return this._awayStatusReasons.filter((reason) => reason.associatedToPhoneStatus);
  }

  async findAwayStatusReasonForPhone(reasonType: PhoneAwayStatusReasonTypes) {
    if (this.canEditAwayStatusReasons) {
      if (this.awayStatusReasons.length === 0) {
        await this.fetchAwayStatusReasons();
      }
      return this.phoneAwayStatusReasons.find(
        (reason) => reason.associatedToPhoneStatus === reasonType,
      );
    }

    return LegacyPhoneReasonTypeToReasonMap[reasonType];
  }

  isCurrentStatusAssociatedToPhoneStatus(targetType: PhoneAwayStatusReasonTypes) {
    if (!this.awayStatusReason) {
      return false;
    }

    if (this.awayStatusReason instanceof AwayStatusReason) {
      return this.awayStatusReason.associatedToPhoneStatus === targetType;
    } else {
      return LegacyPhoneReasonTypeToReasonMap[targetType] === this.awayStatusReason;
    }
  }

  async fetchAwayStatusReasons(force = false) {
    try {
      this.isLoadingReasons = true;
      this._awayStatusReasons = await this.session.workspace.fetchAwayStatusReasons(force);
      if (this.awayStatusReason && this.awayStatusReason instanceof AwayStatusReason) {
        let currentReason = this._awayStatusReasons.findBy('id', this.awayStatusReason.id);
        if (currentReason) {
          this.awayStatusReason = currentReason;
        }
      }
    } finally {
      this.isLoadingReasons = false;
    }
  }

  willDestroy() {
    if (this.refreshTimer) {
      cancel(this.refreshTimer);
    }
  }

  subscribe() {
    this.nexusListener = (e: NexusEvent) => {
      this.handleAdminStatusChange(e);
    };
    this.nexus.addListener(NexusEventName.AdminAwayReassignModeChangeEvent, this.nexusListener);
  }

  toggleAwayMode() {
    this.awayModeEnabled = !this.awayModeEnabled;
    this.reassignConversations = false;
    this.awayStatusReason = null;
    this.isAutoChanged = false;
    this.setAwaySettings(SupportedOperations.ToggleAwayMode, this.currentTeammateContext);
  }

  setAdminAsAway(awayStatusReason: AwayReason | AwayStatusReason = null) {
    this.awayModeEnabled = true;
    this.reassignConversations = false;
    this.awayStatusReason = awayStatusReason;
    this.isAutoChanged = false;
    this.setAwaySettings(SupportedOperations.ToggleAwayMode, this.currentTeammateContext);
  }

  async setAdminAsOnACall() {
    let reason = await this.findAwayStatusReasonForPhone(PhoneAwayStatusReasonTypes.OnACall);
    this.setAdminAsAway(reason);
  }

  async setAdminAsCoaching() {
    let reason = await this.findAwayStatusReasonForPhone(PhoneAwayStatusReasonTypes.Coaching);
    this.setAdminAsAway(reason);
  }

  setAdminAsAvailable() {
    this.awayModeEnabled = false;
    this.reassignConversations = false;
    this.awayStatusReason = null;
    this.isAutoChanged = false;
    this.setAwaySettings(SupportedOperations.ToggleAwayMode, this.currentTeammateContext);
  }

  toggleAwayModeFor(teammate_id: number, away_mode_enabled: boolean) {
    if (teammate_id === this.session.teammate.id) {
      return away_mode_enabled ? this.setAdminAsAway() : this.setAdminAsAvailable();
    }
    let context = {
      app_id: this.session.workspace.id,
      id: teammate_id,
      reassign_conversations: false,
      away_status_reason: null,
      away_mode_enabled,
    };
    this.setAwaySettings(SupportedOperations.ToggleAwayMode, context);
  }

  toggleReassignConversations() {
    this.reassignConversations = !this.reassignConversations;
    this.isAutoChanged = false;
    this.setAwaySettings(
      SupportedOperations.TogglereassignConversations,
      this.currentTeammateContext,
    );
  }

  toggleReassignConversationsFor(teammate_id: number, reassign: boolean) {
    let context = {
      app_id: this.session.workspace.id,
      id: teammate_id,
      reassign_conversations: reassign,
      away_mode_enabled: true,
    };
    this.setAwaySettings(SupportedOperations.ToggleAwayMode, context);
  }

  setAwayReason(reason: AwayReason | AwayStatusReason) {
    this.awayStatusReason = reason;
    this.intercomEventService.trackAnalyticsEvent({
      action: `Away status reason changed to ${reason}`,
      object: 'away_status_reason_setting',
      context: 'from_profile',
    });
    this.setAwaySettings(SupportedOperations.SetAwayReason, this.currentTeammateContext);
  }

  // There are issues where ember misses admin away status changes triggered from the backend during call wrap up
  // This method is used to force backend consistency in cases where wrap up's away status should have been cleared but was not
  async forceAdminConsistency(wrapUpTimeAmount: number) {
    this.getAwaySettings();

    let consistencyBuffer = 5_000;
    let wrapUpDurationMs = wrapUpTimeAmount * 1_000 + consistencyBuffer;

    if (!this.awayModeEnabled) {
      return;
    }

    let intervalledReload = setInterval(() => {
      this.getAwaySettings();
    }, consistencyBuffer);

    setTimeout(() => {
      clearInterval(intervalledReload);
    }, wrapUpDurationMs);
  }

  async toggleAvailableChannel(value: string) {
    this.channelAvailability = value;
    await this.setAvailableChannel(
      SupportedOperations.SetAvailableChannel,
      this.currentAvailableContext,
    );
  }

  async toggleAvailabeChannelForTeammate(adminId: number, value: ChannelAvailabilityCode) {
    if (adminId === this.session.teammate.id) {
      return this.toggleAvailableChannel(value);
    }

    let context = {
      app_id: this.session.workspace.id,
      id: adminId,
      channel_availability: value,
    };
    await this.setAvailableChannelForTeammate(context);
  }

  async getAwaySettings() {
    let data: AwaySettingsWireFormat = await get(
      `/ember/admins/away_settings.json?app_id=${this.session.workspace.id}`,
    );

    if (this.canEditAwayStatusReasons) {
      if (!data.away_status_reason_id) {
        this.awayStatusReason = null;
      } else {
        this.getCustomAwayReason(data);
      }
    } else {
      this.awayStatusReason = !data.away_status_reason
        ? null
        : (data.away_status_reason as AwayReason);
    }

    this.awayModeEnabled = data.away_mode_enabled;
    this.defaultAssigneeId = data.default_assignee_id;
    this.reassignConversations = data.reassign_conversations;
    this.isAutoChanged = data.is_auto_changed;
    this.autoChangedTime = data.auto_changed_time;
    this.channelAvailability = data.channel_availability;
  }

  async getCustomAwayReason(data: AwaySettingsWireFormat) {
    // As we use the findBy what we currently have, we need to make sure the away status reasons are already loaded
    if (this.awayStatusReasons.length === 0) {
      await this.fetchAwayStatusReasons();
    }

    this.awayStatusReason = this.awayStatusReasons.findBy(
      'id',
      data.away_status_reason_id.toString(),
    );
  }

  async setAwaySettings(kind: SupportedOperations, context: any = {}) {
    try {
      if (context.id === this.session.teammate.id) {
        this.trackTeammateComingBackOnline(kind, context);
        this.trackedLocalStorage.setItem(HAS_CHANGED_OWN_STATUS_KEY, '1');
        await put(`/ember/admins/change_own_away_mode`, context);
      } else {
        await put(`/ember/admins/change_away_mode`, context);
      }
    } catch (e) {
      this.revertCurrentTeammateStatus(kind, context);
      this.notificationsService.notifyError(this.intl.t('inbox.user-menu.set-status-error'));
      throw e;
    }
  }

  async setAvailableChannel(kind: SupportedOperations, context: any = {}) {
    try {
      await put(`/ember/admins/change_active_channel`, context);
    } catch (e) {
      this.revertCurrentTeammateStatus(kind, context);
      this.notificationsService.notifyError(this.intl.t('inbox.user-menu.set-status-error'));
      throw e;
    }
  }

  async setAvailableChannelForTeammate(context: {
    app_id: string;
    id: number;
    channel_availability: ChannelAvailabilityCode;
  }) {
    try {
      await put(`/ember/admins/change_teammate_active_channel`, context);
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.dashboard.teammate-activity.table.set-status-error'),
      );
      throw e;
    }
  }

  getTranslationKeyForAwayReason(reason: AwayReason) {
    let map: { [reason: string]: string } = {
      '🧑‍🏫 Coaching': 'coaching',
      '😌 On a break': 'on-a-break',
      '🍔 On lunch': 'on-lunch',
      '🗓️ In a meeting': 'in-a-meeting',
      '📞 On a call': 'on-a-call',
      '📝 Call wrap up': 'call-wrap-up',
      '🏡 Done for the day': 'done-for-the-day',
      '🌴 On holidays': 'on-holidays',
      '🤒 Out sick': 'out-sick',
    };
    if (!reason) {
      return 'default';
    }
    return map[reason];
  }

  getTranslationKeyForDefaultAwayReason(reason: AwayStatusReason) {
    let map: { [reason: string]: string } = {
      Coaching: 'coaching',
      'On a break': 'on-a-break',
      'On lunch': 'on-lunch',
      'In a meeting': 'in-a-meeting',
      'On a call': 'on-a-call',
      'Call wrap up': 'call-wrap-up',
      'Done for the day': 'done-for-the-day',
      'Out of office': 'on-holidays',
      'Out sick': 'out-sick',
    };
    if (!Object.keys(map).includes(reason.label)) {
      return;
    }
    return map[reason.label];
  }

  handleAdminStatusChange(e: NexusEvent) {
    if (e.eventData.admin_id !== this.session.teammate.id) {
      let admin = this.inbox2AssigneeSearch.findAdminById(e.eventData.admin_id);
      if (admin) {
        admin.adminStatus = {
          awayModeEnabled: e.eventData.away_mode_enabled,
          reassignConversations: e.eventData.reassign_conversations,
          isAutoChanged: e.eventData.is_auto_changed,
          channelAvailability: e.eventData.channel_availability,
        };
      }
      return;
    }

    this.awayModeEnabled = e.eventData.away_mode_enabled;
    this.reassignConversations = e.eventData.reassign_conversations;
  }

  @action maybeDisplayModalPrompt() {
    if (storage.get(SILENCE_AWAY_WARNING_KEY)) {
      return;
    }

    if (!this.awayModeEnabled) {
      return;
    }

    this.displayModalPrompt = true;
  }

  @action silenceAwayModePrompt() {
    storage.set(SILENCE_AWAY_WARNING_KEY, true);
  }

  scheduleRefresh() {
    this.refreshTimer = later(this, this.refreshAwaySettings, ENV.APP.awayModeRefreshInterval);
  }

  async refreshAwaySettings() {
    let nexusConnected = this.nexus.isConnected;
    let awayModeEnabledBefore = this.awayModeEnabled;

    await this.getAwaySettings();

    let awayModeEnabledAfter = this.awayModeEnabled;

    // If nexus is connected and the away mode we just fetched does not match the one we had before
    // it means that the AdminAwayReassignModeChange nexus event was lost/not received
    if (nexusConnected && awayModeEnabledBefore !== awayModeEnabledAfter) {
      this.logService.log({
        log_type: 'stale_away_mode_refresh',
        app_id: this.session.workspace.id,
        admin_id: this.session.teammate.id,
        away_mode_enabled_before: awayModeEnabledBefore,
        away_mode_enabled_after: awayModeEnabledAfter,
      });
    }

    if (ENV.environment !== 'test') {
      this.scheduleRefresh();
    }
  }

  private revertCurrentTeammateStatus(kind: SupportedOperations, context: any = {}) {
    if (context.id === this.session.teammate.id) {
      switch (kind) {
        case SupportedOperations.ToggleAwayMode: {
          this.awayModeEnabled = !this.awayModeEnabled;
          break;
        }
        case SupportedOperations.TogglereassignConversations: {
          this.reassignConversations = !this.reassignConversations;
          break;
        }
        case SupportedOperations.SetAvailableChannel: {
          this.channelAvailability = 'conversation';
          break;
        }
      }
    }
  }

  private get currentAvailableContext() {
    return {
      app_id: this.session.workspace.id,
      id: this.session.teammate.id,
      channel_availability: this.channelAvailability,
    };
  }

  private get currentTeammateContext() {
    let currentSelectedAwayStatusReason = this.awayStatusReason;
    if (
      this.canEditAwayStatusReasons &&
      this.awayStatusReason &&
      this.awayStatusReason instanceof AwayStatusReason
    ) {
      currentSelectedAwayStatusReason = this.awayStatusReason.id;
    }

    return {
      app_id: this.session.workspace.id,
      id: this.session.teammate.id,
      reassign_conversations: this.reassignConversations,
      away_mode_enabled: this.awayModeEnabled,
      ...(this.canEditAwayStatusReasons
        ? { away_status_reason_id: currentSelectedAwayStatusReason }
        : { away_status_reason: currentSelectedAwayStatusReason }),
    };
  }

  private trackTeammateComingBackOnline(
    kind: SupportedOperations,
    context: { away_mode_enabled: any },
  ) {
    if (kind === SupportedOperations.ToggleAwayMode && !context.away_mode_enabled) {
      this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(LbaTriggerEvent.ONLINE);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    adminAwayService: AdminAwayService;
    'admin-away-service': AdminAwayService;
  }
}
