/* RESPONSIBLE TEAM: team-ai-agent */
import BaseValidator from 'ember-cp-validations/validators/base';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { type Block, type BlockList } from '@intercom/interblocks.ts';

export default class AiPromptDataIsValid extends BaseValidator {
  validate(instructions: BlockList[], _options: any, model: any) {
    let error = false;

    instructions.forEach((instruction: BlockList) => {
      instruction.forEach((instructionBlock: Block) => {
        if (instructionBlock.type !== 'paragraph') {
          // if somehow we get a non-paragraph block, skip it
          return;
        }

        let instructionBlockText = instructionBlock.text;

        // Convert the instruction block text to a html element for easier querying
        let instructionBlockElement = document.createElement('p');
        instructionBlockElement.innerHTML = sanitizeHtml(instructionBlockText);

        // Get all ai-prompt-data span tags
        let aiPromptDataSpanTags = instructionBlockElement.querySelectorAll(
          'span[data-ai-prompt-data]',
        );

        aiPromptDataSpanTags.forEach((aiPromptDataSpanTag: HTMLElement) => {
          if (this.isInvalidAiPromptData(aiPromptDataSpanTag)) {
            error = true;
          }
        });
      });
    });

    if (error) {
      return model.intl.t(
        'operator.workflows.visual-builder.validations.fin-custom.inline-attribute-data-error',
      );
    }

    return true;
  }

  private isInvalidAiPromptData(spanTag: HTMLElement) {
    return (
      this.isInvalidReadAttribute(spanTag) ||
      this.isInvalidUpdateAttribute(spanTag) ||
      this.isInvalidUseAction(spanTag)
    );
  }

  // Ensures read attributes have the correct data set on the span tag
  private isInvalidReadAttribute(spanElement: HTMLElement) {
    return (
      spanElement.getAttribute('data-prompt-data-operation') === 'read_attribute' &&
      this.hasEmptySubjectIdentifier(spanElement)
    );
  }

  // Ensures update attributes have the correct data set on the span tag
  private isInvalidUpdateAttribute(spanElement: HTMLElement) {
    return (
      spanElement.getAttribute('data-prompt-data-operation') === 'update_attribute' &&
      (this.hasEmptySubjectIdentifier(spanElement) ||
        this.hasInvalidAttributeValueType(spanElement))
    );
  }

  // Ensures use action has the correct data set on the span tag0
  private isInvalidUseAction(spanElement: HTMLElement) {
    return (
      spanElement.getAttribute('data-prompt-data-operation') === 'use_action' &&
      this.hasEmptySubjectIdentifier(spanElement)
    );
  }

  private hasEmptySubjectIdentifier(spanElement: HTMLElement) {
    return (
      spanElement.getAttribute('data-prompt-data-subject-identifier') === '' ||
      spanElement.getAttribute('data-prompt-data-subject-identifier') === null
    );
  }

  private hasInvalidAttributeValueType(spanElement: HTMLElement) {
    let attributeValueType = spanElement.getAttribute('data-prompt-data-attribute-value-type');
    return (
      attributeValueType === null ||
      attributeValueType === '' ||
      !['fin', 'custom'].includes(attributeValueType)
    );
  }
}
