/* RESPONSIBLE TEAM: team-standalone */

import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';
import { type TaskGenerator } from 'ember-concurrency';
import type OfficeHoursSchedule from 'embercom/models/office-hours-schedule';
import {
  ZendeskDataFields,
  type ZendeskTicketsSetupData,
} from 'embercom/objects/standalone/data-fields/zendesk';
import {
  OrganizationField,
  type OrganizationFieldResponse,
  TicketField,
  type TicketFieldResponse,
  UserField,
  type UserFieldResponse,
} from 'embercom/objects/standalone/data-fields/common';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';

export default class ZendeskDataLoader {
  private finStandaloneService: FinStandaloneService;

  constructor(finStandaloneService: FinStandaloneService) {
    this.finStandaloneService = finStandaloneService;
  }

  get app() {
    return this.finStandaloneService.app;
  }

  get intl(): IntlService {
    return this.finStandaloneService.intl;
  }

  get notificationsService() {
    return this.finStandaloneService.notificationsService;
  }

  get store(): Store {
    return this.finStandaloneService.store;
  }

  async performLoads() {
    await taskFor(this.loadZendeskConfig).perform();

    taskFor(this.loadSunshineChannels).perform();
    taskFor(this.loadZendeskBrands).perform();
    taskFor(this.loadZendeskTicketsSetupData).perform();
    taskFor(this.loadZendeskDataFields).perform();
    taskFor(this.loadZendeskSchedules).perform();
  }

  @dropTask *loadZendeskConfig() {
    try {
      this.finStandaloneService.zendeskConfig = yield this.store.findRecord(
        'standalone/zendesk-configuration',
        this.app.id,
      );

      // TODO Can we remove this? Does the backend ever not return a zendesk config?
      if (!this.finStandaloneService.zendeskConfig) {
        this.finStandaloneService.zendeskConfig = this.store.createRecord(
          'standalone/zendesk-configuration',
          {
            id: this.app.id,
            sunshineIntegration: this.store.createRecord('standalone/sunshine-configuration', {
              id: this.app.id,
            }),
          },
        );
      }
    } catch (e) {
      console.error(e.message || e.jqXHR);
      if (e.jqXHR?.status !== 404) {
        throw e;
      }
    }
  }

  get isLoadingSunshineChannels() {
    return taskFor(this.loadSunshineChannels).isRunning;
  }

  @dropTask *loadZendeskBrands(): TaskGenerator<void> {
    if (this.finStandaloneService.zendeskConfig?.isPendingSetupForTickets) {
      this.finStandaloneService.zendeskBrands = [];
      return;
    }

    // TODO Replace this with usage of Ember Data
    let brands = yield get(
      `/ember/standalone/zendesk_configurations/${this.app.id}/available_brands`,
      {
        app_id: this.app.id,
      },
    );

    this.finStandaloneService.zendeskBrands = brands;
  }

  @dropTask *loadSunshineChannels(): TaskGenerator<void> {
    if (this.finStandaloneService.zendeskConfig?.sunshineIntegration.isSunshineDisconnected) {
      this.finStandaloneService.sunshineChannels = [];
      return;
    }

    // TODO Replace this with usage of Ember Data
    let channels = yield get(
      `/ember/standalone/zendesk_configurations/${this.app.id}/sunshine_channels`,
      {
        app_id: this.app.id,
      },
    );

    this.finStandaloneService.sunshineChannels = channels;
  }

  @dropTask *loadZendeskTicketsSetupData(): TaskGenerator<void> {
    if (this.finStandaloneService.zendeskConfig?.isPendingSetupForTickets) {
      this.finStandaloneService.zendeskTicketsSetupData = {
        admins: [],
        email_addresses: [],
        live_trigger: { id: undefined },
      };
      return;
    }

    // TODO Replace this with usage of Ember Data
    this.finStandaloneService.zendeskTicketsSetupData = (yield get(
      `/ember/standalone/zendesk_configurations/${this.app.id}/zendesk_tickets_config`,
      {
        app_id: this.app.id,
      },
    )) as unknown as ZendeskTicketsSetupData;
  }

  get isLoadingZendeskTicketsSetupData() {
    return taskFor(this.loadZendeskTicketsSetupData).isRunning;
  }

  get isLoadingZendeskConfig() {
    return taskFor(this.loadZendeskConfig).isRunning;
  }

  @dropTask *loadZendeskSchedules(): TaskGenerator<void> {
    yield this.reloadZendeskSchedules();
  }

  get isLoadingZendeskSchedules() {
    return taskFor(this.loadZendeskSchedules).isRunning;
  }

  async reloadZendeskSchedules() {
    if (this.finStandaloneService.zendeskConfig?.isPendingSetupForTickets) {
      this.finStandaloneService.zendeskSchedules = [];
      return;
    }

    // TODO Replace this with usage of Ember Data
    let officeHourSchedules = (await get(
      `/ember/standalone/zendesk_schedules/synchronized_schedules`,
      {
        app_id: this.app.id,
        admin_id: this.app.currentAdmin.id,
      },
    )) as Array<OfficeHoursSchedule>;

    this.store.pushPayload({ 'office-hours-schedule': officeHourSchedules });

    this.finStandaloneService.zendeskSchedules = officeHourSchedules
      .map((schedule) => this.store.peekRecord('office-hours-schedule', schedule.id))
      .compact();
  }

  @dropTask *loadZendeskDataFields(): TaskGenerator<void> {
    yield this.reloadZendeskDataFields();
  }

  async reloadZendeskDataFields() {
    if (this.finStandaloneService.zendeskConfig?.isPendingSetupForTickets) {
      this.finStandaloneService.zendeskDataFields = new ZendeskDataFields({
        userFields: [],
        ticketFields: [],
        organizationFields: [],
      });
      return;
    }

    // TODO Replace this with usage of Ember Data
    let dataFields = (await get(`/ember/standalone/zendesk_data/available_fields`, {
      app_id: this.app.id,
      admin_id: this.app.currentAdmin.id,
    })) as {
      user_fields: UserFieldResponse[];
      ticket_fields: TicketFieldResponse[];
      organization_fields: OrganizationFieldResponse[];
    };

    this.finStandaloneService.zendeskDataFields = new ZendeskDataFields({
      userFields: dataFields.user_fields.map((field) => new UserField(field)),
      ticketFields: dataFields.ticket_fields.map((field) => new TicketField(field)),
      organizationFields:
        dataFields.organization_fields?.map((field) => new OrganizationField(field)) || [],
    });
  }

  get isLoadingZendeskDataFields() {
    return taskFor(this.loadZendeskDataFields).isRunning;
  }
}
