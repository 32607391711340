/* RESPONSIBLE TEAM: team-knowledge-foundations */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class HelpCenterRedirect extends EmbercomBaseSerializer {
  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    json.url = json.from_url;

    return json;
  }
}
