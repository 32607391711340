/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import storage from 'embercom/vendor/intercom/storage';
import type Session from 'embercom/services/session';

export default class ConversationTranslationSettings extends Service {
  @service declare session: Session;
  @service declare intercomEventService: $TSFixMe;
  @tracked adminlevelToggle = true;
  @tracked private translationDisabledConversations: Set<string> = new Set();

  constructor() {
    super(...arguments);
    if (storage.get(this.localStorageShowTranslationKey) !== null) {
      this.adminlevelToggle = storage.get(this.localStorageShowTranslationKey);
    }
    this.translationDisabledConversations = new Set(this.disabledConversationIds());
  }

  get autoTranslationEnabled() {
    return this.storedValueOrDefault;
  }

  get storedValueOrDefault() {
    if (!this.session.workspace.isFeatureEnabled('realtime-translation')) {
      return false;
    }

    if (this.session.workspace.aiInboxTranslationsEnabled) {
      return this.adminlevelToggle;
    } else {
      return false;
    }
  }

  get localStorageShowTranslationKey() {
    let appId = this.session.workspace.id;
    let adminId = this.session.teammate.id;
    return `${appId}::${adminId}::ConversationTranslationSettings::showTranslation`;
  }

  translationDisabledConversationIdsKey() {
    let appId = this.session.workspace.id;
    let adminId = this.session.teammate.id;
    return `${appId}::${adminId}::ConversationTranslationSettings::translationDisabled`;
  }

  autoTranslationEnabledForConversation(id: string | number | undefined): boolean {
    if (!this.session.workspace.isFeatureEnabled('realtime-translation')) {
      return false;
    }

    if (!this.session.workspace.aiInboxTranslationsEnabled) {
      return false;
    }

    if (!id) {
      return false;
    }

    id = `${id}`;

    return !this.translationDisabledConversations.has(id);
  }

  disabledConversationIds(): string[] {
    let ids = storage.get(this.translationDisabledConversationIdsKey());
    return ids ? ids.split(',') : [];
  }

  addIdToDisabledList(id: string) {
    let ids = this.disabledConversationIds();
    ids.push(`${id}`);
    storage.set(this.translationDisabledConversationIdsKey(), ids.join(','));

    this.translationDisabledConversations = new Set(this.disabledConversationIds());
  }

  removeIdFromDisabledList(id: string) {
    let ids = this.disabledConversationIds();
    ids = ids.filter((disabledId) => disabledId !== `${id}`);
    storage.set(this.translationDisabledConversationIdsKey(), ids.join(','));
    this.translationDisabledConversations = new Set(this.disabledConversationIds());
  }

  @action toggleAutoTranslationEnabledForConversation(id: string | number): void {
    let newValue = !this.autoTranslationEnabledForConversation(id);
    id = `${id}`;

    if (newValue) {
      this.removeIdFromDisabledList(id);
    } else {
      this.addIdToDisabledList(id);
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'translation_settings',
      section: 'admin_details',
      context: 'conversation_translation_settings',
      metadata: {
        is_translation_enabled: newValue,
        conversation_id: id,
      },
    });
  }

  @action toggleTranslation(toggle?: boolean): void {
    if (toggle === undefined) {
      this.adminlevelToggle = !this.adminlevelToggle;
    } else {
      this.adminlevelToggle = toggle;
    }
    storage.set(this.localStorageShowTranslationKey, this.adminlevelToggle);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'translation_settings',
      section: 'admin_details',
      context: 'conversation_translation_settings',
      metadata: {
        is_translation_enabled: this.autoTranslationEnabled,
        admin_toggle: this.adminlevelToggle,
      },
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    conversationTranslationSettings: ConversationTranslationSettings;
    'conversation-translation-settings': ConversationTranslationSettings;
  }
}
