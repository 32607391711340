/* RESPONSIBLE TEAM: team-knowledge-foundations */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class HelpCenterBlock extends EmbercomBaseSerializer {
  normalize(modelClass: any, resourceHash: any) {
    let normalizedBlock: any = super.normalize(modelClass, resourceHash);
    if (normalizedBlock.data.attributes.type) {
      Object.assign(normalizedBlock.data.attributes, resourceHash, {
        modelKey: `customization-options/layout-options/blocks/${normalizedBlock.data.attributes.type}`,
      });
    }
    return normalizedBlock;
  }
}
