/* RESPONSIBLE TEAM: team-purchase */
import Service, { inject as service } from '@ember/service';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import type Store from '@ember-data/store';
import { dropTask } from 'ember-concurrency-decorators';

export default class AnnualSubscriptionUpdateService extends Service {
  @service appService: any;
  @service customerService: any;
  @service declare modelDataCacheService: any;
  @service declare store: Store;

  @dropTask
  *upgrade({
    planIds,
    coreSeats,
    copilotSeats,
  }: {
    planIds: Array<number>;
    coreSeats?: number;
    copilotSeats?: number;
  }): TaskGenerator<void> {
    let contract;
    if (coreSeats === undefined || copilotSeats === undefined) {
      contract = yield this.store.findRecord('billing/contract', this.appService.app.id);
    }

    let params = {
      plan_ids: planIds,
      core_seats:
        coreSeats !== undefined ? coreSeats : contract.contractUsageLimits.core_seat_count,
      copilot_seat_count:
        copilotSeats !== undefined ? copilotSeats : contract.contractUsageLimits.copilot_seat_count,
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
    };

    yield post('/ember/customers/ammend_annual_contract', params);
  }
}

declare module '@ember/service' {
  interface Registry {
    annualSubscriptionUpdateService: AnnualSubscriptionUpdateService;
    'annual-subscription-update-service': AnnualSubscriptionUpdateService;
  }
}
