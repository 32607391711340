/* RESPONSIBLE TEAM: team-reporting */
import Service from '@ember/service';
import { type BucketRange, RangeInterval } from 'embercom/models/reporting/custom/bucket-interval';
import { type ProcessedValue, type RawChartData } from 'embercom/services/reporting-chart-service';
import { indexBy } from 'underscore';

interface BucketValue {
  key: string;
  from: number;
  to?: number;
  doc_count: number;
}

interface BucketResponseAggregation {
  name: '0';
  values: BucketValue[][];
}

interface BucketResponse {
  groups: []; //always empty,
  aggregations: BucketResponseAggregation[];
  name: string;
}

export default class ReportingBucketingResponseHandler extends Service {
  private orderResponse(response: BucketResponse, ranges: BucketRange[]): BucketValue[] {
    let indexedResponse: Record<string, BucketValue> = indexBy(
      response.aggregations[0].values[0],
      'key',
    );
    let orderedResponse = ranges.map((range) => {
      let key = RangeInterval.toRangeKey(range);
      return indexedResponse[key];
    });

    return orderedResponse;
  }

  formatResponse(response: BucketResponse, rangeOrder: BucketRange[]): RawChartData {
    if (rangeOrder.length === 0) {
      return this.convertToRawChartData(response.name, response.aggregations[0].values[0]);
    } else {
      let orderedResponse = this.orderResponse(response, rangeOrder);
      return this.convertToRawChartData(response.name, orderedResponse);
    }
  }

  private convertToRawChartData(name: string, values: BucketValue[]) {
    return {
      name,
      groups: [
        {
          values: values.map((v) => v.key), // keys can come in one of three forms *-300.0 or 300.0-900.0 or 900.0-* example response : https://github.com/intercom/intercom/issues/382709#issuecomment-2665991847
          name: '0',
          type: 'numerical',
          aggregations: [
            {
              name: '0',
              values: this.convertToPercentage(values),
              processedValues: this.convertToProcessedValues(values),
            },
          ],
        },
      ],
      aggregations: [],
    };
  }

  private convertToPercentage(values: BucketValue[]) {
    let total = values.reduce((acc, v) => acc + v.doc_count, 0);
    return values.map((v) => (v.doc_count / total) * 100);
  }

  private convertToProcessedValues(values: BucketValue[]): ProcessedValue[] {
    let total = values.reduce((acc, v) => acc + v.doc_count, 0);
    return values.map((v, idx) => {
      return {
        key: idx,
        value: (v.doc_count / total) * 100,
        numeratorValue: v.doc_count,
        denominatorValue: total,
      };
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    reportingBucketingResponseHandler: ReportingBucketingResponseHandler;
    'reporting-bucketing-response-handler': ReportingBucketingResponseHandler;
  }
}
