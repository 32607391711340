/* RESPONSIBLE TEAM: team-knowledge-foundations */
import {
  type EmberCPError,
  toErrorResult,
  type EmberCPValidationsResult,
} from 'embercom/validations/typescript/ember-cp-validations-compat';
// eslint-disable-next-line no-restricted-imports
import containerLookup from 'embercom/lib/container-lookup';
import { isBlank } from '@ember/utils';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';

export function validateKnowledgeHubContent(
  localizedKnowledgeContent: LocalizedKnowledgeContent,
): EmberCPValidationsResult {
  let intl = containerLookup('service:intl');
  let errors: Array<EmberCPError> = [];
  if (isBlank(localizedKnowledgeContent.title)) {
    errors.push({
      attribute: 'title',
      message: intl.t(
        `knowledge-hub.content-editor.validations.empty-title.${localizedKnowledgeContent.entityName}`,
      ),
    });
  }

  return toErrorResult(errors);
}
