/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { request } from 'embercom/lib/inbox/requests';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';

export default class Inbox2BrandService extends Service {
  @service declare session: Session;

  @use brands = AsyncData(async () => {
    let response = await request(`/ember/brands?app_id=${this.session.workspace.id}`);
    let data = await response.json();
    return data;
  });

  get isPaywallOverrideActive() {
    return this.session.workspace.isFeatureEnabled('multibrand-ignore-paywall');
  }

  get isAppOnRightMultibrandPlan() {
    if (this.session.workspace.isFeatureEnabled('multibrand-ignore-paywall')) {
      return true;
    } else if (this.session.workspace.isFeatureEnabled('messenger_multibrand')) {
      return true;
    }
    return false;
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2BrandService: Inbox2BrandService;
  }
}
