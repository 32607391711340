/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type ApplicationInstance from '@ember/application/instance';

interface Locale {
  name: string;
  localeId: string;
  isPermitted: boolean;
}

interface Languages {
  supportedLocales: Locale[];
  defaultLocale: string;
}

export default class LanguagesService extends Service {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked languages: Languages | null = null;
  @tracked multilingualSettings: any | null = null;

  fetchLanguagesTask = taskFor(this._fetchLanguages);
  fetchMultilingualSettingsTask = taskFor(this._fetchMultilingualSettings);

  /**
   * Fetches languages and multilingual settings when the service is initialized
   */
  constructor(owner: ApplicationInstance) {
    super(owner);

    this.fetchLanguagesTask.perform();
    this.fetchMultilingualSettingsTask.perform();
  }

  willDestroy() {
    super.willDestroy();
    this.fetchLanguagesTask.cancelAll();
    this.fetchMultilingualSettingsTask.cancelAll();
  }

  get isLanguagesLoading() {
    return this.fetchLanguagesTask.isRunning;
  }

  get isMultilingualSettingsLoading() {
    return this.fetchMultilingualSettingsTask.isRunning;
  }

  /**
   * Fetches language settings from the server
   */
  @task({ drop: true })
  *_fetchLanguages(): TaskGenerator<void> {
    try {
      let settings = yield this.store.findRecord('messenger-settings/all', this.appService.app.id);
      this.languages = settings.languages;
    } catch (error) {
      // ignore error
    }
  }

  /**
   * Fetches multilingual settings from the server
   */
  @task({ drop: true })
  *_fetchMultilingualSettings(): TaskGenerator<any> {
    try {
      let settings = yield this.store.findRecord(
        'ai-agent/multilingual-settings',
        this.appService.app.id,
      );
      this.multilingualSettings = settings;
      return settings;
    } catch (error) {
      // ignore error
      return null;
    }
  }

  /**
   * Refreshes multilingual settings
   */
  async refreshMultilingualSettings() {
    if (!this.fetchMultilingualSettingsTask.isRunning) {
      await this.fetchMultilingualSettingsTask.perform();
    } else {
      await this.fetchMultilingualSettingsTask.last;
    }
  }

  /**
   * Refreshes languages
   */
  async refreshLanguages() {
    if (!this.fetchLanguagesTask.isRunning) {
      await this.fetchLanguagesTask.perform();
    } else {
      await this.fetchLanguagesTask.last;
    }
  }

  /**
   * Returns the name of the default locale or throws an error if no default locale is found
   */
  get defaultLocaleName() {
    if (!this.languages?.defaultLocale) {
      throw new Error('No default locale found');
    }

    return this.getLanguageName(this.languages.defaultLocale);
  }

  /**
   * Returns language name or localeId if no name is found
   */
  getLanguageName(localeId: string): string {
    let supportedLocales = this.languages?.supportedLocales?.toArray?.();

    if (Array.isArray(supportedLocales) && supportedLocales.length > 0) {
      let localeObject = supportedLocales.find((locale) => locale.localeId === localeId);

      if (localeObject?.name) {
        return localeObject.name;
      }
    }

    return localeId;
  }

  /**
   * Returns an array of supported locale names
   */
  getSupportedLocaleNames(): string[] {
    if (!this.languages?.supportedLocales) {
      return [];
    }

    return this.languages.supportedLocales
      .filter((locale: Locale) => locale.isPermitted)
      .map((locale: Locale) => locale.name);
  }
}

declare module '@ember/service' {
  interface Registry {
    'languages-service': LanguagesService;
  }
}
