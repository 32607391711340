/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { type InboxType } from 'embercom/models/data/inbox/inbox-types';
import type IntlService from 'embercom/services/intl';
import {
  type InboxMentionsStatus,
  InboxSortOption,
  type InboxStateOption,
} from 'embercom/models/data/inbox/inbox-filters';
import Conversation, {
  ConversationState,
  type ConversationWireFormat,
  TicketSystemState,
} from 'embercom/objects/inbox/conversation';
import ConversationSummary, {
  type ConversationSummaryWireFormat,
} from 'embercom/objects/inbox/conversation-summary';
import ConversationTableEntry, {
  type ConversationTableEntryWireFormat,
} from 'embercom/objects/inbox/conversation-table-entry';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import {
  type PinnedFolder,
  deserialize as deserializePinnedFolder,
} from 'embercom/objects/inbox/pinned-folder';
import {
  type InboxIdentifier,
  type InboxesMetaTotalsWireFormat,
  type InboxesWireFormat,
  type InboxWireFormat,
  type InboxIdentifierWithCount,
  type InboxIdentifiersWithCountsWireFormat,
} from 'embercom/objects/inbox/inboxes/inbox';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { deserialize as deserializeInbox } from 'embercom/objects/inbox/inboxes/inbox';
import { type DurationObject, DurationType } from 'embercom/objects/inbox/duration';
import RenderablePart, {
  type RenderablePartWireFormat,
} from 'embercom/objects/inbox/renderable-part';
import moment from 'moment-timezone';
import type Session from './session';
import QuickSearchService from 'embercom/services/quick-search';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import SidebarSection, {
  type SidebarSectionWireFormat,
} from 'embercom/objects/inbox/sidebar-section';
import type Attribute from 'embercom/objects/inbox/attribute';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import { TicketType, type TicketTypeWireFormat } from 'embercom/objects/inbox/ticket';
import { type MacroAction, transformMacroActions } from 'embercom/objects/inbox/macro';
import LatestConversationSummary, {
  type LatestConversationSummaryWireFormat,
} from 'embercom/objects/inbox/latest-conversation-summary';
import LinkedTicketSummary, {
  type LinkedTicketSummaryWireFormat,
} from 'embercom/objects/inbox/linked-ticket-summary';
import { EntityType } from 'embercom/models/data/entity-types';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';
import type WorkflowConnectorAction from 'embercom/objects/inbox/workflow-connector-action';
import type WorkflowDetails from 'embercom/objects/inbox/workflow-details';
import type User from 'embercom/objects/inbox/user';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { taskFor } from 'ember-concurrency-ts';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import cached, { invalidates } from 'embercom/lib/cached-decorator';
import { type ConversationsViewType } from 'embercom/services/inbox-state';
import TicketStateUpdatedByAdmin from 'embercom/objects/inbox/renderable/ticket-state-updated-by-admin';
import SmartReply, { type SmartReplyWireFormat } from 'embercom/objects/inbox/smart-reply';
import type Snackbar from 'embercom/services/snackbar';
import {
  buildParams,
  request,
  putRequest,
  postRequest,
  deleteRequest,
} from 'embercom/lib/inbox/requests';
import Note, { type NoteWireFormat } from 'embercom/objects/inbox/note';
import SenderEmailAddressSummary, {
  type SenderEmailAddressSummaryWireFormat,
} from 'embercom/objects/inbox/sender-email-address-summary';
import GithubLinkSummary, {
  type GithubLinkSummaryWireFormat,
} from 'embercom/objects/inbox/github-link-summary';
import type Company from 'embercom/objects/inbox/company';
import CompanyEmailAddress, {
  type CompanyEmailAddressWireFormat,
} from 'embercom/objects/inbox/company-email-address';
import { type Channel } from 'embercom/models/data/inbox/channels';
import { isEmpty } from '@ember/utils';
import { Checklist, type ChecklistWireFormat } from 'embercom/objects/inbox/checklists';
import WhatsappIntegrationSender, {
  type WhatsappIntegrationSenderWireFormat,
} from 'embercom/objects/inbox/whatsapp-integration-sender';
import { type BlockList } from '@intercom/interblocks.ts';
import { type Upload } from 'embercom/objects/inbox/renderable/upload';
import { type Predicate } from 'embercom/objects/inbox/search/predicate-group';
import type ExperimentsApi from './experiments-api';
import { InboxFolder, type InboxFoldersWireFormat } from 'embercom/objects/inbox/inbox-folder';
import SideConversation, {
  type SideConversationWireFormat,
} from 'embercom/objects/inbox/side-conversation';
import { type NewConversationWireFormat } from 'embercom/lib/inbox2/types';
import CompanySummary, {
  type CompanySummaryWireFormat,
} from 'embercom/objects/inbox/company-summary';
import { type RequestInitWithPriority } from 'embercom/lib/inbox/requests';
import {
  KnowledgeBaseSearchResult,
  type KnowledgeBaseSearchResultsWireFormat,
} from 'embercom/objects/inbox/knowledge-base/search-result';
import {
  KnowledgeBaseContent,
  type KnowledgeBaseContentWireFormat,
} from 'embercom/objects/inbox/knowledge-base/content';
import fetchActivities from 'embercom/lib/ai-browser-extension-client';
import { type State } from 'embercom/models/data/matching-system/matching-constants';
import {
  KnowledgeBaseFolder,
  type KnowledgeBaseFolderWireFormat,
} from 'embercom/objects/inbox/knowledge-base/folder';
import { type PromptData } from 'embercom/lib/open-ai-prompt';
import type { AiContentState } from 'embercom/lib/ai-content-library/constants';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type LogService from 'embercom/services/log-service';
import { type RecipientsWireFormat } from 'embercom/lib/composer/recipients';
import type ConversationTranslationSettings from 'embercom/services/conversation-translation-settings';

const DEFAULT_SUGGESTIONS_TO_FETCH = 6;
const FIVE_MINUTES = 5 * 60 * 1000;

export type SortDirection = 'asc' | 'desc';

export type SortField =
  | 'sorting_updated_at'
  | 'waiting_since'
  | 'next_breach_time'
  | 'priority'
  | 'priority_newest' // We should merge this with priority
  | 'conversation_started_at'
  | `${number}`
  | 'relevance' // conversation or ticket attribute columns
  | 'id' // conversation or ticket id
  | 'ticket_id'
  | 'ticket_created_at'
  | 'ticket_state';

export type SortParams = {
  sort_field: SortField;
  sort_direction: SortDirection;
};

export interface ReplyDataType {
  whatsapp_template_name?: string;
  whatsapp_template_language?: string;
  whatsapp_template_components?: Array<any>;
  translate_content?: boolean;
}

export interface ParticipantWireFormat {
  removedParticipantIds: string[];
  newParticipantEmails: string[];
  newParticipantIds: string[];
}

interface ListInboxes {
  folders?: PinnedFolder[];
  inboxes: Inbox[];
  totals: InboxesMetaTotalsWireFormat;
}

type ToneCompletionSuccess = {
  success: true;
  requestId: string;
  completionText: string;
};

type ToneCompletionFailure = {
  success: false;
  requestId: string;
  failureReason: string;
};

type ToneCompletionSuccessWireFormat = KeysToSnakeCase<ToneCompletionSuccess>;
type ToneCompletionFailureWireFormat = KeysToSnakeCase<ToneCompletionFailure>;
export type ToneCompletionWireFormat =
  | ToneCompletionSuccessWireFormat
  | ToneCompletionFailureWireFormat;

function applyClientAssignedUuids(
  data: ReturnType<typeof transformMacroActions>,
  parts: ({ clientAssignedUuid?: string } | undefined)[],
) {
  if (data.length !== parts.length) {
    throw new Error('Macro actions and parts data must have the same length');
  }

  return data.map((item, idx) => ({
    ...item,
    action_data: {
      ...item.action_data,
      client_assigned_uuid: parts[idx]?.clientAssignedUuid,
    },
  }));
}

const SORT_OPTION_TO_PARAMS_MAP: Record<InboxSortOption, SortParams> = {
  [InboxSortOption.Newest]: {
    sort_field: 'sorting_updated_at',
    sort_direction: 'desc',
  },
  [InboxSortOption.Oldest]: {
    sort_field: 'sorting_updated_at',
    sort_direction: 'asc',
  },
  [InboxSortOption.WaitingLongest]: {
    sort_field: 'waiting_since',
    sort_direction: 'asc',
  },
  [InboxSortOption.NextSlaTarget]: {
    sort_field: 'next_breach_time',
    sort_direction: 'asc',
  },
  [InboxSortOption.PriorityNewest]: {
    sort_field: 'priority_newest',
    sort_direction: 'desc',
  },
  [InboxSortOption.Relevance]: {
    sort_field: 'relevance',
    sort_direction: 'desc',
  },
  [InboxSortOption.StartedFirst]: {
    sort_field: 'conversation_started_at',
    sort_direction: 'asc',
  },
  [InboxSortOption.StartedLast]: {
    sort_field: 'conversation_started_at',
    sort_direction: 'desc',
  },
};

export function sortOptionsToParams(orderBy: InboxSortOption): SortParams {
  let sortParams = SORT_OPTION_TO_PARAMS_MAP[orderBy];

  if (!sortParams) {
    throw new Error(`Unknown sort order for inbox: ${orderBy}`);
  }

  return sortParams;
}

export function paramsToSortOptions(sortParams: SortParams): InboxSortOption | null {
  let sortOption = Object.keys(SORT_OPTION_TO_PARAMS_MAP).find((key) => {
    let params = SORT_OPTION_TO_PARAMS_MAP[key as InboxSortOption];
    if (
      params.sort_field === sortParams.sort_field &&
      params.sort_direction === sortParams.sort_direction
    ) {
      return key;
    }
    return null;
  });

  // not all params map to a sort option
  if (!sortOption) {
    return null;
  }

  return sortOption as InboxSortOption;
}

export default class InboxApi extends Service {
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare frontendStatsService: any;
  @service declare experimentsApi: ExperimentsApi;
  @service declare logService: LogService;
  @service declare conversationTranslationSettings: ConversationTranslationSettings;
  async listInboxes(
    {
      pinned = undefined,
      types = undefined,
      includeFolders = undefined,
      includeCounts = false,
    }: {
      pinned?: boolean;
      types?: InboxType[];
      includeFolders?: boolean;
      includeCounts?: boolean;
    },
    init?: RequestInit,
  ): Promise<ListInboxes> {
    let params = this.buildParams({
      types,
      pinned,
      include_folders: includeFolders,
      fields: { count: includeCounts },
    });
    let response = await request(`/ember/inbox/inboxes/?${params.toString()}`, init);

    let json = (await response.json()) as InboxesWireFormat;
    let inboxes = json.inboxes.map(deserializeInbox);
    let totals = json.meta.totals;

    let result = { inboxes, totals } as ListInboxes;

    if (json.folders) {
      let folders = json.folders.map(deserializePinnedFolder);
      result.folders = folders;
    }

    return result;
  }

  async markRatingAsSeen(id: string) {
    return await putRequest(`/ember/conversation_ratings/mark_as_seen/${id}`, {
      app_id: this.session.workspace.id,
    });
  }

  async fetchInbox(
    category: InboxCategory,
    id: string,
    includeCounts = false,
    init?: RequestInit,
  ): Promise<Inbox> {
    let type = category === InboxCategory.Shared ? id : category;

    let params = this.buildParams({ fields: { count: includeCounts } });
    let response = await request(`/ember/inbox/inboxes/${type}/${id}?${params.toString()}`, init);
    let json = (await response.json()).inbox as InboxWireFormat;
    return deserializeInbox(json);
  }

  async fetchInboxCounters(
    inboxes: InboxIdentifier[],
    init?: RequestInit,
  ): Promise<InboxIdentifierWithCount[]> {
    let params = this.buildParams({ fields: { count: true } });
    let response = await postRequest(
      `/ember/inbox/inboxes/inboxes?${params.toString()}`,
      { inboxes },
      init,
    );

    let json = (await response.json()) as InboxIdentifiersWithCountsWireFormat;
    return json.inboxes.map(({ id, inbox_type, count }) => ({
      id,
      type: inbox_type,
      count: typeof count === 'object' ? count.count : count,
      valid_for: typeof count === 'object' ? count.valid_for : undefined,
    }));
  }

  async fetchMostRecentConversation(
    category: InboxCategory,
    id: string,
    state: InboxStateOption,
    orderBy: SortParams,
    metadata?: Partial<{ reason: string }>,
  ): Promise<Conversation | undefined> {
    let { conversation } = await this.listConversations({
      category,
      id,
      state,
      orderBy,
      count: 1,
      includeLatestConversation: true,
      metadata,
    });
    return conversation;
  }

  async getAdminsWhoCanManageTeammates(init?: RequestInit): Promise<AdminSummary[]> {
    let response = await request(
      `/ember/inbox/admins/can_manage_teammates?app_id=${this.session.workspace.id}`,
      init,
    );
    let json = (await response.json()).admins as AdminSummaryWireFormat[];
    return json?.map(AdminSummary.deserialize) || [];
  }

  async listConversations({
    category,
    id,
    state,
    orderBy,
    count,
    includeLatestConversation,
    mentionsStatus,
    activeConversationId,
    metadata,
    includeLastAdminMentionedPart,
  }: {
    category: InboxCategory;
    id: string;
    state: InboxStateOption | undefined;
    orderBy: SortParams;
    count: number;
    includeLatestConversation: boolean;
    mentionsStatus?: InboxMentionsStatus;
    activeConversationId?: number;
    metadata?: Partial<{ reason: string; lowPriority: boolean }>;
    includeLastAdminMentionedPart?: boolean;
  }): Promise<{
    conversations: ConversationSummary[];
    conversation?: Conversation;
    activeConversation?: ConversationSummary;
    total: number;
    validFor: number;
    serverTime?: Date;
    inbox_id: string;
  }> {
    let fields = ['attributes'];
    if (includeLastAdminMentionedPart) {
      fields.push('last_admin_mentioned_part');
    }

    let ticket_state = Object.values(TicketSystemState).includes(state as TicketSystemState)
      ? state
      : undefined;
    state = Object.values(ConversationState).includes(state as ConversationState)
      ? state
      : undefined;

    let params = this.buildParams(this.inboxParams(category, id), {
      sort_field: orderBy.sort_field,
      sort_direction: orderBy.sort_direction,
      state,
      ticket_state,
      mentions_status: mentionsStatus,
      count,
      include_latest_conversation: includeLatestConversation,
      active_conversation_id: activeConversationId,
      metadata,
      fields,
    });
    let requestInit: RequestInitWithPriority = {};
    if (metadata?.lowPriority) {
      requestInit.priority = 'low';
    }
    let response = await request(
      `/ember/inbox/conversations/list?${params.toString()}`,
      requestInit,
    );
    let json = (await response.json()) as {
      conversations: ConversationSummaryWireFormat[];
      latest_conversation?: ConversationWireFormat;
      active_conversation?: ConversationSummaryWireFormat;
      meta: { total: number; valid_for: number; now?: number };
    };

    return {
      total: json.meta.total,
      validFor: json.meta.valid_for,
      serverTime: json.meta.now ? new Date(json.meta.now) : undefined,
      conversation: json.latest_conversation
        ? Conversation.deserialize(json.latest_conversation)
        : undefined,
      conversations: json.conversations.map((conversation) =>
        ConversationSummary.deserialize(conversation),
      ),
      activeConversation: json.active_conversation
        ? ConversationSummary.deserialize(json.active_conversation)
        : undefined,
      inbox_id: id,
    };
  }

  async checkConversationsExist(init?: RequestInit) {
    let response = await request(
      `/ember/apps/${this.session.workspace.id}/conversations_exist.json?app_id=${this.session.workspace.id}`,
      init,
    );
    let json = (await response.json()) as {
      conversations_exists: boolean;
    };
    return json.conversations_exists;
  }

  async getUserConversations(
    userId: string,
    currentConversation?: number,
    init?: RequestInit,
  ): Promise<{
    conversations: LatestConversationSummary[];
    total_count: number;
  }> {
    let params = new URLSearchParams();
    params.append('user_id', userId);
    params.append('app_id', this.session.workspace.id);
    if (currentConversation) {
      params.append('ignorable_conversation_id', currentConversation.toString());
    }
    let response = await request(`/ember/inbox/conversations/for_user?${params.toString()}`, init);
    let json = (await response.json()) as {
      conversations: LatestConversationSummaryWireFormat[];
      total_count: number;
    };
    return {
      conversations: json.conversations.map(LatestConversationSummary.deserialize),
      total_count: json.total_count,
    };
  }

  async getSimilarConversations(currentConversation: string, init?: RequestInit) {
    let params = this.buildParams({ conversation_id: currentConversation });
    let response = await request(`/ember/inbox/conversations/similar?${params.toString()}`, init);
    let json = (await response.json()) as {
      conversations: LatestConversationSummaryWireFormat[];
      total_count: number;
    };
    return {
      conversations: json.conversations.map(LatestConversationSummary.deserialize),
      total_count: json.total_count,
    };
  }

  async getUserChecklists(
    userId: string,
    init?: RequestInit,
  ): Promise<{ checklists: Checklist[] }> {
    let params = new URLSearchParams();
    params.append('user_id', userId);
    params.append('app_id', this.session.workspace.id);

    let response = await request(
      `/ember/inbox/checklists/recent_checklists?${params.toString()}`,
      init,
    );

    let json = (await response.json()) as {
      checklists: ChecklistWireFormat[];
    };

    return {
      checklists: json.checklists.map(Checklist.deserialize),
    };
  }

  @cached({ max: 10, ttl: 5000 })
  async getLinkedTickets(conversationId: number): Promise<{
    tickets: LinkedTicketSummary[];
    totalCount: number;
  }> {
    let response = await request(
      `/ember/inbox/conversations/${conversationId}/list_linked_tickets?app_id=${this.session.workspace.id}`,
    );
    let json = (await response.json()) as {
      conversations: LinkedTicketSummaryWireFormat[];
      total_count: number;
    };
    return {
      tickets: json.conversations.map(LinkedTicketSummary.deserialize),
      totalCount: json.total_count,
    };
  }

  async getSideConversations(
    parentConversationId: number,
    init?: RequestInit,
  ): Promise<Array<SideConversation>> {
    let response = await request(
      `/ember/inbox/side_conversations?app_id=${this.session.workspace.id}&parent_conversation_id=${parentConversationId}`,
      init,
    );
    let json = (await response.json()) as { side_conversations: SideConversationWireFormat[] };
    return json.side_conversations.map(SideConversation.deserialize);
  }

  async getSideConversation(
    sideConversationId: number,
    parentConversationId: number,
    init?: RequestInit,
  ) {
    let url = `/ember/inbox/side_conversations/${sideConversationId}?app_id=${this.session.workspace.id}&parent_conversation_id=${parentConversationId}`;

    let response = await request(url, init);
    let json = (await response.json()).side_conversation as ConversationWireFormat;
    return SideConversation.deserializeToConversation(json);
  }

  async getGithubLinks(
    conversationId: number,
    init?: RequestInit,
  ): Promise<{
    github_links: GithubLinkSummary[];
    total_count: number;
  }> {
    let response = await request(
      `/ember/inbox/conversations/${conversationId}/list_github_issues?app_id=${this.session.workspace.id}`,
      init,
    );
    let json = (await response.json()) as {
      github_issues: GithubLinkSummaryWireFormat[];
      total_count: number;
    };
    return {
      github_links: json.github_issues.map(GithubLinkSummary.deserialize),
      total_count: json.total_count,
    };
  }

  async createGithubLink(conversationId: number, link: string): Promise<GithubLinkSummary> {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversationId}/link_github_issue?app_id=${this.session.workspace.id}`,
      {
        link,
      },
    );
    return await response.json();
  }

  fetchTableConversationsForInbox(
    category: InboxCategory,
    id: string,
    state: InboxStateOption | undefined,
    fields: string[],
    count: number,
    sortParams: SortParams,
    mentionsStatus?: InboxMentionsStatus,
    init?: RequestInit,
  ): Promise<{ conversations: ConversationTableEntry[]; total: number; validFor: number }> {
    let ticket_state = Object.values(TicketSystemState).includes(state as TicketSystemState)
      ? state
      : undefined;
    state = Object.values(ConversationState).includes(state as ConversationState)
      ? state
      : undefined;
    let params = this.buildParams(this.inboxParams(category, id), sortParams, {
      state,
      ticket_state,
      fields,
      count,
      mentions_status: mentionsStatus,
    });

    return this.fetchTableConversationsForParams(params, init);
  }

  fetchTableConversationsForList(
    conversationIds: number[],
    fields: string[],
    init?: RequestInit,
  ): Promise<{ conversations: ConversationTableEntry[] }> {
    let params = this.buildParams({ conversation_ids: conversationIds, fields });

    return this.fetchTableConversationsForParams(params, init);
  }

  async fetchTableConversationsForParams(
    params: URLSearchParams,
    init?: RequestInit,
  ): Promise<{ total: number; conversations: ConversationTableEntry[]; validFor: number }> {
    let response = await request(`/ember/inbox/conversations/table?${params.toString()}`, init);
    let json = (await response.json()) as {
      conversations: ConversationTableEntryWireFormat[];
      meta: { total: number; valid_for: number };
    };

    return {
      total: json.meta.total,
      validFor: json.meta.valid_for,
      conversations: json.conversations.map((conversation) =>
        ConversationTableEntry.deserialize(conversation),
      ),
    };
  }

  // disable caching for this request whilst we're cancelling requests in conversation-resource
  // do not add this back in without reverting cancellation
  // @cached({ max: 10, ttl: 5000 })
  async fetchConversation(
    conversationId: number,
    clientAssignedUuid?: string,
    init?: RequestInitWithPriority,
  ) {
    let url = `/ember/inbox/conversations/${conversationId}?app_id=${this.session.workspace.id}`;
    if (clientAssignedUuid) {
      url += `&client_assigned_uuid=${encodeURIComponent(clientAssignedUuid)}&s=${this.session.id}`;
    }
    let getConversationStartedAt = Date.now();
    let response = await request(url, init);
    let json = (await response.json()) as ConversationWireFormat;
    if (clientAssignedUuid) {
      this.frontendStatsService.enqueueInteractionMetric({
        name: 'messenger-to-inbox',
        client_assigned_uuid: clientAssignedUuid,
        session_uuid: this.session.id,
        fetch_conversation_ms: Date.now() - getConversationStartedAt,
      });
    }
    return Conversation.deserialize(json);
  }

  async fetchConversations(conversationIds: number[], init?: RequestInit) {
    let params = this.buildParams({ ids: conversationIds });
    let response = await request(
      `/ember/inbox/conversations/list_by_ids?${params.toString()}`,
      init,
    );
    let json = (await response.json()) as {
      conversations: ConversationSummaryWireFormat[];
    };
    return json.conversations.map(ConversationSummary.deserialize);
  }

  async areAllConversationsCloseable(
    conversationIds: number[],
    attributeIdentifiersToAdd?: string[],
    init?: RequestInit,
  ): Promise<boolean> {
    let params = this.buildParams({
      conversation_ids: conversationIds,
      attributes_to_add: attributeIdentifiersToAdd,
    });
    let response = await request(
      `/ember/inbox/conversations/are_all_closeable?${params.toString()}`,
      init,
    );
    let json = (await response.json()) as {
      are_all_closeable: boolean;
    };
    return json.are_all_closeable;
  }

  async fetchSmartReply(conversationId: number, init?: RequestInit) {
    let params = buildParams(this.session.workspace.id, { conversation_id: conversationId });
    let response;
    try {
      response = await request(`/ember/inbox/smart_replies?${params.toString()}`, init);
    } catch (_e) {
      return null;
    }
    let json = await response.json();
    return new SmartReply(json.suggestion as SmartReplyWireFormat);
  }

  async fetchOpenAICompletion(
    promptData: PromptData,
    conversationId: number | undefined,
    useImageVariant: boolean,
  ) {
    let params = {
      prompt_key: promptData.promptKey,
      use_image_variant: useImageVariant,
      input_variables: promptData.inputVariables,
      entity_id: conversationId,
      entity_type: EntityType.Conversation,
    };

    let request = await postRequest(
      `/ember/inbox/open_ai_demo/completion_v2?app_id=${this.session.workspace.id}`,
      params,
    );

    let json = (await request.json()) as {
      completion_text?: string;
      request_id?: string;
    };

    return {
      completionText: json.completion_text?.trim(),
      requestId: json.request_id,
    };
  }

  async fetchToneCompletion(
    reply: string,
    conversationId: number | undefined,
    keepImages: boolean,
    userId: string | undefined,
  ): Promise<ToneCompletionFailure | ToneCompletionSuccess> {
    let params: {
      reply: string;
      conversation_id: number | undefined;
      keep_images: boolean;
      user_id: string | undefined;
    } = {
      reply,
      conversation_id: conversationId,
      keep_images: keepImages,
      user_id: userId,
    };
    let request = await postRequest(
      `/ember/inbox/open_ai_demo/tone_transfer?app_id=${this.session.workspace.id}`,
      params,
    );
    let json = (await request.json()) as
      | ToneCompletionSuccessWireFormat
      | ToneCompletionFailureWireFormat;

    if (json.success) {
      return {
        completionText: json.completion_text,
        success: json.success,
        requestId: json.request_id,
      };
    } else {
      return {
        failureReason: json.failure_reason,
        success: json.success,
        requestId: json.request_id,
      };
    }
  }

  async searchForConversationsTableV2({
    query,
    sortParams,
    count,
    predicates,
    searchSource,
    fields,
  }: {
    query: string;
    sortParams: SortParams;
    count: number;
    predicates: { predicates: Predicate[] };
    fields: string[];
    searchSource?: string;
  }) {
    let request = await postRequest(
      `/ember/inbox/conversations/search?app_id=${this.session.workspace.id}`,
      {
        query,
        inbox_type: 'all',
        predicates,
        sort_direction: sortParams.sort_direction,
        sort_key: sortParams.sort_field,
        count,
        fields,
        search_source: searchSource,
      },
    );

    let result = await request.json();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'searched',
      object: 'conversations',
      place: 'inbox',
      section: 'search',
      query_keyword: query,
      number_of_results: result.meta.total,
      version: 'v2',
      predicates: JSON.stringify(predicates),
    });

    return {
      total: result.meta.total,
      conversations: result.conversations.map(ConversationTableEntry.deserialize),
    };
  }

  async replyToConversation(
    conversation: Conversation,
    blocks: any,
    pendingPart: RenderablePart,
    replyData?: ReplyDataType,
    recipients?: RecipientsWireFormat,
    emailHistoryMetadataId?: number,
  ) {
    if (this.session.workspace.isFeatureEnabled('realtime-translation')) {
      let translationEnabled =
        this.conversationTranslationSettings.autoTranslationEnabledForConversation(conversation.id);
      replyData = replyData || {};
      replyData.translate_content = translationEnabled;
    }

    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/reply?app_id=${this.session.workspace.id}`,
      {
        blocks,
        client_assigned_uuid: pendingPart.clientAssignedUuid,
        reply_data: replyData,
        email_history_metadata_id: emailHistoryMetadataId,
        recipients,
      },
    );

    let json = (await response.json()) as RenderablePartWireFormat[];

    let parts = json.map(RenderablePart.deserialize);

    if (this.session.workspace.isFeatureEnabled('team-inbox-ai-browser-extension')) {
      // this await is very fast in practice.
      let activities = await fetchActivities();
      if (activities) {
        // no await here. We want to run this async
        postRequest(`/ember/record_browser_activity?app_id=${this.session.workspace.id}`, {
          conversation_id: conversation.id,
          part_id: parts[0].id,
          activities,
        });
      }
    }

    return parts;
  }

  async addNoteToConversation(
    conversation: Conversation,
    blocks: any,
    part: RenderablePart,
    crossPost = false,
  ) {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/add_note?app_id=${this.session.workspace.id}`,
      { blocks, client_assigned_uuid: part.clientAssignedUuid, cross_post: crossPost },
    );

    let json = (await response.json()) as RenderablePartWireFormat[];

    return json.map(RenderablePart.deserialize);
  }

  async addSummaryToConversation(conversation: Conversation, part: RenderablePart) {
    let summaryInAppLocale =
      this.session.workspace.isFeatureEnabled('realtime-translation') &&
      this.conversationTranslationSettings.autoTranslationEnabledForConversation(conversation.id);
    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/add_summary?app_id=${this.session.workspace.id}`,
      { client_assigned_uuid: part.clientAssignedUuid, in_app_locale: summaryInAppLocale },
    );

    let json = (await response.json()) as RenderablePartWireFormat[];

    return json.map(RenderablePart.deserialize);
  }

  async generateSummaryForConversation(conversationId: number, init?: RequestInit) {
    let summaryInAppLocale =
      this.session.workspace.isFeatureEnabled('realtime-translation') &&
      this.conversationTranslationSettings.autoTranslationEnabledForConversation(conversationId);
    let response = await request(
      `/ember/inbox/conversations/${conversationId}/summary?app_id=${this.session.workspace.id}&in_app_locale=${summaryInAppLocale}`,
      init,
    );
    return (await response.json()) as {
      conversation_id?: number;
      summary: BlockList;
    };
  }

  async closeConversation(
    conversation: ConversationRecord,
    part: RenderablePart,
    checkRequiredAttributes = false,
  ) {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/close?app_id=${this.session.workspace.id}`,
      {
        client_assigned_uuid: part.clientAssignedUuid,
        check_required_attributes: checkRequiredAttributes,
      },
    );

    let json = (await response.json()) as RenderablePartWireFormat[];

    return json.map(RenderablePart.deserialize);
  }

  async updateConversationTitle(
    conversation: Conversation,
    newTitle: string,
    part: RenderablePart,
  ) {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/change_title?app_id=${this.session.workspace.id}`,
      { title: newTitle, client_assigned_uuid: part.clientAssignedUuid },
    );

    let json = (await response.json()) as RenderablePartWireFormat[];

    return json.map(RenderablePart.deserialize);
  }

  async changeTicketState(
    conversation: ConversationRecord,
    ticketState: TicketCustomState,
    trackingSection?: string,
    part?: RenderablePart,
    relatedPartId?: number,
    adminLabel?: string,
  ) {
    let applyLocalUpdates = !part;
    if (applyLocalUpdates && conversation instanceof Conversation) {
      part = conversation.addPendingPart(
        new TicketStateUpdatedByAdmin(
          this.session.teammate,
          ticketState.systemState,
          !conversation.visibleToUser,
          conversation.id,
          conversation?.ticketType?.id,
          conversation.visibleToUser,
          undefined, // id
          undefined, // ticketTitle
          adminLabel,
        ),
      );
    }

    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/change_ticket_state?app_id=${this.session.workspace.id}`,
      {
        ticket_state: ticketState.id,
        client_assigned_uuid: part?.clientAssignedUuid,
        related_part_id: relatedPartId,
      },
    );

    let json = (await response.json()) as RenderablePartWireFormat[];

    this.intercomEventService.trackAnalyticsEvent({
      action: 'changed',
      object: 'ticket_state',
      place: 'inbox',
      section: trackingSection || 'conversation_details',
      conversation_id: conversation.id,
      ticket_state: ticketState.systemState,
    });

    let createdParts = json.map(RenderablePart.deserialize);

    if (applyLocalUpdates && part && conversation instanceof Conversation) {
      conversation.commitPendingPart(part, createdParts);
    }

    return createdParts;
  }

  async openConversation(conversation: ConversationRecord, part?: RenderablePart) {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/open?app_id=${this.session.workspace.id}`,
      { client_assigned_uuid: part?.clientAssignedUuid },
    );

    let json = (await response.json()) as RenderablePartWireFormat[];

    return json.map(RenderablePart.deserialize);
  }

  async snoozeConversation(
    conversation: ConversationRecord,
    until: DurationObject,
    part: RenderablePart,
  ) {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/snooze?app_id=${this.session.workspace.id}`,
      {
        snoozed_until: until.type,
        author_timezone: moment.tz.guess(true),
        client_assigned_uuid: part.clientAssignedUuid,
        custom_snoozed_until_time: until.type === DurationType.CustomTime ? until.time : undefined,
      },
    );
    this.logService.log({
      log_type: 'inboxApiSnoozeConversation',
      app_id: this.session.workspace.id,
      conversation_id: conversation.id,
      conversation_part_id: part.id,
      admin_id: this.session.teammate.id,
      snoozed_until_type: until.type,
      moment_cache_js_author_timezone: moment.tz.guess(),
      moment_no_cache_js_author_timezone: moment.tz.guess(true),
      client_assigned_uuid: part.clientAssignedUuid,
      custom_snoozed_until_time: until.type === DurationType.CustomTime ? until.time : undefined,
      browser_author_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    let json = (await response.json()) as RenderablePartWireFormat[];

    return json.map(RenderablePart.deserialize);
  }

  async changePriority(conversation: ConversationRecord, priority: boolean, part: RenderablePart) {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/change_priority?app_id=${this.session.workspace.id}`,
      {
        priority,
        client_assigned_uuid: part.clientAssignedUuid,
      },
    );

    let json = (await response.json()) as RenderablePartWireFormat[];

    return json.map(RenderablePart.deserialize);
  }

  async closeConversations(
    ids: number[],
    closeAndResolve = false,
    trackerTicketParams?: {
      id: number;
      linkedCustomerReports: number[] | string;
    },
  ) {
    let response = await postRequest(
      `/ember/inbox/conversations/close_all?app_id=${this.session.workspace.id}`,
      {
        ids,
        close_and_resolve: closeAndResolve,
        tracker_ticket_params: trackerTicketParams
          ? {
              id: trackerTicketParams.id,
              linked_customer_reports: trackerTicketParams.linkedCustomerReports,
            }
          : null,
      },
    );

    return await response.json();
  }

  async bulkChangeTicketState(
    ids: number[],
    ticketState: TicketCustomState['id'],
    trackerTicketParams?: {
      id: number;
      linkedCustomerReports: number[] | string;
    },
  ): Promise<{ validConversationIds: number[]; invalidConversationIds: number[] }> {
    let response = await postRequest(
      `/ember/inbox/conversations/change_ticket_state_all?app_id=${this.session.workspace.id}`,
      {
        ids,
        ticket_state: `${ticketState}`,
        tracker_ticket_params: trackerTicketParams
          ? {
              id: trackerTicketParams.id,
              linked_customer_reports: trackerTicketParams.linkedCustomerReports,
            }
          : null,
      },
    );

    let result = (await response.json()) as {
      valid_conversation_ids: number[];
      invalid_conversation_ids: number[];
    };

    return {
      validConversationIds: result.valid_conversation_ids,
      invalidConversationIds: result.invalid_conversation_ids,
    };
  }

  async openConversations(
    ids: number[],
    trackerTicketParams?: {
      id: number;
      linkedCustomerReports: 'all';
    },
  ) {
    let response = await postRequest(
      `/ember/inbox/conversations/open_all?app_id=${this.session.workspace.id}`,
      {
        ids,
        tracker_ticket_params: trackerTicketParams
          ? {
              id: trackerTicketParams.id,
              linked_customer_reports: trackerTicketParams.linkedCustomerReports,
            }
          : null,
      },
    );

    return await response.json();
  }

  async bulkAssignConversationsToAdmin(
    ids: number[],
    adminAssignee: AdminSummary,
    trackerTicketParams?: {
      id: number;
      linkedCustomerReports: 'all';
    },
  ) {
    let response = await postRequest(
      `/ember/inbox/conversations/assign_all?app_id=${this.session.workspace.id}`,
      {
        ids,
        admin_assignee_id: adminAssignee.id,
        tracker_ticket_params: trackerTicketParams
          ? {
              id: trackerTicketParams.id,
              linked_customer_reports: trackerTicketParams.linkedCustomerReports,
            }
          : null,
      },
    );

    return await response.json();
  }

  async bulkAssignConversationsToTeam(
    ids: number[],
    teamAssignee: TeamSummary,
    trackerTicketParams?: {
      id: number;
      linkedCustomerReports: 'all';
    },
  ) {
    let response = await postRequest(
      `/ember/inbox/conversations/assign_all?app_id=${this.session.workspace.id}`,
      {
        ids,
        admin_assignee_id: AdminSummary.unassigned.id,
        team_assignee_id: teamAssignee.id,
        tracker_ticket_params: trackerTicketParams
          ? {
              id: trackerTicketParams.id,
              linked_customer_reports: trackerTicketParams.linkedCustomerReports,
            }
          : null,
      },
    );

    return await response.json();
  }

  async changeConversationsPriority(
    ids: number[],
    priority: boolean,
    trackerTicketParams?: {
      id: number;
      linkedCustomerReports: 'all';
    },
  ) {
    let response = await postRequest(
      `/ember/inbox/conversations/change_priority_all?app_id=${this.session.workspace.id}`,
      {
        ids,
        priority,
        tracker_ticket_params: trackerTicketParams
          ? {
              id: trackerTicketParams.id,
              linked_customer_reports: trackerTicketParams.linkedCustomerReports,
            }
          : null,
      },
    );

    return await response.json();
  }

  async updateAttribute(
    conversationId: Conversation['id'],
    attribute: ConversationAttributeSummary | TicketAttributeSummary,
    clientAssignedUuid?: string,
    options?: { signal?: AbortSignal },
  ) {
    let { workspace } = this.session;

    interface Request {
      value: any;
      client_assigned_uuid?: string;
    }

    let payload: Request = { value: attribute.serializedValue };
    if (clientAssignedUuid) {
      payload.client_assigned_uuid = clientAssignedUuid;
    }

    let request = await putRequest(
      `/ember/conversations/${conversationId}/conversation_attributes/${attribute.descriptor.id}?app_id=${workspace.id}`,
      payload,
      options,
    );

    let json = (await request.json()) as {
      id: string;
      value: string | number | boolean;
    };

    //if we provide a uuid then we're using optimisic updates and don't need to explicitly update the attribute
    if (!clientAssignedUuid) {
      attribute.update(json.value);
    }
  }

  async assignConversationToAdmin(
    conversation: ConversationRecord,
    part: RenderablePart,
    assignee: AdminSummary,
    activeInbox?: Inbox,
    trackingSection?: string,
    layoutType?: ConversationsViewType,
  ) {
    let assignmentType;
    if (assignee.isUnassignedAssignee) {
      assignmentType = 'unassigned';
    } else if (assignee.id === this.session.teammate.id) {
      assignmentType = 'me';
    } else {
      assignmentType = 'teammate';
    }

    this.trackAssignmentAnalyticsEvent({
      conversation,
      activeInbox,
      assignmentType,
      adminAssigneeChanged: assignee.id !== conversation.adminAssignee?.id,
      teamAssigneeChanged: false,
      section: trackingSection,
      layoutType,
      assigneeId: assignee.id,
    });

    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/assign?app_id=${this.session.workspace.id}`,
      {
        admin_assignee_id: assignee.id,
        client_assigned_uuid: part.clientAssignedUuid,
      },
    );

    let json = (await response.json()) as RenderablePartWireFormat[];

    return json.map(RenderablePart.deserialize);
  }

  async assignConversationToTeam(
    conversation: ConversationRecord,
    part: RenderablePart,
    assignee: TeamSummary,
    activeInbox?: Inbox,
    trackingSection?: string,
    layoutType?: ConversationsViewType,
  ) {
    let unassigned = AdminSummary.unassigned;

    this.trackAssignmentAnalyticsEvent({
      conversation,
      activeInbox,
      assignmentType: !assignee.isUnassignedAssignee ? 'team' : 'unassigned',
      adminAssigneeChanged: true,
      teamAssigneeChanged: assignee.id !== conversation.teamAssignee?.id,
      section: trackingSection,
      layoutType,
      assigneeId: assignee.id,
    });

    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/assign?app_id=${this.session.workspace.id}`,
      {
        team_assignee_id: assignee.id,
        admin_assignee_id: unassigned.id,
        client_assigned_uuid: part.clientAssignedUuid,
      },
    );

    let json = (await response.json()) as RenderablePartWireFormat[];

    return json.map(RenderablePart.deserialize);
  }

  async markMentionsAsRead(conversation: ConversationRecord): Promise<void> {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/mark_mentions_as_read?app_id=${this.session.workspace.id}`,
      { priority: 'low' },
    );

    return await response.json();
  }

  async markAsRead(conversation: Conversation): Promise<void> {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/mark_as_read?app_id=${this.session.workspace.id}`,
      { priority: 'low' },
    );

    return await response.json();
  }

  async markUnread(conversationId: number): Promise<void> {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversationId}/mark_as_unread?app_id=${this.session.workspace.id}`,
    );

    return await response.json();
  }

  async listSidebarSections(init?: RequestInit): Promise<SidebarSection[]> {
    let response = await request(
      `/ember/inbox/sidebar_sections?app_id=${this.session.workspace.id}`,
      init,
    );
    let json = (await response.json()) as { sidebar_sections: SidebarSectionWireFormat[] };
    let sidebarSections = json.sidebar_sections.map(SidebarSection.deserialize);
    return sidebarSections;
  }

  @cached({ max: 1, ttl: FIVE_MINUTES })
  async listAvailableSidebarSections(): Promise<SidebarSection[]> {
    let response = await request(
      `/ember/inbox/sidebar_sections/available_sections?app_id=${this.session.workspace.id}`,
    );
    let json = (await response.json()) as { sidebar_sections: SidebarSectionWireFormat[] };
    let sidebarSections = json.sidebar_sections.map(SidebarSection.deserialize);
    return sidebarSections;
  }

  async updateSidebarSections(sections: SidebarSection[]) {
    let workspaceId = this.session.workspace.id;
    let id = `${workspaceId}-${this.session.teammate.id}`;

    let response = await putRequest(
      `/ember/inbox/admin_app_sidebar_section_lists/${id}?app_id=${workspaceId}`,
      {
        sections: sections.map((section) => section.serialize()),
      },
    );

    return await response.json();
  }

  async updateUserAttribute(
    attribute: Attribute,
    value: any,
    user: User,
    company?: Company,
  ): Promise<User> {
    let role = user.hasLeadRole ? 'lead' : 'user';

    let data: Record<string, unknown> = {
      app_id: this.session.workspace.id,
      attribute_belongs_to_type: attribute.isCompany ? 'company' : role,
      attribute_identifier: attribute.key,
      attribute_value: value,
    };

    if (attribute.isCompany && company?.id) {
      data.qualification_company_id = company?.id;
    } else {
      data.id = user.id;
    }

    let response = await putRequest(`/ember/user_attributes/${user.id}`, data);
    return await response.json();
  }

  // deprecated - use this.session.workspace.ticketTypes resource, or this.session.workspace.fetchTicketTypes
  // instead where possible
  async listTicketTypes(): Promise<TicketType[]> {
    return this.session.workspace.fetchTicketTypes();
  }

  async fetchRequestTypeForMessageThread(
    messageThreadId: number,
    init?: RequestInit,
  ): Promise<TicketType> {
    let response = await request(
      `/ember/inbox/ticket_types/for_message_thread?app_id=${this.session.workspace.id}&message_thread_id=${messageThreadId}`,
      init,
    );
    let json = (await response.json()) as TicketTypeWireFormat;

    return TicketType.deserialize(json);
  }

  async createMacro(data: Record<string, unknown>) {
    // Returns a macro object, but not in the MacroWireFormat structure
    let response = await postRequest('/ember/saved_replies', data);
    return await response.json();
  }

  async applyMacroActions(
    conversationIds: number[],
    macroActions: MacroAction[],
    partsWithUuids: (RenderablePart | undefined)[] = [],
    trackerTicketParams?: {
      id: number;
      linkedCustomerReports: 'all';
    },
  ): Promise<void> {
    if (isEmpty(macroActions)) {
      return;
    }

    let actions = transformMacroActions(macroActions);
    if (!isEmpty(partsWithUuids)) {
      actions = applyClientAssignedUuids(actions, partsWithUuids);
    }

    if (this.session.workspace.isFeatureEnabled('realtime-translation')) {
      let translationEnabled = this.conversationTranslationSettings.autoTranslationEnabled;
      actions.forEach((action) => {
        if (action.type === 'reply-to-conversation') {
          action.action_data.translate_content = translationEnabled;
        }
      });
    }

    let response = await postRequest(`/ember/inbox/conversations/execute_actions`, {
      actions,
      conversation_ids: conversationIds,
      app_id: this.session.workspace.id,
      tracker_ticket_params: trackerTicketParams
        ? {
            id: trackerTicketParams.id,
            linked_customer_reports: trackerTicketParams.linkedCustomerReports,
          }
        : null,
    });
    return await response.json();
  }

  async addNote(modelId: string, body: string, isCompany = false): Promise<Note> {
    let response = await postRequest(`/ember/inbox/notes?app_id=${this.session.workspace.id}`, {
      model_id: modelId,
      is_company: isCompany,
      body,
    });

    let json = (await response.json()) as NoteWireFormat;

    return Note.deserialize(json);
  }

  async updateNote(note: Note, body: string): Promise<Note> {
    let response = await putRequest(
      `/ember/inbox/notes/${note.id}?app_id=${this.session.workspace.id}`,
      {
        body,
      },
    );

    let json = (await response.json()) as NoteWireFormat;

    return Note.deserialize(json);
  }

  async fetchNotes(noteIds: any[], init?: RequestInit): Promise<Note[]> {
    let params = buildParams(this.session.workspace.id, { ids: noteIds });
    let response = await request(`/ember/inbox/notes?${params.toString()}`, init);
    let json = (await response.json()) as NoteWireFormat[];
    return json.map((noteItem) => Note.deserialize(noteItem));
  }

  async updateTagsForUser(user: User, tags: Array<Tag>) {
    let response = await putRequest(
      `/ember/inbox/users/${user.id}/update_tags?app_id=${this.session.workspace.id}`,
      {
        tag_ids: tags
          .filter((tag) => tag.id)
          .map((tag) => tag.id)
          .map(Number),
        new_tags: tags.filter((tag) => !tag.id).map(Tag.serialize),
      },
    );
    return await response.json();
  }

  async updateTagsForCompany(companyId: string, tags: Array<Tag>) {
    let response = await putRequest(
      `/ember/inbox/companies/${companyId}/update_tags?app_id=${this.session.workspace.id}`,
      {
        tag_ids: tags
          .filter((tag) => tag.id)
          .map((tag) => tag.id)
          .map(Number),
        new_tags: tags.filter((tag) => !tag.id).map(Tag.serialize),
      },
    );
    return await response.json();
  }

  @invalidates({ type: QuickSearchService, functionName: 'searchMultipleTypes' })
  async updateTags(conversation: Conversation, renderablePart: RenderablePart, tags: Array<Tag>) {
    let partId =
      renderablePart.entityType === EntityType.ConversationPart ? renderablePart.entityId : null;

    let tag_ids = tags
      .filter((tag) => tag.id)
      .map((tag) => tag.id)
      .map(Number);
    let new_tags = tags.filter((tag) => !tag.id).map(Tag.serialize);

    if (partId === null) {
      this.logService.log({
        log_type: 'inboxApiUpdateTags_nullPartId',
        app_id: this.session.workspace.id,
        conversation_id: conversation.id,
        renderable_part_type: renderablePart.renderableType,
        renderable_part_id: renderablePart.id,
        entity_type: renderablePart.entityType,
        entity_id: renderablePart.entityId,
        tag_ids,
        new_tags,
      });
    }

    let response = await putRequest(
      `/ember/inbox/conversations/${conversation.id}/update_tags?app_id=${this.session.workspace.id}`,
      {
        conversation_part_id: partId,
        tag_ids,
        // tags without IDs need to be created
        new_tags,
      },
    );
    return await response.json();
  }

  async addTagToConversation(
    conversation: Conversation,
    renderablePart: RenderablePart,
    tag: Tag,
    clientAssignedUuid: string,
  ) {
    let conversationPartId =
      renderablePart.entityType === EntityType.ConversationPart ? renderablePart.entityId : null;

    let params = {
      conversation_part_id: conversationPartId,
      client_assigned_uuid: clientAssignedUuid,
    } as {
      conversation_part_id: number;
      client_assigned_uuid: string;
      tag_id?: string;
      new_tag?: Pick<TagWireFormat, 'id' | 'name'>;
    };

    if (tag.id) {
      params['tag_id'] = tag.id;
    } else {
      params['new_tag'] = Tag.serialize(tag);
    }

    let response = await putRequest(
      `/ember/inbox/conversations/${conversation.id}/add_tag?app_id=${this.session.workspace.id}`,
      params,
    );

    return await response.json();
  }

  async removeTagFromConversation(
    conversation: Conversation,
    renderablePart: RenderablePart,
    tag: Tag,
    clientAssignedUuid: string,
  ) {
    let conversationPartId =
      renderablePart.entityType === EntityType.ConversationPart ? renderablePart.entityId : null;

    let params = {
      conversation_part_id: conversationPartId,
      tag_id: Number(tag.id),
      client_assigned_uuid: clientAssignedUuid,
    };

    let response = await putRequest(
      `/ember/inbox/conversations/${conversation.id}/remove_tag?app_id=${this.session.workspace.id}`,
      params,
    );

    return await response.json();
  }

  async triggerWorkflow(
    conversation: Conversation | ConversationTableEntry,
    workflowDetails: WorkflowDetails,
  ) {
    await postRequest(`/ember/inbox/workflows/start`, {
      app_id: this.session.workspace.id,
      conversation_id: conversation.id,
      workflow_instance_id: workflowDetails.workflowInstanceId,
    });
  }

  async triggerWorkflowConnectorAction(
    conversation: Conversation | ConversationTableEntry,
    renderablePart: RenderablePart | null,
    action: WorkflowConnectorAction,
  ) {
    let partId = null;

    if (renderablePart && renderablePart.entityType === EntityType.ConversationPart) {
      partId = renderablePart.entityId;
    }

    return await postRequest(`/ember/inbox/workflow_connector/actions/execute`, {
      app_id: this.session.workspace.id,
      conversation_id: conversation.id,
      conversation_part_id: partId,
      action_id: action.id,
    });
  }

  async triggerFinAiAgent(conversation: Conversation | ConversationTableEntry) {
    await postRequest(`/ember/inbox/ai_agent/trigger`, {
      app_id: this.session.workspace.id,
      conversation_id: conversation.id,
    });
  }

  async searchUsers(
    predicates: any,
    options: { sort_by?: string } = {},
    searchComparison?: string,
  ): Promise<UserSummary[]> {
    return taskFor(this._searchUsers).perform(predicates, options, searchComparison);
  }

  async searchUserSuggestions(
    predicates: any,
    options: { sort_by?: string } = {},
    searchComparison?: string,
  ): Promise<UserSummary[]> {
    return taskFor(this._searchUsers).perform(predicates, options, searchComparison, true);
  }

  @task({ restartable: true })
  *_searchUsers(
    predicates: any,
    options: { sort_by?: string } = {},
    searchComparison?: string,
    forUserSuggestions?: boolean,
  ): TaskGenerator<UserSummary[]> {
    let debounceTimeout = searchComparison === 'starts_with' ? ENV.APP._100MS : ENV.APP._750MS;
    yield timeout(debounceTimeout);

    let defaultOptions = {
      page: 1,
      per_page: DEFAULT_SUGGESTIONS_TO_FETCH,
      include_count: false,
      sort_direction: 'asc',
    };

    let data = { predicates, ...defaultOptions, ...options };

    let endpoint =
      forUserSuggestions &&
      this.session.workspace.isFeatureEnabled('channels-user-suggestion-permission-control')
        ? 'search_user_suggestions'
        : 'search';

    let response = yield postRequest(
      `/ember/users/${endpoint}.json?app_id=${this.session.workspace.id}`,
      data,
    );

    let json = yield response.json();

    let users = json.users.map((userJson: UserSummaryWireFormat) =>
      UserSummary.deserialize(userJson),
    );

    return users;
  }

  async searchCompanySuggestions(
    predicates: any,
    options: { sort_by?: string } = {},
  ): Promise<CompanySummary[]> {
    return taskFor(this._searchCompanies).perform(predicates, options);
  }

  @task({ restartable: true })
  *_searchCompanies(
    predicates: any,
    options: { sort_by?: string } = {},
  ): TaskGenerator<CompanySummary[]> {
    let debounceTimeout = ENV.APP._750MS;
    yield timeout(debounceTimeout);

    let defaultOptions = {
      page: 1,
      per_page: DEFAULT_SUGGESTIONS_TO_FETCH,
      include_count: false,
      sort_direction: 'asc',
    };

    let data = { predicates, ...defaultOptions, ...options };

    let response = yield postRequest(
      `/ember/companies/search.json?app_id=${this.session.workspace.id}`,
      data,
    );

    let json = yield response.json();

    let companies = json.companies.map((companyJson: CompanySummaryWireFormat) =>
      CompanySummary.deserialize(companyJson),
    );

    return companies;
  }

  async createNewConversation(data: NewConversationWireFormat) {
    let response = await postRequest('/ember/users/send_support_conversation.json', data);
    return await response.json();
  }

  async createNewSideConversation(data: Record<string, unknown>) {
    let response = await postRequest('/ember/inbox/side_conversations', data);
    let json = await response.json();

    return SideConversation.deserialize(json.side_conversation);
  }

  async createNewTicket(data: Record<string, unknown>) {
    let response = await postRequest('/ember/users/create_ticket.json', data);
    return await response.json();
  }

  async createLinkedTicket(data: Record<string, unknown>) {
    let response = await postRequest(
      `/ember/inbox/conversations/${data.original_conversation_id}/create_linked_ticket?app_id=${this.session.workspace.id}`,
      data,
    );
    return await response.json();
  }

  async linkExistingTicket(data: Record<string, unknown>) {
    let response = await postRequest(
      `/ember/inbox/conversations/${data.conversation_id}/link_ticket?app_id=${this.session.workspace.id}`,
      data,
    );
    return await response.json();
  }

  async unlinkTicket(ticketId: number, conversationId: number) {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversationId}/unlink_ticket?app_id=${this.session.workspace.id}`,
      {
        ticket_id_to_unlink: ticketId,
      },
    );
    return await response.json();
  }

  async mergeConversation(conversationId: number, mergeIntoConversationId: number) {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversationId}/merge_conversation?app_id=${this.session.workspace.id}`,
      {
        conversation_ids: conversationId,
        merge_into_conversation_id: mergeIntoConversationId,
      },
    );
    return await response.json();
  }

  async linkReportsToTracker(conversationIds: number[], trackerTicketId: number) {
    let response = await postRequest(
      `/ember/inbox/conversations/link_reports?app_id=${this.session.workspace.id}`,
      {
        ticket_id: trackerTicketId,
        conversation_ids: conversationIds,
      },
    );
    return await response.json();
  }

  async createTicketFromConversation(
    conversation: Conversation,
    ticketTypeId: number,
    ticketAttributes: Array<{
      descriptor_id: number;
      value: string | number | boolean | Upload[] | Array<object> | number[] | undefined;
    }>,
    visibleToUser: boolean,
    companyId: string | null = null,
  ): Promise<any> {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversation.id}/create_ticket_from_conversation?app_id=${this.session.workspace.id}`,
      {
        ticket_type_id: ticketTypeId,
        ticket_attributes: ticketAttributes.filter(
          (item) => item.value !== undefined && item.value !== null,
        ),
        visible_to_user: visibleToUser,
        company_id: companyId,
      },
    );
    let json = await response.json();
    this.snackbar.notify(
      this.intl.t('inbox.notifications.ticket-added-to-conversation', {
        ticket_type: json.ticket_type.name,
      }),
    );
    return json;
  }

  async shareTicketWithUser(conversationId: number) {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversationId}/make_ticket_visible_to_user?app_id=${this.session.workspace.id}`,
      {},
    );
    this.snackbar.notify(this.intl.t('inbox.notifications.ticket-shared-with-user'));
    return await response.json();
  }

  async updateCompanyTicketAssociation(conversationId: number, companyId: string) {
    let response = await putRequest(
      `/ember/inbox/conversations/${conversationId}/update_company_ticket_association?app_id=${this.session.workspace.id}`,
      { company_id: companyId },
    );
    this.snackbar.notify(this.intl.t('inbox.notifications.company-ticket-association-updated'));
    return await response.json();
  }

  async createMessengerCard(
    id: string,
    locale: string,
    anchorLink: Record<string, string>,
    conversationId?: number,
    helpCenterId?: string,
  ) {
    let params = this.buildParams({
      locale,
      conversation_id: conversationId,
      anchor_link: anchorLink,
      help_center_id: helpCenterId,
    });

    let response = await request(`/ember/articles/${id}/messenger_card?${params.toString()}`);
    return await response.json();
  }

  async deleteMessage(conversation: Conversation, part: RenderablePart) {
    let partId = part.generatePermalinkId(conversation.id);

    let response = await postRequest(`/ember/conversation_parts/${partId}/redact`, {
      conversation_id: conversation.id,
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
    });
    return await response.json();
  }

  private trackAssignmentAnalyticsEvent({
    conversation,
    assignmentType,
    adminAssigneeChanged,
    teamAssigneeChanged,
    activeInbox,
    section,
    layoutType,
    assigneeId,
  }: {
    conversation: ConversationRecord;
    assignmentType: string;
    adminAssigneeChanged: boolean;
    teamAssigneeChanged: boolean;
    activeInbox?: Inbox;
    section?: string;
    layoutType?: ConversationsViewType;
    assigneeId?: number;
  }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'assigned',
      object: 'conversation',
      place: 'inbox',
      section: section || 'conversation_details',
      assignment_type: assignmentType,
      inbox_type: activeInbox?.type,
      admin_assignee_changed: adminAssigneeChanged,
      team_assignee_changed: teamAssigneeChanged,
      conversation_id: conversation.id,
      layout_type: layoutType,
      assignee_id: assigneeId,
    });
  }

  buildParams(...args: object[]): URLSearchParams {
    return buildParams(this.session.workspace.id, ...args);
  }

  private inboxParams(
    category: InboxCategory,
    id: string,
  ): { inbox_id: string | undefined; inbox_type: string } {
    if (category === InboxCategory.Shared) {
      return {
        inbox_type: id,
        inbox_id: undefined,
      };
    } else {
      return {
        inbox_type: category,
        inbox_id: id,
      };
    }
  }

  @cached({ max: 10, ttl: 5000 })
  async fetchAdminById(adminId: number) {
    let url = `/ember/admins/${adminId}?app_id=${this.session.workspace.id}`;

    let response = await request(url);
    let json = (await response.json()) as AdminSummaryWireFormat;
    return AdminSummary.deserialize(json);
  }

  async fetchSenderEmailAddresses(init?: RequestInit) {
    let response = await request(`/ember/sender_emails?app_id=${this.session.workspace.id}`, init);
    let json = (await response.json()).sender_emails as SenderEmailAddressSummaryWireFormat[];
    return json.map((address) => SenderEmailAddressSummary.deserialize(address));
  }

  async fetchWhatsappIntegrations(opts?: { no_external_updates: boolean }, init?: RequestInit) {
    let queryParams: URLSearchParams = new URLSearchParams({
      app_id: this.session.workspace.id,
    });
    if (opts?.no_external_updates) {
      queryParams.append('no_external_updates', opts.no_external_updates.toString());
    }
    let response = await request(`/ember/whatsapp/integrations?${queryParams}`, init);
    let json = (await response.json()) as WhatsappIntegrationSenderWireFormat[];
    return json.map((integration) => WhatsappIntegrationSender.deserialize(integration));
  }

  async getCompanyEmailAddress(init?: RequestInit): Promise<CompanyEmailAddress[]> {
    let url = `/ember/company_email_addresses?app_id=${this.session.workspace.id}`;

    let response = await request(url, init);
    let json = (await response.json()) as CompanyEmailAddressWireFormat[];
    return json.map((companyEmail) => CompanyEmailAddress.deserialize(companyEmail));
  }

  async blockUser(options: { id: string; conversation_id?: number }) {
    let response = await postRequest(
      `/ember/users/block?app_id=${this.session.workspace.id}`,
      options,
    );
    return await response.json();
  }

  async getLatestSocialConversationId(userId: string, channel: Channel, init?: RequestInit) {
    let params = buildParams(this.session.workspace.id, {
      user_id: userId,
      channel: channel.toString(),
    });
    let response = await request(
      `/ember/inbox/conversations/latest_open_social_conversation_for_user?${params.toString()}`,
      init,
    );
    let responseJson = (await response.json()) as {
      latest_open_social_conversation_id: number | undefined;
    };
    return responseJson.latest_open_social_conversation_id;
  }

  async getSmsUsageLimitsStatus(channel: Channel, init?: RequestInit) {
    let params = buildParams(this.session.workspace.id, {
      channel: channel.toString(),
    });
    let response = await request(
      `/ember/inbox/conversations/sms_usage_limits_status?${params.toString()}`,
      init,
    );
    let responseJson = (await response.json()) as {
      sms_usage_limit_status: number | undefined;
    };
    return responseJson.sms_usage_limit_status;
  }

  async removeSla(conversationId: number) {
    return await deleteRequest(
      `/ember/conversations/${conversationId}/sla?app_id=${this.session.workspace.id}`,
    );
  }

  async updateConversationParticipants(
    conversationId: number,
    participantData: ParticipantWireFormat,
  ) {
    let response = await postRequest(
      `/ember/inbox/conversations/${conversationId}/change_participants?app_id=${this.session.workspace.id}`,
      {
        new_participant_emails: participantData?.newParticipantEmails,
        new_participant_ids: participantData?.newParticipantIds,
        removed_participant_ids: participantData?.removedParticipantIds,
      },
    );
    return await response.json();
  }

  async fetchForwardingContext(partId: string, init?: RequestInit) {
    let response = await request(
      `/ember/inbox/forwarding_context?app_id=${this.session.workspace.id}&part_permalink_id=${partId}`,
      init,
    );
    return await response.json();
  }

  async fetchConversationHistory(
    conversationId: number,
    conversationPartId: number | undefined,
    init?: RequestInit,
  ) {
    let response = await request(
      `/ember/inbox/email_history/build?app_id=${this.session.workspace.id}&conversation_id=${conversationId}&conversation_part_id=${conversationPartId}`,
      init,
    );

    let json = (await response.json()) as {
      history: { blocks: BlockList; from_external_sender: boolean; metadata_id: number };
      conversation_id: number;
    };

    return {
      history: json.history,
      responseConversationId: json.conversation_id,
    };
  }

  async fetchEmailPartParticipants(
    conversationId: number,
    conversationPartId: number,
    init?: RequestInit,
  ) {
    let response = await request(
      `/ember/inbox/conversation_participants/fetch_for_email_part?app_id=${this.session.workspace.id}&conversation_id=${conversationId}&conversation_part_id=${conversationPartId}`,
      init,
    );
    return response.json();
  }

  async updateSeenState(partId: string) {
    return await postRequest(
      '/ember/conversation_parts/update_admin_seen_state',
      {
        app_id: this.session.workspace.id,
        id: partId,
      },
      { priority: 'low' },
    );
  }

  async fetchFolders(init?: RequestInit): Promise<InboxFolder[]> {
    let params = buildParams(this.session.workspace.id, []);
    let response = await request(`/ember/inbox/inbox_folders/?${params.toString()}`, init);
    let json = (await response.json()) as InboxFoldersWireFormat;
    return json.inbox_folders.map(InboxFolder.deserialize);
  }

  async searchKnowledgeBase({
    copilotState,
    searchTerm,
    entityTypes,
    states,
    init,
  }: {
    searchTerm: string;
    entityTypes: EntityType[];
    states: State[];
    init?: RequestInit;
    copilotState?: AiContentState;
  }): Promise<KnowledgeBaseSearchResult[]> {
    let params = this.buildParams({
      content_terms: searchTerm,
      object_types: entityTypes,
      copilot_state: copilotState,
      states,
    });
    let response = await request(`/ember/inbox/internal_knowledge_base?${params.toString()}`, init);
    let json = (await response.json()) as KnowledgeBaseSearchResultsWireFormat;
    return json.results.map(KnowledgeBaseSearchResult.deserialize);
  }

  async fetchKnowledgeBaseContent(
    entityId: KnowledgeBaseSearchResult['entityId'],
    entityType: EntityType,
    conversationUserId: string,
    init?: RequestInit,
  ): Promise<KnowledgeBaseContent> {
    let params = this.buildParams({
      entity_id: entityId,
      entity_type: entityType,
      user_id: conversationUserId,
    });
    let response = await request(
      `/ember/inbox/internal_knowledge_base/show?${params.toString()}`,
      init,
    );
    let json = (await response.json()) as { result: KnowledgeBaseContentWireFormat };
    return KnowledgeBaseContent.deserialize(json.result);
  }

  async fetchKnowledgeBaseFolders(init?: RequestInit): Promise<KnowledgeBaseFolder[]> {
    let params = this.buildParams();

    let response = await request(
      `/ember/inbox/internal_knowledge_base/folders?${params.toString()}`,
      init,
    );
    let json = (await response.json()) as { results: KnowledgeBaseFolderWireFormat[] };

    return json.results.map(KnowledgeBaseFolder.deserialize);
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxApi: InboxApi;
    'inbox-api': InboxApi;
  }
}
