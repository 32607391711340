/* RESPONSIBLE TEAM: team-purchase */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';

import type IntlService from 'embercom/services/intl';
import { type TaskGenerator } from 'ember-concurrency';
import type Store from '@ember-data/store';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { FIXED_DISCOUNT_OFFERED } from 'embercom/lib/intershop/intershop';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

import {
  INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
  CORE_ENGAGE_PRO_ID,
  CORE_ENGAGE_PREMIUM_ID,
  CORE_CONVERT_PRO_ID,
  PEOPLE_REACHED_TIER_1_ID,
  PEOPLE_REACHED_TIER_2_ID,
  PEOPLE_REACHED_TIER_3_ID,
  PROACTIVE_SUPPORT_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import type Plan from 'embercom/models/plan';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';

export interface PricesParams {
  app_id: string;
  plan_ids: Array<string>;
  pricing_model_identifier: string;
  source: 'intershop-pricing';
  usage: Partial<Record<Metric, number>>;
}

export interface Price {
  pre_discount_amount?: number;
}

export interface PricesResponse {
  prices: Array<Price>;
}

export enum BillablePeriod {
  Months = 'months',
  Years = 'years',
}

const CORE_ENGAGE_PRO_ID_AS_NUMBER = Number(CORE_ENGAGE_PRO_ID);
const CORE_ENGAGE_PREMIUM_ID_AS_NUMBER = Number(CORE_ENGAGE_PREMIUM_ID);
const CORE_CONVERT_PRO_ID_AS_NUMBER = Number(CORE_CONVERT_PRO_ID);
const PEOPLE_REACHED_TIER_1_ID_AS_NUMBER = Number(PEOPLE_REACHED_TIER_1_ID);
const PEOPLE_REACHED_TIER_2_ID_AS_NUMBER = Number(PEOPLE_REACHED_TIER_2_ID);
const PEOPLE_REACHED_TIER_3_ID_AS_NUMBER = Number(PEOPLE_REACHED_TIER_3_ID);
const PROACTIVE_SUPPORT_BASE_ID_AS_NUMBER = Number(PROACTIVE_SUPPORT_BASE_ID);
const FIN_AI_COPILOT_BASE_ID_AS_NUMBER = Number(FIN_AI_COPILOT_BASE_ID);

// metrics that we do not allow discounts for
const DISCOUNT_UNAVAILABLE = [Metric.resolutions];

// the maximum value available to purchase via the UI for a pricing metric
const DEFAULT_PRICING_METRIC_LIMIT = 100000;
const PRICING_METRIC_LIMIT: Partial<Record<Metric, number>> = {
  [Metric.resolutions]: 10000,
};

export default class IntershopService extends Service {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare customerService: any;

  //Follow up PR use this everywhere in intershop and remove billablePeriod consts and strings from everywhere
  @tracked billablePeriod: BillablePeriod = BillablePeriod.Months;
  @tracked initialTotalPrice = 0;
  @tracked selectedUsageTotalPrice = 0;
  @tracked newPriceBreakdown: any | null = null;
  @tracked dollarAmountDiscount = 0;
  @tracked plan: Plan | null = null;

  PEOPLE_REACHED_TIER_IDS_AS_NUMBERS = [
    PEOPLE_REACHED_TIER_1_ID_AS_NUMBER,
    PEOPLE_REACHED_TIER_2_ID_AS_NUMBER,
    PEOPLE_REACHED_TIER_3_ID_AS_NUMBER,
  ];

  get isArticles(): boolean | undefined {
    return this.plan?.product.isArticles;
  }

  get showPricingPopover(): boolean {
    return !this.isArticles && !taskFor(this.requestPricesWithUsage).isRunning;
  }

  get costDifferenceMinusDiscount(): number {
    if (!this.hideDiscount) {
      return this.costDifference() + this.dollarAmountDiscount;
    }
    return this.costDifference();
  }

  get costDifferenceIsSavings(): boolean {
    return this.costDifferenceMinusDiscount < 0;
  }

  get fixedDiscountOffered(): number {
    return FIXED_DISCOUNT_OFFERED;
  }

  get contract() {
    // TODO(smg): for Sales Led, this will always be populated, for Self Serve it will return null, we need to account for this later
    return this.store.peekRecord('billing/contract', this.appService.app.id);
  }

  get contractUsageLimits() {
    return this.contract.contractUsageLimits;
  }

  get billedUsage() {
    return this.contract.billedUsage;
  }

  get totalUsage() {
    return this.contract.totalUsage;
  }

  get hideDiscount(): boolean {
    // Should not use discount if there if the plan is not a billableCustomerPlan
    // or it is an add on with overlapping metrics with a core plan
    if (!this.plan?.billableCustomerPlan) {
      return true;
    }
    return this.planHasOverlappingMetricsAndIsAddon;
  }

  get costDifferenceString(): string {
    if (this.costDifferenceIsSavings) {
      return this.intl.t('intershop.pricing_summary.negative_cost_difference_header', {
        billablePeriod: this.billablePeriod,
      });
    }
    return this.intl.t('intershop.pricing_summary.positive_cost_difference_header');
  }

  get overlappingPlan() {
    let overlappingPlans = this.customerService
      .getPlansWithOverlappingPricingMetrics(this.plan)
      .map((id: number) => this.customerService.plans.find((plan: Plan) => Number(plan.id) === id));
    // ------------------ TODO ------------------ //
    // Need to add case for proactive support if engage or convert is active
    // People reached should be static unless neither engage or convert is active
    if (overlappingPlans.length && this.plan?.product.addon) {
      return overlappingPlans.firstObject;
    }
    return null;
  }

  get planHasOverlappingMetricsAndIsAddon() {
    let overlappingPlansIds = this.customerService.getPlansWithOverlappingPricingMetrics(this.plan);
    // ------------------ TODO ------------------ //
    // Need to allow add ons for QAP if messages is not active
    return overlappingPlansIds.length && this.plan?.product.addon;
  }

  getMetricOverageValue(metric: string): number {
    return this.getContract().billedUsage[metric] - this.getContract().contractUsageLimits[metric];
  }

  //billedUsage is the higher of the customers usage limit agreement and actual usage.
  getMetricBilledUsage(metric: string): number {
    return this.getContract().billedUsage[metric];
  }

  getBilledUsage(): any {
    return this.getContract().billedUsage;
  }

  getMetricContractedValue(metric: string): number {
    return this.getContract().contractUsageLimits[metric];
  }

  costDifference() {
    return this.selectedUsageTotalPrice - this.initialTotalPrice;
  }

  hasBillablePeopleReachedTierId() {
    return this.customerService.billableCustomerPlanIds.find((id: number) =>
      this.PEOPLE_REACHED_TIER_IDS_AS_NUMBERS.includes(id),
    );
  }

  peopleReachedPlanId(planId: number): number | undefined {
    if (this.defaultPeopleReachedTier(planId)) {
      return this.peopleReachedTier(planId);
    }
    return;
  }

  defaultPeopleReachedTier(planId: number): number | undefined {
    switch (planId) {
      case CORE_CONVERT_PRO_ID_AS_NUMBER:
      case PROACTIVE_SUPPORT_BASE_ID_AS_NUMBER:
        return PEOPLE_REACHED_TIER_1_ID_AS_NUMBER;
      case CORE_ENGAGE_PRO_ID_AS_NUMBER:
        return PEOPLE_REACHED_TIER_2_ID_AS_NUMBER;
      case CORE_ENGAGE_PREMIUM_ID_AS_NUMBER:
        return PEOPLE_REACHED_TIER_3_ID_AS_NUMBER;
      default:
        return undefined;
    }
  }

  // Convert requires People reached Tier 1
  // Engage Pro requires People Reached Tier 2
  // Engage Premium requires People Reached Tier 3
  // Proactive support requires any active tier of People Reached, if not active Tier 1
  peopleReachedTier(planId: number): number | undefined {
    let tierA = this.defaultPeopleReachedTier(planId);
    let tierB = this.hasBillablePeopleReachedTierId();
    if (tierA && tierB) {
      return Math.max(Number(tierA), Number(tierB));
    } else {
      return tierA ?? tierB;
    }
  }

  centsToDollars(priceInCents: number) {
    return priceInCents / 100;
  }

  // Next PR use this everywhere in intershop and remove formatting from translations
  formatPriceFromCents(priceInCents: number, maximumFractionDigits = 0) {
    return this.formatPrice(this.centsToDollars(priceInCents), maximumFractionDigits);
  }

  formatPrice(price: number, maximumFractionDigits = 0) {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits,
    });
  }

  getContract() {
    return this.store.peekRecord('billing/contract', this.appService.app.id);
  }

  @action setDiscountDollarAmount(metricDiscountOffered: Partial<Record<Metric, number>>) {
    this.dollarAmountDiscount =
      Object.values(metricDiscountOffered).reduce((acc, val) => acc + val, 0) * -1;
  }

  setPlan(plan: Plan) {
    this.plan = plan;
  }

  @task({ keepLatest: true })
  *requestPricesWithUsage(params: PricesParams, setInitalPrice: boolean): TaskGenerator<void> {
    try {
      let response = yield get('/ember/prices', {
        ...params,
        include_tiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
      });
      if (setInitalPrice) {
        this.initialTotalPrice = !this.plan?.product.billableCustomerPlan
          ? 0
          : this.centsToDollars(response.prices[0].pre_discount_amount);
      }
      this.selectedUsageTotalPrice = this.centsToDollars(response.prices[0].pre_discount_amount);
      this.newPriceBreakdown = response.prices[0].breakdown.filter(
        (breakdown: any) => breakdown.charges,
      );
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('intershop.ticket_creation.request_error'));
    }
  }

  @action
  showIntercom() {
    // one custom bot for all of intershop
    // https://app.intercom.com/a/apps/tx2p130c/operator/custom-bots/inbound-custom-bot/27751690
    showNewMessageInIntercomWidget();
  }

  get customer() {
    return this.customerService.customer;
  }

  get canAccessPricing(): boolean {
    return (
      this.customerService.isSalesSoldCustomer &&
      this.plan?.idAsNumber !== FIN_AI_COPILOT_BASE_ID_AS_NUMBER
    );
  }

  isDiscountAvailable(metric: Metric): boolean {
    return !DISCOUNT_UNAVAILABLE.includes(metric);
  }

  pricingMetricLimit(metric: Metric): number {
    return PRICING_METRIC_LIMIT[metric] ?? DEFAULT_PRICING_METRIC_LIMIT;
  }

  canSelfServeUpgradeToPlan(plan: Plan): boolean {
    return (
      !this.appService.app.isSalesforceContracted &&
      plan.selfServe &&
      !plan.billableCustomerPlan && // if plan is in customer's subscription
      !(plan.activeTrial && plan.activeTrialIsGraduating) // if plan will be in customer's subscription after graduating trial is over
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    intershopService: IntershopService;
    'intershop-service': IntershopService;
  }
}
