/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import Service from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import { tracked } from '@glimmer/tracking';
import type Settings from 'embercom/models/ai-agent/tone-of-voice-settings';
import type Store from '@ember-data/store';
import type { GuidelineCategory } from 'embercom/models/ai-agent/guidelines';
import { guidFor } from '@ember/object/internals';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';

export interface GuidelineExample {
  title: string;
  prompt: string;
}

enum Stat {
  USED = 'used',
  RESOLVED = 'resolved',
  ROUTED = 'routed',
}

export type GuidelineCategoryForExamples = Exclude<GuidelineCategory, 'other'>;
type MappedGuidelineCategory = 'communication' | 'clarification' | 'routing';

export type PreviewSettingsMessageType =
  | 'preview-settings-updated'
  | 'preview-guidelines-updated'
  | 'preview-guidelines-saved';

export const PREVIEW_REFRESH_INTERVAL = 3000;

export default class AiAgentGuidelineService extends Service {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare attributeService: $TSFixMe;

  @tracked typingTimeout: ReturnType<typeof setTimeout> | null = null;
  @tracked openGuidelineId: string | null = null;
  @tracked chartDefinitionsHash: Record<string, Record<Stat, any>> = {};

  guidelines: Guideline[];
  composerRefreshHooks: (() => void)[] = [];
  personalityCustomizationSettings: Settings;

  constructor() {
    super(...arguments);
    this.guidelines = this.store.peekAll('ai-agent/guidelines') as any;
    this.personalityCustomizationSettings = this.store.peekRecord(
      'ai-agent/tone-of-voice-settings',
      this.appService.app.id,
    );
  }

  attributeInfoResolver(): AttributeInfoResolver {
    return new AttributeInfoResolver({
      attributes: this.appService.app.allowedAttributes,
      conversationAttributes:
        this.attributeService.nonArchivedConversationCustomAttributesWithoutFile,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: false,
      includeCustomObjectAttributes: true, // needed to show conversation attributes
    });
  }

  serializedBlocksFromPrompt(prompt: string) {
    let blocksDoc = new BlocksDocument(
      prompt.split('\n').map((line) => ({ type: 'paragraph', text: line, class: 'no-margin' })),
    );

    return blocksDoc.blocks;
  }

  getEmptyStateGuidelinesForCategory(guidelineCategory: GuidelineCategoryForExamples) {
    return this.guidelineExamples(guidelineCategory)
      .sort(() => Math.random() - 0.5)
      .slice(0, 3);
  }

  guidelineExamples(guidelineCategory: GuidelineCategory): GuidelineExample[] {
    if (guidelineCategory === 'other') {
      return [];
    }

    let examplesCategoryMapping: Record<GuidelineCategoryForExamples, MappedGuidelineCategory> = {
      tone: 'communication',
      clarification: 'clarification',
      routing: 'routing',
    };

    let category: MappedGuidelineCategory = examplesCategoryMapping[guidelineCategory];

    return category ? this.getExamplesForCategory(category) : [];
  }

  updateChartDefinitions(newGuideline: Guideline) {
    this.chartDefinitionsHash[newGuideline.id] = {
      [Stat.USED]: newGuideline.chartDefinitionForStat(Stat.USED),
      [Stat.RESOLVED]: newGuideline.chartDefinitionForStat(Stat.RESOLVED),
      [Stat.ROUTED]: newGuideline.chartDefinitionForStat(Stat.ROUTED),
    };
  }

  getExamplesForCategory(category: MappedGuidelineCategory): GuidelineExample[] {
    let numberOfExamples;
    if (category === 'clarification') {
      numberOfExamples = 11;
    } else {
      numberOfExamples = 10;
    }

    return Array.from({ length: numberOfExamples }, (_, i) => {
      let exampleNumber = i + 1;
      return {
        title: this.intl.t(`ai-agent.guidance.examples.${category}.example-${exampleNumber}.title`),
        prompt: this.intl.t(
          `ai-agent.guidance.examples.${category}.example-${exampleNumber}.prompt`,
        ),
      };
    });
  }

  callComposerRefreshHooks() {
    this.composerRefreshHooks.forEach((hook) => hook());
  }

  handleAddFromExampleCategoryEmptyState(
    category: GuidelineCategory,
    example: GuidelineExample,
  ): Guideline {
    let newGuideline = this.createNewGuideline(category, example);

    this.updatePreview();

    return newGuideline;
  }

  handleAddFromExampleGuidelineEmptyState(guideline: Guideline, example: GuidelineExample) {
    guideline.title = example.title;
    guideline.prompt = example.prompt;
    guideline.createdFromTemplate = true;

    this.updatePreview();
  }

  createNewGuideline(category: GuidelineCategory, example: GuidelineExample | null): Guideline {
    let newGuideline = this.store.createRecord('ai-agent/guidelines', {
      id: `temp-${guidFor({})}`,
      category,
      title: example?.title
        ? example.title
        : this.intl.t(`ai-agent.guidance.guidelines.categories.${category}.guideline-title`),
      prompt: example?.prompt ? example.prompt : '',
    });

    if (example) {
      newGuideline.createdFromTemplate = true;
    }

    return newGuideline;
  }

  updatePreview() {
    this.handleUserIsTyping();
    this.callComposerRefreshHooks();
  }

  registerRefreshHook(hook: () => void) {
    this.composerRefreshHooks.push(hook);
  }

  unregisterRefreshHook(hook: () => void) {
    this.composerRefreshHooks.removeObject(hook);
  }

  sendPreviewSettingsUpdatedMessage() {
    this.sendMessageToMessengerIFrame('preview-settings-updated');
  }

  handleUserIsTyping() {
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    this.typingTimeout = setTimeout(() => {
      this.sendPreviewSettingsUpdatedMessage();
      this.typingTimeout = null;
    }, PREVIEW_REFRESH_INTERVAL);
  }

  private sendMessageToMessengerIFrame(messageType: PreviewSettingsMessageType) {
    // temporary workaround for tests - skip if app is already torn down
    if (
      !this.appService.app ||
      this.appService.app.isDestroyed ||
      this.appService.app.isDestroying
    ) {
      return;
    }

    let message: {
      type: PreviewSettingsMessageType;
      previewGuidelines?: { text: string; category: string }[];
      previewPersonalitySettings?: Settings;
    } = {
      type: messageType,
      previewGuidelines: this.guidelines
        .filter((guideline: Guideline) => !guideline.isDeleted)
        .map((guideline: Guideline) => {
          return {
            text: guideline.prompt,
            category: guideline.category,
            title: guideline.title,
          };
        }),
      previewPersonalitySettings: this.personalityCustomizationSettings,
    };

    let iframeId = this.getIframeId();
    this.postMessageToIframe(iframeId, JSON.stringify(message));
  }

  getIframeId(): string {
    return this.appService.app.canUseFinGuidanceReusablePreview
      ? '#hosted-messenger-unified-preview'
      : '#hosted-messenger-fin-demo-preview';
  }

  postMessageToIframe(iframeId: string, message: string): void {
    let iframe = window.document.querySelector(iframeId) as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(message);
    }
  }

  setOpenGuidelineId(guidelineId: string | null): void {
    if (this.openGuidelineId === guidelineId) {
      this.openGuidelineId = null;
    } else {
      this.openGuidelineId = guidelineId;
    }
  }
}
