/* RESPONSIBLE TEAM: team-purchase */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import {
  PRODUCT_TOURS_ESSENTIAL_ID,
  SURVEYS_BASE_ID,
  WHATSAPP_BASE_ID,
  SWITCH_BASE_ID,
  ANSWER_BOT_ESSENTIAL_ID,
  SUPPORT_AUTOMATION_BASE_ID,
  CORE_GROW_ID,
  CORE_ACCELERATE_ID,
  CORE_SCALE_ID,
  CORE_SUPPORT_PRO_ID,
  CORE_SUPPORT_PREMIUM_ID,
  PROACTIVE_SUPPORT_BASE_ID,
  PEOPLE_REACHED_TIER_1_ID,
  CUSTOMER_INITIATED_TRIALABLE_CORE_PLAN_IDS,
} from 'embercom/lib/billing';
import { tracked } from '@glimmer/tracking';

export default class SelfServeTrialsService extends Service {
  @service appService;
  @service customerService;
  @service permissionsService;
  @tracked whatsappPlanCombinationValid;
  @tracked switchPlanCombinationValid;
  @tracked resolutionBotPlanCombinationValid;
  @tracked supportAutomationPlanCombinationValid;

  get app() {
    return this.appService.app;
  }

  get activePlanIds() {
    return this.customerService.activePlanIds;
  }

  get billableCustomerPlanIds() {
    return this.customerService.billableCustomerPlanIds;
  }

  get activePlanTrialIds() {
    return this.customerService.activePlanTrialIds;
  }

  get isTriallingCorePlan() {
    return (
      (this.customerService.onSelfServeVBP1Plan && this.isTriallingVBP1()) ||
      (this.customerService.onSelfServeVBP2Plan && this.isTriallingVBP2())
    );
  }

  get activeNonGraduatingTrialPlanIds() {
    let activeTrials = this.customerService.activePlanTrials || [];
    let activeNonGraduatingTrials = activeTrials.filter(
      (trial) => !trial.get('activeTrialIsGraduating'),
    );
    return activeNonGraduatingTrials.mapBy('idAsNumber');
  }

  canTrialSpecificProduct(planId) {
    if (!this.app.isSalesforceContracted) {
      return false;
    }
    switch (planId) {
      case PRODUCT_TOURS_ESSENTIAL_ID:
        return true;
      case SURVEYS_BASE_ID:
        return true;
      case WHATSAPP_BASE_ID:
        this.validatePlanCombination.perform(planId);
        return (
          this.permissionsService.currentAdminCan('can_manage_apps_and_integrations') &&
          this.whatsappPlanCombinationValid
        );
      case SWITCH_BASE_ID:
        this.validatePlanCombination.perform(planId);
        return this.switchPlanCombinationValid;
      case ANSWER_BOT_ESSENTIAL_ID:
        this.validatePlanCombination.perform(planId);
        return this.resolutionBotPlanCombinationValid;
      case SUPPORT_AUTOMATION_BASE_ID:
        this.validatePlanCombination.perform(planId);
        return this.supportAutomationPlanCombinationValid;
      default:
        return false;
    }
  }

  getPlanIdParamsForStartContractBasedTrial(planId) {
    if (this.customerService.onSelfServeVBP2Plan && planId === CORE_SUPPORT_PRO_ID) {
      return [CORE_SUPPORT_PRO_ID, PROACTIVE_SUPPORT_BASE_ID, PEOPLE_REACHED_TIER_1_ID];
    } else {
      return [planId];
    }
  }

  canViewComparisonPaywalls(planId) {
    if (this.app.isSalesforceContracted) {
      return false;
    }
    return CUSTOMER_INITIATED_TRIALABLE_CORE_PLAN_IDS.includes(planId) || planId === CORE_GROW_ID;
  }

  canTrialCorePlan(planId) {
    if (this.app.isSalesforceContracted) {
      return false;
    }
    return this.isOnSelfServePlan() && CUSTOMER_INITIATED_TRIALABLE_CORE_PLAN_IDS.includes(planId);
  }

  isOnSelfServePlan() {
    return this.customerService.onSelfServeVBP1Plan || this.customerService.onSelfServeVBP2Plan;
  }

  isTriallingVBP1() {
    return (
      this.activePlanTrialIds.includes(Number(CORE_ACCELERATE_ID)) ||
      this.activePlanTrialIds.includes(Number(CORE_SCALE_ID))
    );
  }

  isTriallingVBP2() {
    return (
      this.activePlanTrialIds.includes(Number(CORE_SUPPORT_PRO_ID)) ||
      this.activePlanTrialIds.includes(Number(CORE_SUPPORT_PREMIUM_ID)) ||
      this.activePlanTrialIds.includes(Number(PROACTIVE_SUPPORT_BASE_ID))
    );
  }

  @task *validatePlanCombination(planId) {
    let planIds = [...this.activePlanIds, Number(planId)].uniq();
    let priceLoaded = this.customerService.isPriceLoaded(
      planIds,
      this.customerService?.currentPricingModelIdentifier,
    );
    if (!priceLoaded) {
      yield this.customerService.bulkLoadPrices([
        {
          planIds,
          source: 'self-serve-trials-service',
          includePlanCombinationValidation: true,
        },
      ]);
    }
    let planCombinationValid =
      this.customerService.getPriceFromPlanIds(planIds).plan_combination_valid;
    switch (planId) {
      case WHATSAPP_BASE_ID:
        this.whatsappPlanCombinationValid = planCombinationValid;
        break;
      case SWITCH_BASE_ID:
        this.switchPlanCombinationValid = planCombinationValid;
        break;
      case ANSWER_BOT_ESSENTIAL_ID:
        this.resolutionBotPlanCombinationValid = planCombinationValid;
        break;
      case SUPPORT_AUTOMATION_BASE_ID:
        this.supportAutomationPlanCombinationValid = planCombinationValid;
        break;
      default:
        return false;
    }
  }
}
