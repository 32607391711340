/* RESPONSIBLE TEAM: team-app-security */

import type IntlService from 'ember-intl/services/intl';

type AllowedStampColor =
  | 'transparent'
  | 'green'
  | 'sky'
  | 'blue'
  | 'yellow'
  | 'red'
  | 'gray'
  | 'yellow-light'
  | undefined;

export interface StampFormat {
  value: string;
  color: AllowedStampColor;
}

export function getJwtErrorStampsMap(intl: IntlService): Record<string, StampFormat> {
  return {
    'App::IdentityVerificationMissingUserHashException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.idv-missing-user-hash-error',
      ),
      color: 'gray',
    },
    'App::IdentityVerificationInvalidUserHashException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.idv-invalid-user-hash-error',
      ),
      color: 'gray',
    },
    'Incrypt::IdentityVerification::InvalidUserHashError': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.idv-invalid-user-hash-error',
      ),
      color: 'gray',
    },
    'Incrypt::IdentityVerification::NoMatchingUserHashError': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.idv-no-matching-user-hash-error',
      ),
      color: 'gray',
    },
    'IntercomUserJwt::BaseException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.base-jwt-error',
      ),
      color: 'yellow',
    },
    'IntercomUserJwt::FeatureUnavailableException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.feature-unavailable-jwt-error',
      ),
      color: 'red',
    },
    'IntercomUserJwt::MissingUserIdException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.missing-user-id-jwt-error',
      ),
      color: 'yellow',
    },
    'IntercomUserJwt::InvalidException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.invalid-user-jwt-error',
      ),
      color: 'red',
    },
    'IntercomUserJwt::InvalidPayloadException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.invalid-payload-jwt-error',
      ),
      color: 'red',
    },
    'IntercomUserJwt::PrematureException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.premature-jwt-error',
      ),
      color: 'gray',
    },
    'IntercomUserJwt::UserIdMismatchException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.user-id-mismatch-jwt-error',
      ),
      color: 'yellow-light',
    },
    'IntercomUserJwt::JwtAndUserHashException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.jwt-and-user-hash-exception',
      ),
      color: 'sky',
    },
    'IntercomUserJwt::JwtAndHmacException': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.jwt-and-hmac-exception',
      ),
      color: 'yellow',
    },
    'JWT::ExpiredSignature': {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.expired-signature-error',
      ),
      color: 'yellow',
    },
  };
}

export function getStampForErrorType(
  errorType: string,
  stampsMap: Record<string, StampFormat>,
  intl: IntlService,
): StampFormat {
  return (
    stampsMap[errorType] || {
      value: intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.unknown-error',
      ),
      color: 'gray',
    }
  );
}
