/* RESPONSIBLE TEAM: team-messenger */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RouteRegexes from 'embercom/lib/route-regexes';
import type IntlService from 'ember-intl/services/intl';
import type Router from '@ember/routing/router-service';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';

export type Tab =
  | 'content'
  | 'general'
  | 'messages'
  | 'styling'
  | 'home'
  | 'fin'
  | 'teammates'
  | 'web'
  | 'mobile'
  | 'greeting';

type SectionId = string; // Type these with specific section ids
type Platform = 'web' | 'ios' | 'android';
type Screen = 'home' | 'messages' | 'conversation' | 'news' | 'help' | 'tasks' | 'tickets';

export const DEFAULT_SECTIONS_FOR_TABS: { [key in Tab]: SectionId } = {
  content: '',
  styling: 'general',
  general: 'inbound-volume',
  home: 'add-apps',
  messages: 'start-conversation-button',
  fin: 'set-expectation',
  teammates: 'start-conversation-button',
  web: 'brand',
  mobile: 'brand',
  greeting: 'set-expectation',
};

const CONVERSATION_SECTIONS: string[] = [
  'special-notice',
  'team-introduction',
  'start-conversation-button',
  'set-expectation',
  'reply-expectations',
  'privacy-policy-notice',
];

class SettingsNavigation {
  @tracked selectedTab: Tab = 'content';
  @tracked openSectionId: SectionId | null = null;
  @tracked previouslyOpenedSectionForTab: { [key in Tab]?: string } = {};

  previouslyOpenedSection(tab: Tab) {
    return this.previouslyOpenedSectionForTab[tab];
  }

  @action
  navigate(tab: Tab = 'content', sectionId: SectionId) {
    this.selectedTab = tab;
    this.openSection(sectionId);
  }

  @action
  openSection(sectionId: SectionId) {
    this.openSectionId = sectionId;
    this.previouslyOpenedSectionForTab[this.selectedTab] = sectionId;
  }

  @action
  refresh() {
    this.selectedTab = 'content';
    this.openSectionId = null;
    this.previouslyOpenedSectionForTab = {};
  }
}

class Preview {
  defaultPlatform: Platform = 'web';
  platforms: { value: Platform; icon: string }[] = [
    { value: 'web', icon: 'computer' },
    { value: 'ios', icon: 'apple' },
    { value: 'android', icon: 'android' },
  ];
  @tracked zoomInLauncherWeb = false;
  @tracked zoomInLauncherAndroid = false;
  @tracked zoomInLauncherIOS = false;
  @tracked selectedPlatform: Platform = 'web';
  @tracked selectedScreen: Screen | null = null;

  @action
  onWebPaddingFocus() {
    this.zoomInLauncherWeb = true;
  }

  @action
  onAndroidPaddingFocus() {
    this.zoomInLauncherAndroid = true;
  }

  @action
  onIOSPaddingFocus() {
    this.zoomInLauncherIOS = true;
  }

  @action
  onPaddingBlur() {
    this.zoomInLauncherWeb = false;
    this.zoomInLauncherAndroid = false;
    this.zoomInLauncherIOS = false;
  }

  @action
  onPlatformChange(platform: Platform) {
    this.selectedPlatform = platform;
  }

  @action
  onScreenChange(screen: Screen | null) {
    this.selectedScreen = screen;
  }
}

export default class MessengerSettingsService extends Service {
  @service declare router: Router;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare intl: IntlService;
  navigation = new SettingsNavigation();
  preview = new Preview();

  @tracked settings: $TSFixMe = null; // instance of app/models/messenger-settings/all.js

  get isMobilePreview() {
    return (
      this.messengerSettingsTargetUserProvider.isMobileOrWebUserType &&
      (this.preview.selectedPlatform === 'android' || this.preview.selectedPlatform === 'ios')
    );
  }

  get openSectionId() {
    return this.navigation.openSectionId;
  }

  get selectedTab() {
    return this.navigation.selectedTab;
  }

  get selectedScreen() {
    return this.preview.selectedScreen;
  }

  get showMessagesScreen() {
    if (this.selectedScreen === null) {
      return this.selectedTab === 'messages';
    }

    return this.selectedScreen === 'messages';
  }

  get showConversationScreen() {
    return this.selectedScreen === 'conversation';
  }

  get showNewsScreen() {
    return this.selectedScreen === 'news';
  }

  get showHelpScreen() {
    return this.selectedScreen === 'help';
  }

  get showTasksScreen() {
    return this.selectedScreen === 'tasks';
  }

  get showTicketsScreen() {
    return this.selectedScreen === 'tickets';
  }

  get upfrontEmailCollectionSettingOpened() {
    return (
      this.selectedTab === 'messages' &&
      'require-email' === this.openSectionId &&
      this.messengerSettingsTargetUserProvider.isVisitor
    );
  }

  get launcherSettingOpened() {
    return (
      (this.selectedTab === 'styling' && 'launcher' === this.openSectionId) ||
      (this.selectedTab === 'content' && 'show-launcher' === this.openSectionId)
    );
  }

  get launcherSettingOpenedForConversationalMobile() {
    return this.selectedTab === 'mobile' && 'launcher' === this.openSectionId;
  }

  get platformHeaderTitle() {
    let headerTitle = this.intl.t('messenger.left-side-nav.web');

    if (this.router.currentRouteName.match(RouteRegexes.messenger.web)) {
      return headerTitle;
    } else if (this.router.currentRouteName.match(RouteRegexes.messenger.mobileSdk)) {
      return this.intl.t('messenger.left-side-nav.mobile-sdk');
    } else if (this.router.currentRouteName.match(RouteRegexes.messenger.settings)) {
      return this.intl.t('messenger.left-side-nav.settings');
    } else if (this.router.currentRouteName.match(RouteRegexes.messenger.conversations)) {
      return this.intl.t('messenger.left-side-nav.conversations');
    }

    return headerTitle;
  }

  get defaultMessengerLocale() {
    return this.settings?.languages.defaultLocale;
  }

  @action
  navigate(tab: Tab, section: SectionId, options: { changeUrl: boolean } = { changeUrl: true }) {
    if (!section && options?.changeUrl) {
      let previousSection = this.navigation.previouslyOpenedSection(tab);
      section = previousSection || DEFAULT_SECTIONS_FOR_TABS[tab];
    }
    this.navigation.navigate(tab, section);
    this._changeRouterUrl({ tab, section }, options);
  }

  @action
  openSection(section: SectionId, options: { changeUrl: boolean }) {
    this.navigation.openSection(section);
    let tab = this.selectedTab;
    if (tab === 'messages' && section === 'require-email') {
      this.messengerSettingsTargetUserProvider.setVisitorTarget();
    }
    this._changeRouterUrl({ tab, section }, options);
    this.trackAnalyticsEvent({
      action: 'clicked',
      object: 'messenger_settings_section',
      place: `${tab}_tab`,
      selected_platform: this.preview.selectedPlatform,
    });
  }

  @action
  changePlatform(platform: Platform) {
    this.preview.onPlatformChange(platform);
    this.resetPreviewScreen();
  }

  @action
  onPreviewScreenChange(screen: Screen) {
    this.preview.onScreenChange(screen);
  }

  @action
  refresh() {
    this.navigation.refresh();
  }

  @action
  resetPreviewScreen(
    options: { fullReset?: boolean; enforceScreen?: boolean } = { fullReset: false },
  ) {
    let initialScreen: Screen | null = null;
    let isConversationSection =
      this.openSectionId && CONVERSATION_SECTIONS.includes(this.openSectionId);

    initialScreen = isConversationSection ? 'conversation' : null;

    if (['teammates', 'fin'].includes(this.selectedTab)) {
      initialScreen = isConversationSection ? 'conversation' : 'messages';

      if (this.settings && this.openSectionId === 'start-conversation-button') {
        let hasNewConversationCardSlot = this.settings.home.slotGroup?.hasNewConversationCardSlot;
        let isHubEnabled = this.settings.spaces.hasHub;

        initialScreen = isHubEnabled && hasNewConversationCardSlot ? 'home' : 'messages';
      }
    }

    if (
      this.openSectionId === 'launch-to-conversation' &&
      this.settings?.visibility.launchToConversationEnabled
    ) {
      initialScreen = 'conversation';
    }

    this.preview.onScreenChange(initialScreen);
    if (options.fullReset) {
      this.preview.onPlatformChange(this.preview.defaultPlatform);
    }
  }

  trackAnalyticsEvent(event: $TSFixMe) {
    this.intercomEventService.trackAnalyticsEvent({
      ...event,
      place: `${this.selectedTab}_tab`,
      accordion_section: this.openSectionId,
      user_type: this.messengerSettingsTargetUserProvider.targetUserType,
    });
  }

  _changeRouterUrl(
    queryParams: { section?: SectionId; tab?: Tab } = {},
    options: { changeUrl?: boolean; enforceScreen?: boolean } = { changeUrl: true },
  ) {
    if (queryParams.section) {
      this.resetPreviewScreen({ enforceScreen: options.enforceScreen });
    }

    if (options?.changeUrl) {
      this.router.transitionTo(this.router.currentRouteName, { queryParams });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'messenger-settings-service': MessengerSettingsService;
  }
}
