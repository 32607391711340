/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import type Store from '@ember-data/store';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import type Workspace from 'embercom/objects/workspace';
import moment from 'moment-timezone';

export default class SubscriptionEndingService extends Service {
  @service declare intl: IntlService;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare store: Store;

  @tracked customerSubscriptionWillBeCancelledAt?: Date;
  @tracked hasSucceedingSubscription?: boolean;
  @tracked isSalesforceContracted?: boolean;
  @tracked inCardlessTrial?: boolean;
  @tracked hasPendingStripeMigration?: boolean;
  @tracked featureEnabled?: boolean;
  @tracked customerId?: string;
  @tracked appId?: string;

  initializeUsingApp(app: any) {
    this.customerSubscriptionWillBeCancelledAt = app.customerSubscriptionWillBeCancelledAt;
    this.hasSucceedingSubscription = app.customerHasSucceedingSubscription;
    this.isSalesforceContracted = app.isSalesforceContracted;
    this.inCardlessTrial = app.in_cardless_trial;
    this.hasPendingStripeMigration = app.has_pending_stripe_migration;
    this.featureEnabled = app.canUseFeature('app-wide-subscription-cancellation-banner');
    this.customerId = app.customerId;
    this.appId = app.id;
  }

  initializeUsingWorkspace(workspace: Workspace) {
    this.customerSubscriptionWillBeCancelledAt = workspace.customerSubscriptionWillBeCancelledAt;
    this.hasSucceedingSubscription = workspace.customerHasSucceedingSubscription;
    this.isSalesforceContracted = workspace.isSalesforceContracted;
    this.inCardlessTrial = workspace.inCardlessTrial;
    this.hasPendingStripeMigration = workspace.hasPendingStripeMigration;
    this.featureEnabled = workspace.isFeatureEnabled('app-wide-subscription-cancellation-banner');
    this.customerId = workspace.customerId;
    this.appId = workspace.id;
  }

  updateCustomerSubscriptionWillBeCancelledAt(date: Date) {
    this.customerSubscriptionWillBeCancelledAt = date;
  }

  get customerSubscriptionCancelledWithin30Days() {
    if (!this.customerSubscriptionWillBeCancelledAt) {
      return false;
    }
    return moment(this.customerSubscriptionWillBeCancelledAt).isSameOrBefore(
      moment().add(30, 'days'),
    );
  }

  get customerSubscriptionCancelledAfter30Days() {
    if (!this.customerSubscriptionWillBeCancelledAt) {
      return false;
    }
    return moment(this.customerSubscriptionWillBeCancelledAt).isAfter(moment().add(30, 'days'));
  }

  get formattedCancellationDate() {
    if (!this.customerSubscriptionWillBeCancelledAt) {
      return null;
    }
    return this.intl.formatDate(this.customerSubscriptionWillBeCancelledAt, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }

  get longFormattedCancellationDate() {
    if (!this.customerSubscriptionWillBeCancelledAt) {
      return null;
    }
    return this.intl.formatDate(this.customerSubscriptionWillBeCancelledAt, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      format: 'dd MMMM, yyyy',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'subscription-ending-service': SubscriptionEndingService;
  }
}
