/* RESPONSIBLE TEAM: team-growth-opportunities */
import type Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { use } from 'ember-resources/util/function-resource';
import { getDomainName } from 'embercom/lib/email';
import type CustomBounceSettings from 'embercom/models/custom-bounce-settings';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import type DkimSettings from 'embercom/models/dkim-settings';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { groupBy, indexBy } from 'underscore';

export interface DomainEmailAddress {
  id: string;
  email: string;
  verified: boolean;
  domainName: string;
  brandName: string;
  brandId: number | null;
  outboundStatus: string;
  forwardingEnabled: boolean;
  outboundOnly?: boolean;
  isDefaultBrandNotificationEmail: boolean;
  senderEmailAddresses: CustomEmailAddress[];
  senderEmailAddressSettings?: SenderEmailAddressSettings;
  hasForwardingVerificationAttemp?: boolean;
}

export interface Domain {
  name: string;
  isAuthenticated: boolean;
  hasAuthenticationAttempt: boolean;
  bounceSettings: CustomBounceSettings;
  dkimSettings: DkimSettings;
  addresses: DomainEmailAddress[];
}

export default class DomainService extends Service {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;

  get app() {
    return this.appService.app;
  }

  get senderEmailAddressSettings(): SenderEmailAddressSettings[] {
    return this.senderEmailAddressSettingsLoader.value || [];
  }

  get verifiedAndAuthenticatedCustomEmailAddresses(): DomainEmailAddress[] {
    return this.domains
      .filter((domain) => domain.isAuthenticated)
      .flatMap((domain) => domain.addresses)
      .filter((address) => address.verified);
  }

  get defaultSenderEmailAddressSettings() {
    let defaultSettings = this.domains
      .flatMap((domain) => domain.addresses)
      .find((address) => address.isDefaultBrandNotificationEmail);
    return defaultSettings?.senderEmailAddressSettings;
  }

  async load() {
    await this.app.loadEmailDomains();
    await this.store.query('sender-email-address-settings', {
      include: 'custom-email-address',
    });
    await this.senderEmailAddressSettingsLoader.promise;
  }

  @use senderEmailAddressSettingsLoader = AsyncData<SenderEmailAddressSettings[]>(async () => {
    return this.store.findAll('sender-email-address-settings');
  });

  get domains(): Domain[] {
    /*
      Combine all email-related records (sender email addresses), grouped by email address
    */

    let senderEmailAddressSettingsByEmail = indexBy(
      this.senderEmailAddressSettings.toArray(),
      'email',
    );
    let allEmails = new Set([...Object.keys(senderEmailAddressSettingsByEmail)]);
    let adminEmails = new Set(this.app.admins.map((admin: any) => admin.email.toLowerCase()));

    let allAddresses = [...allEmails].map((email) => {
      let senderEmailAddressSettings = senderEmailAddressSettingsByEmail[email];
      let senderEmailAddresses = senderEmailAddressSettings?.senderEmailAddresses || [];
      let senderEmailAddress = senderEmailAddresses?.firstObject;

      let brandId = Number(senderEmailAddressSettings?.brandId) || null;
      let brandName = senderEmailAddressSettings?.brandName || this.app.name;
      let forwardingEnabled = senderEmailAddressSettings?.forwardingEnabled || false;
      let outboundOnly = senderEmailAddressSettings?.outboundOnly || false;
      let hasForwardingVerificationAttempt =
        senderEmailAddress?.hasForwardingVerificationAttempt || false;

      // this comes from the dkim settings for the email and will be the same on all addresses
      let outboundStatus;
      if (senderEmailAddressSettings && !senderEmailAddressSettings.verified) {
        outboundStatus = this.intl.t(
          'new-settings.channels.email.connect-email.addresses-table.unverified-email',
        );
      } else {
        outboundStatus = senderEmailAddress?.status || 'disabled';
      }
      return {
        id: senderEmailAddressSettings?.id,
        email,
        verified:
          senderEmailAddressSettings?.verified ||
          senderEmailAddress?.verified ||
          adminEmails.has(email.toLowerCase()),
        domainName: getDomainName(email),
        brandId,
        brandName,
        isDefaultBrandNotificationEmail:
          senderEmailAddressSettings?.isDefaultBrandNotificationEmail || false,
        senderEmailAddresses: senderEmailAddresses.toArray(),
        senderEmailAddressSettings,
        outboundStatus,
        forwardingEnabled,
        outboundOnly,
        hasForwardingVerificationAttempt,
      };
    });

    /*
      Reject temporary email addresses created for Fin Standalone
    */

    if (this.app.canUseStandalone) {
      allAddresses = allAddresses.reject((address) => address.domainName.endsWith('.hirefin.com'));
    }

    /*
      Combine all domain-related records (bounce settings, DKIM settings, and
      email addresses), grouped by domain name
    */

    let bounceSettingsGrouped = groupBy(
      this.app.customBounceSettings?.toArray() || [],
      'rootDomain',
    );
    let dkimSettingsGrouped = groupBy(this.app.dkim_settings?.toArray() || [], 'domain');
    let addressesGrouped = groupBy(allAddresses, 'domainName');
    let allDomainNames = new Set([
      ...Object.keys(bounceSettingsGrouped),
      ...Object.keys(dkimSettingsGrouped),
      ...Object.keys(addressesGrouped),
    ]);

    let allDomains = [...allDomainNames].map((domainName) => {
      // There is only one bounceSettings and one dkimSettings per domain.
      let bounceSettings = bounceSettingsGrouped[domainName]?.[0];
      let dkimSettings = dkimSettingsGrouped[domainName]?.[0];
      let addresses = (addressesGrouped[domainName] || []).sort((a, b) =>
        a.email.localeCompare(b.email),
      );
      return {
        name: domainName,
        isAuthenticated:
          bounceSettings?.validRecordExists &&
          dkimSettings?.validRecordExists &&
          dkimSettings?.validDmarcExists,
        hasAuthenticationAttempt:
          bounceSettings?.hasAuthenticationAttempt || dkimSettings?.hasAuthenticationAttempt,
        bounceSettings,
        dkimSettings,
        addresses,
      };
    });

    allDomains = allDomains.filter(
      (domain) => !domain.dkimSettings?.domainForKnownEmailHost && domain.addresses.length > 0,
    );

    return allDomains.sort((a, b) => a.name.localeCompare(b.name));
  }
}

declare module '@ember/service' {
  interface Registry {
    'domain-service': DomainService;
  }
}
