/* RESPONSIBLE TEAM: team-standalone */
import Service, { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask, restartableTask } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type SalesforceLiveChatConfiguration from 'embercom/models/standalone/salesforce-live-chat-configuration';
import type SalesforceInAppMessagingConfiguration from 'embercom/models/standalone/salesforce-in-app-messaging-configuration';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { get, post } from 'embercom/lib/ajax';
import type ContentImportService from './content-import-service';
import { type TaskGenerator } from 'ember-concurrency';
import type RouterService from '@ember/routing/router-service';
import type IntercomMessengerConfiguration from 'embercom/models/standalone/intercom-messenger-configuration';
import type OfficeHoursSchedule from 'embercom/models/office-hours-schedule';
import type KnowledgeHubService from './knowledge-hub-service';
import {
  RESPONSE_PROVIDED_ERRORS,
  StandalonePlatformToIcon,
} from 'embercom/objects/standalone/constants';
import {
  ZendeskDataFields,
  type ZendeskTicketsSetupData,
} from 'embercom/objects/standalone/data-fields/zendesk';
import {
  type SalesforceAdmin,
  type SalesforceQueue,
  type SalesforceSetupData,
} from 'embercom/objects/standalone/salesforce';
import { SalesforceDataFields } from 'embercom/objects/standalone/data-fields/salesforce';
import {
  type OrganizationField,
  TicketField,
  type TicketFieldResponse,
  UserField,
  type UserFieldResponse,
} from 'embercom/objects/standalone/data-fields/common';
import ZendeskDataLoader from 'embercom/services/fin-standalone-service/zendesk-data-loader';
import { type KnowledgeSourceSummary } from 'embercom/objects/standalone/knowledge';
import { type SunshineChannel, type ZendeskBrand } from 'embercom/objects/standalone/zendesk';
import type ApplicationInstance from '@ember/application/instance';
import type { InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class FinStandaloneService extends Service {
  @service declare store: Store;
  @service declare appService: { app: $TSFixMe };
  @service declare notificationsService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare contentImportService: ContentImportService;
  @service declare router: RouterService;
  @service declare knowledgeHubService: KnowledgeHubService;

  @tracked intercomMessengerConfig!: IntercomMessengerConfiguration;
  @tracked messengerSettings!: $TSFixMe;
  @tracked operatorIdentity?: $TSFixMe;
  @tracked operatorIdentityPreviewImgSrc = '';
  @tracked initialized = false;

  /* Salesforce specific */
  @tracked salesforceConfig?: SalesforceConfiguration;
  @tracked salesforceLiveChatConfig?: SalesforceLiveChatConfiguration;
  @tracked salesforceInAppMessagingConfig?: SalesforceInAppMessagingConfiguration;
  @tracked salesforceSetupData: SalesforceSetupData = {
    admins: [],
    queues: [],
    caseOrigins: [],
  };
  @tracked selectedSalesforceCaseOrigins: Array<string> = [];
  @tracked salesforceDataFields: SalesforceDataFields = new SalesforceDataFields({
    userFields: [],
    caseFields: [],
  });

  /* Zendesk specific */
  zendeskDataLoader: ZendeskDataLoader;
  @tracked zendeskConfig?: ZendeskConfiguration;
  @tracked zendeskBrands: ZendeskBrand[] = [];
  @tracked sunshineChannels: SunshineChannel[] = [];
  @tracked zendeskTicketsSetupData: ZendeskTicketsSetupData = {
    admins: [],
    email_addresses: [],
    live_trigger: { id: undefined },
  };
  @tracked zendeskDataFields: ZendeskDataFields = new ZendeskDataFields({
    userFields: [],
    ticketFields: [],
    organizationFields: [],
  });
  @tracked zendeskSchedules: Array<OfficeHoursSchedule> = [];

  constructor(owner: ApplicationInstance) {
    super(owner);
    this.zendeskDataLoader = new ZendeskDataLoader(this);
  }

  get app() {
    return this.appService.app;
  }

  get workflowsReturnRoute(): string {
    let current = this.router.currentRouteName;

    if (current === 'apps.app.standalone.channels.zendesk.tickets.workflow') {
      return 'apps.app.standalone.deploy.zendesk.tickets.workflow.show';
    }

    if (current === 'apps.app.standalone.channels.zendesk.messaging.workflow') {
      return 'apps.app.standalone.deploy.zendesk.messaging.workflow.show';
    }

    if (current === 'apps.app.standalone.deploy.salesforce.cases.workflow') {
      return 'apps.app.standalone.deploy.salesforce.cases';
    }

    if (current === 'apps.app.standalone.channels.intercom.messenger.workflow') {
      return 'apps.app.standalone.deploy.intercom-messenger.integrate';
    }

    if (current === 'apps.app.standalone.tasks.workflow') {
      return 'apps.app.standalone.tasks';
    }

    console.error('No return route defined for: ', current);

    return 'apps.app.standalone.setup';
  }

  get hasContentForFin() {
    return Object.keys(this.knowledgeSourceSummary).some((key) => {
      return this.knowledgeSourceSummary[Number(key)].used_by_fin_count > 0;
    });
  }

  async initialize() {
    if (!this.app.canUseStandalone) {
      return;
    }

    if (this.initialized) {
      return;
    }

    let promises: Promise<unknown>[] = [
      taskFor(this.loadIntercomMessengerConfig).perform(),
      this.store.findAll('conversation-attributes/descriptor'),
      taskFor(this.peekOrLoadMessengerSettings).perform(),
    ];

    if (this.app.canUseFeature('team-standalone-fin-over-api')) {
      promises.push(this.store.findRecord('standalone/api-configuration', this.app.id));
    }

    if (this.app.hasStandalonePlatform('zendesk')) {
      promises.push(this.zendeskDataLoader.performLoads());
    }

    if (this.app.hasStandalonePlatform('salesforce')) {
      promises.push(taskFor(this.loadSalesforceConfig).perform());
    }

    this.contentImportService.fetchContentImportSources();
    this.knowledgeHubService.fetchKnowledgeUsageSummary();

    try {
      await RSVP.all(promises);
    } catch (error) {
      console.error(error);
    }

    // this call is here to populate the contentLibrarySummary for Fin step in workflows
    taskFor(this.loadAiContentLibrarySummary).perform();
    taskFor(this.loadOperatorIdentity).perform();

    if (this.app.hasStandalonePlatform('salesforce')) {
      taskFor(this.loadSalesforceSetupData).perform();
      taskFor(this.loadSalesforceLiveChatConfig).perform();
      taskFor(this.loadSalesforceDataFields).perform();
      taskFor(this.loadSalesforceInAppMessagingConfig).perform();
    }

    this.initialized = true;
  }

  @dropTask *loadSalesforceConfig() {
    try {
      this.salesforceConfig = yield this.store.findRecord(
        'standalone/salesforce-configuration',
        this.app.id,
      );

      if (!this.salesforceConfig) {
        this.salesforceConfig = this.store.createRecord('standalone/salesforce-configuration', {
          id: this.app.id,
        });
      }
    } catch (e) {
      console.error(e.message || e.jqXHR);
      if (e.jqXHR?.status !== 404) {
        throw e;
      }
    }
  }

  @dropTask *loadSalesforceLiveChatConfig(): TaskGenerator<void> {
    if (!this.app.canUseHandoffToSalesforceChat || this.salesforceConfig?.isPendingSetup) {
      return;
    }

    try {
      this.salesforceLiveChatConfig = yield this.store.findRecord(
        'standalone/salesforce-live-chat-configuration',
        this.app.id,
      );
    } catch (e) {
      console.error(e.message || e.jqXHR);
      if (e.jqXHR?.status !== 404) {
        throw e;
      }
    }
  }

  @dropTask *loadSalesforceInAppMessagingConfig(): TaskGenerator<void> {
    if (
      !this.appService.app.canUseHandoffToSalesforceInAppMessaging ||
      this.salesforceConfig?.isPendingSetup
    ) {
      return;
    }

    try {
      this.salesforceInAppMessagingConfig = yield this.store.findRecord(
        'standalone/salesforce-in-app-messaging-configuration',
        this.appService.app.id,
      );
    } catch (e) {
      console.error(e.message || e.jqXHR);
      if (e.jqXHR?.status !== 404) {
        throw e;
      }
    }
  }

  @dropTask *loadIntercomMessengerConfig(): TaskGenerator<void> {
    try {
      this.intercomMessengerConfig = yield this.store.findRecord(
        'standalone/intercom-messenger-configuration',
        this.app.id,
      );
    } catch (e) {
      console.error(e.message || e.jqXHR);
      if (e.jqXHR?.status !== 404) {
        throw e;
      }
    }
  }

  get isLoadingIntercomMessengerConfig() {
    return taskFor(this.loadIntercomMessengerConfig).isRunning;
  }

  get isLoadingSunshineChannels() {
    return this.zendeskDataLoader?.isLoadingSunshineChannels || false;
  }

  get knowledgeSourceSummary(): KnowledgeSourceSummary {
    let knowledgeHubUsageSummary = this.knowledgeHubService.usageSummary || {};

    return Object.entries(knowledgeHubUsageSummary).reduce((summary, [key, value]) => {
      summary[Number(key)] = {
        entity_type: Number(key),
        total_count: value.all,
        used_by_fin_count: value.agent,
        additional_data: undefined,
      };
      return summary;
    }, {} as KnowledgeSourceSummary);
  }

  @restartableTask
  *loadOperatorIdentity(): TaskGenerator<void> {
    try {
      this.operatorIdentity = yield this.store.findRecord('operator-identity', '1');
      // operator identity can be modified, but not saved, in settings
      // and it looks strange to have a partially edited record when we visit this page
      this.operatorIdentity.rollbackAttributes();
      this.operatorIdentityPreviewImgSrc = this.operatorIdentity.avatar?.image_urls?.square_128;
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('operator.settings.error'));
    }
  }

  get isLoadingOperatorIdentity() {
    return taskFor(this.loadOperatorIdentity).isRunning;
  }

  @action async updateOperatorIdentity() {
    await taskFor(this.saveOperatorIdentity).perform();
  }

  @restartableTask
  *saveOperatorIdentity(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission('can_create_and_edit_bots');
    } catch (e) {
      return;
    }

    try {
      if (this.app.canUseFeature('bot_identity')) {
        yield this.operatorIdentity.save();
      }

      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.settings.saved-successfully'),
      );
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('operator.settings.error'));
    }
  }

  get isSavingOperatorIdentity() {
    return taskFor(this.saveOperatorIdentity).isRunning;
  }

  @action
  setOperatorIdentityPreviewImgSrc(url: string) {
    this.operatorIdentityPreviewImgSrc = url;
  }

  @action async saveZendeskConfig() {
    if (!this.zendeskConfig || !this.zendeskDataLoader) {
      throw new Error('zendeskConfig or zendeskDataLoader are not present.');
    }

    if (this.zendeskConfig) {
      this.zendeskConfig.subdomain = this.zendeskConfig.subdomain?.trim();
      this.zendeskConfig.username = this.zendeskConfig.username?.trim();
      this.zendeskConfig.token = this.zendeskConfig.token?.trim();

      if (this.zendeskConfig.sunshineIntegration) {
        this.zendeskConfig.sunshineIntegration.sunshineAppId =
          this.zendeskConfig.sunshineIntegration.sunshineAppId?.trim();
        this.zendeskConfig.sunshineIntegration.apiKeyId =
          this.zendeskConfig.sunshineIntegration.apiKeyId?.trim();
        this.zendeskConfig.sunshineIntegration.apiSecret =
          this.zendeskConfig.sunshineIntegration.apiSecret?.trim();
      }
    }

    try {
      await this.zendeskConfig.save();
      taskFor(this.zendeskDataLoader.loadZendeskTicketsSetupData).perform();
      taskFor(this.zendeskDataLoader.loadSunshineChannels).perform();
      taskFor(this.zendeskDataLoader.loadZendeskBrands).perform();
      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.setup.api.save-success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: this.intl.t('standalone.setup.api.save-error'),
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
      throw e;
    }
  }

  get isLoadingZendeskTicketsSetupData() {
    return this.zendeskDataLoader?.isLoadingZendeskTicketsSetupData || false;
  }

  get finIsLive() {
    return (
      this.zendeskConfig?.isEnabledForTickets ||
      this.zendeskConfig?.sunshineIntegration?.isEnabledForSunshineMessenger ||
      this.salesforceConfig?.isEnabled ||
      false
    );
  }

  @dropTask *loadSalesforceSetupData(): TaskGenerator<void> {
    if (this.salesforceConfig?.isPendingSetup) {
      this.salesforceSetupData = {
        admins: [],
        queues: [],
        caseOrigins: [],
      };

      return;
    }

    let salesforceAdmins = yield get(
      `/ember/standalone/salesforce_configurations/${this.app.id}/list_salesforce_users`,
      {
        app_id: this.app.id,
      },
    );

    let salesforceQueues = yield get(
      `/ember/standalone/salesforce_configurations/${this.app.id}/list_salesforce_queues`,
      {
        app_id: this.app.id,
      },
    );

    let salesforceCaseOrigins = yield get(
      `/ember/standalone/salesforce_configurations/${this.app.id}/list_salesforce_case_origins`,
      {
        app_id: this.app.id,
      },
    );

    let selectedCaseOrigins = yield get(
      `/ember/standalone/salesforce_configurations/${this.app.id}/list_selected_case_origins`,
      {
        app_id: this.app.id,
      },
    );

    this.selectedSalesforceCaseOrigins = selectedCaseOrigins;

    this.salesforceSetupData = {
      admins: salesforceAdmins as Array<SalesforceAdmin>,
      queues: salesforceQueues as Array<SalesforceQueue>,
      caseOrigins: salesforceCaseOrigins as Array<string>,
    };
  }

  get isLoadingZendeskConfig() {
    return this.zendeskDataLoader?.isLoadingZendeskConfig || false;
  }

  get isLoadingSalesforceConfig() {
    return taskFor(this.loadSalesforceConfig).isRunning;
  }

  get isLoadingSalesforceInAppMessagingConfig() {
    return taskFor(this.loadSalesforceInAppMessagingConfig).isRunning;
  }

  get isLoadingSalesforceLiveChatConfig() {
    return taskFor(this.loadSalesforceLiveChatConfig).isRunning;
  }

  get isLoadingSalesforceSetupData() {
    return taskFor(this.loadSalesforceSetupData).isRunning;
  }

  get isLoadingZendeskSchedules() {
    return this.zendeskDataLoader?.isLoadingZendeskSchedules || false;
  }

  @dropTask *loadSalesforceDataFields(): TaskGenerator<void> {
    yield this.reloadSalesforceDataFields();
  }

  @dropTask *loadAiContentLibrarySummary() {
    yield this.contentImportService.fetchAiContentLibrarySummary();
  }

  get isLoadingAiContentLibrarySummary() {
    return taskFor(this.loadAiContentLibrarySummary).isRunning;
  }

  async reloadSalesforceDataFields() {
    if (this.salesforceConfig?.isPendingSetup) {
      this.salesforceDataFields = new SalesforceDataFields({
        userFields: [],
        caseFields: [],
      });
      return;
    }

    let dataFields = (await get(`/ember/standalone/salesforce_data/available_fields`, {
      app_id: this.app.id,
      admin_id: this.app.currentAdmin.id,
    })) as {
      user_fields: UserFieldResponse[];
      case_fields: TicketFieldResponse[];
    };

    this.salesforceDataFields = new SalesforceDataFields({
      userFields: dataFields.user_fields.map((field) => new UserField(field)),
      caseFields: dataFields.case_fields.map((field) => new TicketField(field)),
    });
  }

  get isLoadingZendeskDataFields() {
    return this.zendeskDataLoader?.isLoadingZendeskDataFields || false;
  }

  get isLoadingSalesforceDataFields() {
    return taskFor(this.loadSalesforceDataFields).isRunning;
  }

  @dropTask *peekOrLoadMessengerSettings(): TaskGenerator<void> {
    this.messengerSettings =
      this.store.peekRecord('messenger-settings/all', this.app.id) ||
      (yield this.store.findRecord('messenger-settings/all', this.app.id));
  }

  async addZendeskUserField(userField: UserField) {
    try {
      // Place field at the end of the list
      this.zendeskDataFields.userFields.removeObject(userField);
      this.zendeskDataFields.userFields.pushObject(userField);

      userField.isSynchronizing = true;
      let intercomCda = await post(
        `/ember/standalone/zendesk_data/${userField.id}/synchronize_user_field`,
        {
          app_id: this.app.id,
          admin_id: this.app.currentAdmin.id,
        },
      );
      userField.intercom_cda = intercomCda;
      this.app.reload();
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: 'Something went wrong and we couldn’t synchronize that field.',
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    } finally {
      userField.isSynchronizing = false;
    }
  }

  async addZendeskTicketField(ticketField: TicketField) {
    try {
      // Place the field at the end of the list
      this.zendeskDataFields.ticketFields.removeObject(ticketField);
      this.zendeskDataFields.ticketFields.pushObject(ticketField);

      ticketField.isSynchronizing = true;
      let intercomCvda = await post(
        `/ember/standalone/zendesk_data/${ticketField.id}/synchronize_ticket_field`,
        {
          app_id: this.app.id,
          admin_id: this.app.currentAdmin.id,
        },
      );
      ticketField.intercom_cvda = intercomCvda;
      this.app.reload();
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: 'Something went wrong and we couldn’t synchronize that field.',
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    } finally {
      ticketField.isSynchronizing = false;
    }
  }

  async addSalesforceUserField(userField: UserField) {
    try {
      // Place field at the end of the list
      this.salesforceDataFields.userFields.removeObject(userField);
      this.salesforceDataFields.userFields.pushObject(userField);

      userField.isSynchronizing = true;
      let intercomCda = await post(
        `/ember/standalone/salesforce_data/${userField.id}/synchronize_user_field`,
        {
          app_id: this.app.id,
          admin_id: this.app.currentAdmin.id,
        },
      );
      userField.intercom_cda = intercomCda;
      this.app.reload();
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: 'Something went wrong and we couldn’t synchronize that field.',
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    } finally {
      userField.isSynchronizing = false;
    }
  }

  async addSalesforceCaseField(caseField: TicketField) {
    try {
      // Place the field at the end of the list
      this.salesforceDataFields.caseFields.removeObject(caseField);
      this.salesforceDataFields.caseFields.pushObject(caseField);

      caseField.isSynchronizing = true;
      let intercomCvda = await post(
        `/ember/standalone/salesforce_data/${caseField.id}/synchronize_case_field`,
        {
          app_id: this.app.id,
          admin_id: this.app.currentAdmin.id,
        },
      );
      caseField.intercom_cvda = intercomCvda;
      this.app.reload();
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: 'Something went wrong and we couldn’t synchronize that field.',
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    } finally {
      caseField.isSynchronizing = false;
    }
  }

  async addSalesforceCaseOrigin(origin: string) {
    try {
      await post(
        `/ember/standalone/salesforce_configurations/${this.app.id}/create_salesforce_case_origin`,
        {
          app_id: this.app.id,
          origin,
        },
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: 'Something went wrong and we couldn’t add that Case Origin.',
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    }
  }

  async removeSalesforceCaseOrigin(origin: string) {
    try {
      await post(
        `/ember/standalone/salesforce_configurations/${this.app.id}/delete_salesforce_case_origin`,
        {
          app_id: this.app.id,
          origin,
        },
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: 'Something went wrong and we couldn’t remove that Case Origin.',
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    }
  }

  get standaloneConversationField(): (id: string | undefined) => TicketField | undefined {
    return (id: string) => {
      return (
        this.zendeskDataFields.ticketFields.find((field) => field.intercom_cvda?.id === id) ||
        this.salesforceDataFields.caseFields.find((field) => field.intercom_cvda?.id === id)
      );
    };
  }

  async addZendeskOrganizationField(organizationField: OrganizationField) {
    try {
      // Place field at the end of the list
      this.zendeskDataFields.organizationFields.removeObject(organizationField);
      this.zendeskDataFields.organizationFields.pushObject(organizationField);

      organizationField.isSynchronizing = true;
      let intercomCda = await post(
        `/ember/standalone/zendesk_data/${organizationField.id}/synchronize_organization_field`,
        {
          app_id: this.app.id,
          admin_id: this.app.currentAdmin.id,
        },
      );
      organizationField.intercom_cda = intercomCda;
      this.app.reload();
    } catch (e) {
      this.notificationsService.notifyResponseError(
        e,
        {
          default: 'Something went wrong and we couldn’t synchronize that field.',
        },
        {
          responseProvidedErrors: RESPONSE_PROVIDED_ERRORS,
        },
      );
      console.error(e.message || e.jqXHR);
    } finally {
      organizationField.isSynchronizing = false;
    }
  }

  isMappedUserAttribute(attributeIdentifier: string) {
    let userFields = [] as UserField[];

    if (this.app.hasStandalonePlatform('zendesk')) {
      userFields = this.zendeskDataFields.userFields;
    } else if (this.app.hasStandalonePlatform('salesforce')) {
      userFields = this.salesforceDataFields.userFields;
    }

    return userFields.any(
      (userField) =>
        userField.intercom_cda?.identifier === attributeIdentifier ||
        userField.intercom_sda?.identifier === attributeIdentifier,
    );
  }

  isMappedCompanyAttribute(attributeIdentifier: string) {
    let organizationFields = [] as OrganizationField[];

    if (this.app.hasStandalonePlatform('zendesk')) {
      organizationFields = this.zendeskDataFields.organizationFields;
    }

    return organizationFields.any(
      (organizationField) =>
        `company.${organizationField.intercom_cda?.identifier}` === attributeIdentifier ||
        `company.${organizationField.intercom_sda?.identifier}` === attributeIdentifier,
    );
  }

  isMappedConversationAttribute(attributeIdentifier: string) {
    let ticketFields = [] as TicketField[];

    if (this.app.hasStandalonePlatform('zendesk')) {
      ticketFields = this.zendeskDataFields.ticketFields;
    } else if (this.app.hasStandalonePlatform('salesforce')) {
      ticketFields = this.salesforceDataFields.caseFields;
    }

    return ticketFields.any(
      (ticketField) => `conversation.${ticketField.intercom_cvda?.id}` === attributeIdentifier,
    );
  }

  platformIcon(): InterfaceIconName | undefined {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return StandalonePlatformToIcon['zendesk'];
    } else if (this.appService.app.hasStandalonePlatform('salesforce')) {
      return StandalonePlatformToIcon['salesforce'];
    }

    return undefined;
  }
}
