/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  type InboxIdentifier,
  type InboxIdentifierWithCount,
  isSameInbox,
} from 'embercom/objects/inbox/inboxes/inbox';
import type InboxState from 'embercom/services/inbox-state';
import FaviconCounter from 'embercom/lib/favicon-counter';
import type ApplicationInstance from '@ember/application/instance';
import type Nexus from 'embercom/services/nexus';
import { type InboxContentsChangedEvent, NexusEventName } from 'embercom/services/nexus';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator, type TaskInstance, timeout } from 'ember-concurrency';
import type Session from 'embercom/services/session';
import ENV from 'embercom/config/environment';
import type TracingService from 'embercom/services/tracing';
import type InboxApi from 'embercom/services/inbox-api';
import { captureException } from 'embercom/lib/sentry';

export default class InboxCounters extends Service {
  @service declare inboxState: InboxState;
  @service declare nexus: Nexus;
  @service declare session: Session;
  @service declare tracing: TracingService;
  @service declare inboxApi: InboxApi;

  @tracked counts: { [key: string]: number } = {};
  private handler?: (e: InboxContentsChangedEvent) => void;

  private visibleCounters: { [key: string]: { identifier: InboxIdentifier; count: number } } = {};
  private pendingInboxCounterUpdates: { [key: string]: InboxIdentifier } = {};
  private ttlTasks: { [key: string]: TaskInstance<void> } = {};
  private visibilityListener?: () => void;

  faviconCounter = new FaviconCounter();

  constructor(owner: ApplicationInstance) {
    super(owner);

    if (
      this.usingPullBasedCounters ||
      this.session.workspace.isFeatureEnabled('inbox2-pull-counters-metrics')
    ) {
      this.visibilityListener = () => {
        if (document.visibilityState === 'visible') {
          taskFor(this.fetchCountsForInboxesDebounced).perform();
        }
      };
      addEventListener('visibilitychange', this.visibilityListener);

      this.handler = this.handleInboxContentsChangedEvent.bind(this);
      if (this.handler) {
        this.nexus.addListener(NexusEventName.InboxContentsChanged, this.handler);
      }
    }
  }

  get usingPullBasedCounters() {
    return this.session.workspace.isFeatureEnabled('inbox2-pull-counters');
  }

  willDestroy() {
    super.willDestroy();
    if (this.handler) {
      this.nexus.removeListener(NexusEventName.InboxContentsChanged, this.handler);
    }
    if (this.visibilityListener) {
      removeEventListener('visibilitychange', this.visibilityListener);
    }
  }

  trackInbox(inbox: InboxIdentifier) {
    if (
      !this.usingPullBasedCounters &&
      !this.session.workspace.isFeatureEnabled('inbox2-pull-counters-metrics')
    ) {
      return;
    }

    let identifier: InboxIdentifier = { type: inbox.type, id: inbox.id };
    let key = this.inboxKey(identifier);
    let data = this.visibleCounters[key];
    if (data) {
      data.count += 1;
    } else {
      this.nexus.subscribeTopics([this.inboxNexusTopic(inbox)]);
      data = { identifier, count: 1 };
      this.visibleCounters[key] = data;
    }
  }

  untrackInbox(inbox: InboxIdentifier) {
    if (
      !this.usingPullBasedCounters &&
      !this.session.workspace.isFeatureEnabled('inbox2-pull-counters-metrics')
    ) {
      return;
    }

    let key = this.inboxKey(inbox);
    let data = this.visibleCounters[key];
    if (!data) {
      return;
    }
    data.count -= 1;
    if (data.count === 0) {
      this.nexus.unsubscribeTopics([this.inboxNexusTopic(inbox)]);
      delete this.visibleCounters[key];
    }
  }

  private inboxNexusTopic(inbox: InboxIdentifier) {
    return `inbox/${inbox.type}/${inbox.id}/InboxContentsChanged/counter`;
  }

  handleInboxContentsChangedEvent(e: InboxContentsChangedEvent) {
    let inboxes: InboxIdentifier[] = [];

    e.eventData.inboxes.forEach((inboxData) => {
      // we only care about updates which affect counters
      if (!('counter' in inboxData) || !inboxData.counter) {
        return;
      }
      let inbox: InboxIdentifier = {
        type: inboxData.type,
        id: inboxData.id,
      };

      let key = this.inboxKey(inbox);
      if (this.visibleCounters[key]) {
        inboxes.push(inbox);
      }
    });

    if (inboxes.length === 0) {
      return;
    }

    this.addPendingInboxCounterUpdates(inboxes);

    taskFor(this.fetchCountsForInboxesDebounced).perform();
  }

  private addPendingInboxCounterUpdates(inboxes: InboxIdentifier[]) {
    inboxes.forEach((inbox) => {
      this.pendingInboxCounterUpdates[`${inbox.type}-${inbox.id}`] = {
        type: inbox.type,
        id: inbox.id,
      };
    });
  }

  @task({ keepLatest: true }) *fetchCountsForInboxesDebounced(): TaskGenerator<void> {
    // if we're not visible then there's no point fetching counters
    // we'll trigger this again when we next become visible
    if (document.visibilityState === 'hidden') {
      return;
    }

    yield timeout(this.jitter(ENV.APP._100MS, ENV.APP._500MS)); // wait for the second event (admin-specific) to hit, with jitter
    let updatesToRequest = Object.values(this.pendingInboxCounterUpdates);
    if (updatesToRequest.length === 0) {
      return;
    }

    try {
      this.pendingInboxCounterUpdates = {};

      if (this.session.workspace.isFeatureEnabled('inbox2-pull-counters-metrics')) {
        let totals: Record<string, number> = {};
        updatesToRequest.forEach((inbox) => {
          totals[`num_${inbox.type}`] = (totals[`num_${inbox.type}`] || 0) + 1;
        });

        this.tracing.startRootSpan({
          name: 'inbox-list.pull-counters',
          resource: 'inbox-list',
          attributes: {
            num_inboxes: updatesToRequest.length,
            ...totals,
          },
        });
      }

      if (this.usingPullBasedCounters) {
        let inboxes: InboxIdentifierWithCount[] =
          yield this.inboxApi.fetchInboxCounters(updatesToRequest);
        this.updateCountsFromInboxes(inboxes);
      }
    } catch (e) {
      console.error('Error fetching counters', e);
      captureException(e);
      this.addPendingInboxCounterUpdates(updatesToRequest);
    } finally {
      yield timeout(this.jitter(ENV.APP._1000MS, ENV.APP._1500MS));
    }
  }

  jitter(min: number, max: number) {
    return min + Math.random() * (max - min);
  }

  refreshCounters(inboxes: InboxIdentifier[]) {
    this.addPendingInboxCounterUpdates(inboxes);
    taskFor(this.fetchCountsForInboxesDebounced).perform();
  }

  countForInbox(inbox: InboxIdentifier): number {
    return this.counts[this.inboxKey(inbox)] || 0;
  }

  decrementCount(inbox: InboxIdentifierWithCount) {
    let key = this.inboxKey(inbox);
    let counts = this.counts;
    let current = counts[key];
    if (current !== undefined && current > 0) {
      counts[this.inboxKey(inbox)] = current - 1;
      this.counts = counts;
    }

    this.updateCurrentInboxFavicon(inbox);
  }

  incrementCount(inbox: InboxIdentifierWithCount) {
    let key = this.inboxKey(inbox);
    let counts = this.counts;
    let current = counts[key];
    if (current !== undefined) {
      counts[this.inboxKey(inbox)] = current + 1;
      this.counts = counts;
    }

    this.updateCurrentInboxFavicon(inbox);
  }

  updateCount(inbox: InboxIdentifier, count: number, ttl?: number) {
    let counts = this.counts;
    counts[this.inboxKey(inbox)] = count;
    this.counts = counts;

    this.updateCurrentInboxFavicon(inbox);

    if (ttl) {
      this.trackCounterTTL(inbox, ttl);
    }
  }

  updateCountFromInbox(inbox: InboxIdentifierWithCount) {
    if (inbox.count !== undefined) {
      this.updateCount(inbox, inbox.count, inbox.valid_for);
    }
  }

  updateCountsFromInboxes(inboxes: InboxIdentifierWithCount[]) {
    inboxes.forEach((inbox) => {
      this.updateCountFromInbox(inbox);
    });
  }

  private trackCounterTTL(inbox: InboxIdentifier, ttl: number) {
    let key = this.inboxKey(inbox);
    this.ttlTasks[key]?.cancel();
    this.ttlTasks[key] = taskFor(this._trackCounterTTL).perform(inbox, ttl);
  }

  @task private *_trackCounterTTL(inbox: InboxIdentifier, ttl: number): TaskGenerator<void> {
    let key = this.inboxKey(inbox);
    yield timeout(ttl * ENV.APP._1000MS);
    if (this.visibleCounters[key]) {
      this.refreshCounters([inbox]);
    }
    delete this.ttlTasks[key];
  }

  private inboxKey(inbox: InboxIdentifier) {
    return `${inbox.type}-${inbox.id}`;
  }

  private updateCurrentInboxFavicon(inbox: InboxIdentifierWithCount) {
    if (isSameInbox(inbox, this.inboxState.activeInbox)) {
      let count = this.counts[this.inboxKey(inbox)];
      if (count !== undefined) {
        this.faviconCounter.updateFaviconCounter(count);
      }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxCounters: InboxCounters;
    'inbox2-counters': InboxCounters;
  }
}
