/* RESPONSIBLE TEAM: team-help-desk-experience */

/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import Velocity from 'velocity';
import Evented from '@ember/object/evented';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { useFunction } from 'ember-resources';
import { ajaxDelete } from 'embercom/lib/ajax';
import { type SidebarTabDetail } from 'embercom/lib/inbox2/types';
import { type EntityType } from 'embercom/models/data/entity-types';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type NewConversation } from 'embercom/objects/inbox/conversation';
import type DuplicateConversationSummary from 'embercom/objects/inbox/duplicate-conversation-summary';
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import type CallSummary from 'embercom/objects/inbox/renderable/call-summary';
import SidebarCallDetails from 'embercom/objects/inbox/sidebar-call-details';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import type User from 'embercom/objects/inbox/user';
import { RoleLabel } from 'embercom/objects/inbox/user-summary';
import type CopilotApi from 'embercom/services/copilot-api';
import type InboxApi from 'embercom/services/inbox-api';
import type IntlService from 'embercom/services/intl';
import type SidebarManager from 'embercom/services/sidebar-manager';
import type Snackbar from 'embercom/services/snackbar';
import storage from 'embercom/vendor/intercom/storage';
import type InboxState from './inbox-state';
import type Session from './session';
import { request } from 'embercom/lib/inbox/requests';
import { type CallingDetailWireFormat } from 'embercom/objects/inbox/call-detail';
import { CallingDetail } from 'embercom/objects/inbox/call-detail';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';

type KnowledgeBaseContentArgs = {
  entityId?: KnowledgeBaseContent['entityId'];
  entityType?: EntityType;
};

export interface SidebarSectionDetail {
  name: string;
  trackingName: string;
  label: string;
}

export enum SupportedSidebarSection {
  UserDetails = 'user-details',
  Notes = 'notes',
  Tags = 'tags',
  UserSegments = 'segments',
  CompanySegments = 'company-segments',
  LatestConversations = 'latest-conversations',
  SimilarConversations = 'similar-conversations',
  ConversationDetails = 'conversation-details',
  InAppChecklists = 'in-app-checklists',
  CompanyDetails = 'company-details',
  RecentEvents = 'recent-events',
  Qualification = 'qualification',
  RecentPageViews = 'recent-page-views',
  CompanyTags = 'company-tags',
  LinkedTickets = 'linked-tickets',
  LinkedConversation = 'linked-conversation',
  CompanyNotes = 'company-notes',
  LinkedGithubIssues = 'linked-github-issues',
  Shopify = 'shopify2',
  ExternalProfiles = 'external-profiles',
  TicketDetails = 'ticket-details',
  AssignmentQueuePosition = 'assignment-queue-position',
  TicketDescription = 'ticket-description',
}

export enum SidebarEvents {
  PreviewConversation = 'PreviewConversation',
  PreviewSideConversation = 'PreviewSideConversation',
}

export default class InboxSidebarService extends Service.extend(Evented) {
  @service declare intercomEventService: any;
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare sidebarManager: SidebarManager;
  @service declare copilotApi: CopilotApi;
  @service declare snackbar: Snackbar;

  readonly trackerTicketSidebarSections: Set<string> = new Set([
    SupportedSidebarSection.ConversationDetails,
    SupportedSidebarSection.LinkedConversation,
    SupportedSidebarSection.LinkedTickets,
  ]);

  @tracked openSidebarSections: Array<SupportedSidebarSection | number> = [
    SupportedSidebarSection.ConversationDetails,
    SupportedSidebarSection.TicketDetails,
    SupportedSidebarSection.TicketDescription,
    SupportedSidebarSection.LinkedConversation,
    SupportedSidebarSection.LinkedTickets,
  ];
  @tracked expandedSidebarSection?: SupportedSidebarSection | number;
  @tracked expandedCustomAttributeId?: string;
  @tracked expandedPotentialDuplicates?: User[] | null;

  @tracked isPreviewingConversation = false;

  @tracked isViewingStartLinkedConversation = false;
  @tracked isViewingNewSideConversationPanel = false;
  @tracked isViewingSideConversation = false;
  @tracked isDisplayingLinkTicketPanel = false;
  @tracked isSidebarCollapsed = false;
  @tracked isViewingPhoneDetails = false;
  @tracked isViewingKnowledgeBase = false;
  @tracked isViewingCopilotSourcePreview = false;
  @tracked isPreviewingLinkedItem = false;
  @tracked _testSidebarSections?: SidebarSection[];
  @tracked callId: string | null = null;
  @tracked sidebarCallDetails: SidebarCallDetails | null = null;

  @tracked knowledgeBaseContentArgs: KnowledgeBaseContentArgs = {};

  @tracked _activeTab: SidebarTabDetail['id'] = 'details';

  get isCopilotTabVisible() {
    return !this.copilotApi.isCopilotAccessBlocked;
  }

  get activeTab() {
    if (this.isCopilotTabVisible) {
      return this._activeTab;
    }

    return 'details';
  }

  constructor(owner: any) {
    super(owner);
    this.restoreOpenSections();
  }

  @action updateActiveTab(tabId: SidebarTabDetail['id']) {
    if (this._activeTab === tabId) {
      return;
    }

    this._activeTab = tabId;

    this.intercomEventService.trackAnalyticsEvent({
      object: 'sidebar_tab',
      action: 'switched',
      section: 'conversation_details_sidebar',
      tab: tabId,
    });
  }

  resetDefaults() {
    this.inboxState.showConversationList();
    this.sidebarManager.closeSidebar();
    this.expandedSidebarSection = undefined;
    this.expandedCustomAttributeId = undefined;
    this.expandedPotentialDuplicates = undefined;
    this.isViewingStartLinkedConversation = false;
    this.isViewingNewSideConversationPanel = false;
    this.isDisplayingLinkTicketPanel = false;
    this.isViewingSideConversation = false;
    this.isPreviewingConversation = false;
    this.isViewingKnowledgeBase = false;
    this._activeTab = 'details';
  }

  sidebarSectionDetails(roleLabel?: RoleLabel): {
    [key in SupportedSidebarSection]: SidebarSectionDetail;
  } {
    let role: RoleLabel = roleLabel || RoleLabel.User;
    return {
      [SupportedSidebarSection.UserDetails]: {
        name: 'data',
        trackingName: 'profile_data',
        label: this.intl.t(
          `inbox.conversation-details-sidebar.section-headings.${role.toLowerCase()}.user-details`,
        ),
      },
      [SupportedSidebarSection.Notes]: {
        name: 'notes',
        trackingName: 'profile_notes',
        label: this.intl.t(
          `inbox.conversation-details-sidebar.section-headings.${role.toLowerCase()}.notes`,
        ),
      },
      [SupportedSidebarSection.Tags]: {
        name: 'tags',
        trackingName: 'profile_tags',
        label: this.intl.t(
          `inbox.conversation-details-sidebar.section-headings.${role.toLowerCase()}.tags`,
        ),
      },
      [SupportedSidebarSection.UserSegments]: {
        name: 'segments',
        trackingName: 'profile_segments',
        label: this.intl.t(
          `inbox.conversation-details-sidebar.section-headings.${role.toLowerCase()}.segments`,
        ),
      },
      [SupportedSidebarSection.CompanySegments]: {
        name: 'company-segments',
        trackingName: 'company_segments',
        label: this.intl.t(`inbox.conversation-details-sidebar.section-headings.company.segments`),
      },
      [SupportedSidebarSection.LatestConversations]: {
        name: 'Recent conversations',
        trackingName: 'recent_conversations',
        label: this.intl.t(
          'inbox.conversation-details-sidebar.section-headings.latest-conversations',
        ),
      },
      [SupportedSidebarSection.SimilarConversations]: {
        name: 'Similar conversations',
        trackingName: 'similar_conversations',
        label: this.intl.t(
          'inbox.conversation-details-sidebar.section-headings.similar-conversations',
        ),
      },
      [SupportedSidebarSection.ConversationDetails]: {
        name: 'Attributes',
        trackingName: 'conversation_details',
        label: this.intl.t('inbox.conversation-details-sidebar.section-headings.attributes'),
      },
      [SupportedSidebarSection.InAppChecklists]: {
        name: 'Checklists',
        trackingName: 'in-app-checklists',
        label: 'Checklists',
      },
      [SupportedSidebarSection.CompanyDetails]: {
        name: 'company details',
        trackingName: 'company_details',
        label: this.intl.t(`inbox.conversation-details-sidebar.section-headings.company.details`),
      },
      [SupportedSidebarSection.RecentEvents]: {
        name: 'recent_events',
        trackingName: 'recent_events',
        label: this.intl.t(`inbox.conversation-details-sidebar.section-headings.recent-events`),
      },
      [SupportedSidebarSection.Qualification]: {
        name: 'qualification',
        trackingName: 'qualification',
        label: this.intl.t(`inbox.conversation-details-sidebar.section-headings.qualification`),
      },
      [SupportedSidebarSection.RecentPageViews]: {
        name: 'Recent page views',
        trackingName: 'recent_page_views',
        label: this.intl.t(`inbox.conversation-details-sidebar.section-headings.recent-page-views`),
      },
      [SupportedSidebarSection.CompanyTags]: {
        name: 'company tags',
        trackingName: 'company_tags',
        label: this.intl.t(`inbox.conversation-details-sidebar.section-headings.company.tags`),
      },
      [SupportedSidebarSection.LinkedTickets]: {
        name: 'Linking',
        trackingName: 'linked_tickets',
        label: this.intl.t('inbox.conversation-details-sidebar.section-headings.linked-tickets'),
      },
      [SupportedSidebarSection.LinkedConversation]: {
        name: 'Linked conversation',
        trackingName: 'linked_conversation',
        label: this.intl.t(
          'inbox.conversation-details-sidebar.section-headings.linked-conversation',
        ),
      },
      [SupportedSidebarSection.CompanyNotes]: {
        name: 'company notes',
        trackingName: 'company_notes',
        label: this.intl.t(`inbox.conversation-details-sidebar.section-headings.company.notes`),
      },
      [SupportedSidebarSection.LinkedGithubIssues]: {
        name: 'Github',
        trackingName: 'linked_github_issues',
        label: this.intl.t(
          `inbox.conversation-details-sidebar.section-headings.linked-github-issues`,
        ),
      },
      [SupportedSidebarSection.Shopify]: {
        name: 'Shopify',
        trackingName: 'shopify',
        label: this.intl.t(`inbox.conversation-details-sidebar.section-headings.shopify`),
      },
      [SupportedSidebarSection.ExternalProfiles]: {
        name: 'External profiles',
        trackingName: 'external_profiles',
        label: this.intl.t(`inbox.conversation-details-sidebar.section-headings.external-profiles`),
      },
      [SupportedSidebarSection.TicketDetails]: {
        name: 'Ticket attributes',
        trackingName: 'ticket_attributes',
        label: this.intl.t(`inbox.conversation-details-sidebar.section-headings.ticket-attributes`),
      },
      [SupportedSidebarSection.AssignmentQueuePosition]: {
        name: 'Assignment Queue Position',
        trackingName: 'assignment_queue_position',
        label: 'Assignment Queue Position',
      },
      [SupportedSidebarSection.TicketDescription]: {
        name: 'Description',
        trackingName: 'ticket_description',
        label: this.intl.t(
          `inbox.conversation-details-sidebar.section-headings.ticket-description`,
        ),
      },
    };
  }

  get sidebarSections() {
    return this.sidebarSectionsLoader.value || [];
  }

  get isLoading() {
    return this.sidebarSectionsLoader.isLoading;
  }

  get hasLoaded() {
    return this.sidebarSectionsLoader.value && !this.isLoading && !this.failedToLoad;
  }

  get failedToLoad() {
    return this.sidebarSectionsLoader.error;
  }

  @use sidebarSectionsLoader = AsyncData<SidebarSection[]>(async () => {
    if (this._testSidebarSections) {
      return this._testSidebarSections;
    }
    return this.inboxApi.listSidebarSections();
  });

  /** Returns all the sections that are available, including those that are not pinned */
  availableSidebarSections(conversation: ConversationRecord | NewConversation): SidebarSection[] {
    let sections = this.filterImplementedSections(this.availableSectionsLoader.value ?? []);
    if (!conversation.isTrackerTicket) {
      return sections;
    }

    return this.getTrackerTicketApps(sections);
  }

  /** Returns sections that have been pinned by the current teammate */
  pinnedSidebarSections(conversation: ConversationRecord | NewConversation): SidebarSection[] {
    let sections = this.filterImplementedSections(this.sidebarSections ?? []);
    if (!conversation.isTrackerTicket) {
      return sections;
    }

    return this.getTrackerTicketApps(sections);
  }

  private getTrackerTicketApps(sections: SidebarSection[]): SidebarSection[] {
    let generalApps = sections.filter((section) =>
      this.trackerTicketSidebarSections.has(section.componentIdentifier),
    );

    let installedApps = sections.filter((section) => {
      return section.isTrackerTicketModule;
    });

    return [...generalApps, ...installedApps];
  }

  hideConversationSidebar() {
    this.isSidebarCollapsed = true;
  }

  @action onExpandedSectionChange(
    sectionId?: SupportedSidebarSection | number,
    conversationId?: number,
  ) {
    let section = sectionId || this.expandedSidebarSection;
    if (conversationId && section) {
      this.intercomEventService.trackAnalyticsEvent({
        action: sectionId ? 'expanded' : 'collapsed',
        section,
        object: `${this.sidebarSectionTrackingName(section)}_details`,
        inbox_type: this.inboxState.activeInbox?.type,
        conversation_id: conversationId,
      });
    }

    this.expandedSidebarSection = sectionId;
  }

  @action onExpandCustomAttribute(attributeId?: string) {
    this.expandedCustomAttributeId = attributeId;
  }

  @action onExpandPotentialDuplicates(
    duplicates: User[] | null,
    moreExist: boolean,
    conversationId?: number,
  ) {
    this.expandedPotentialDuplicates = duplicates;

    this.intercomEventService.trackAnalyticsEvent({
      action: duplicates ? 'expanded' : 'collapsed',
      section: SupportedSidebarSection.UserDetails,
      object: 'potential_duplicate_details',
      inbox_type: this.inboxState.activeInbox?.type,
      conversation_id: conversationId,
      displayed_duplicates_count: duplicates?.length || 0,
      more_duplicates_exist: moreExist,
    });
  }

  @action onOpenSectionChange(
    sectionId: SupportedSidebarSection | number,
    conversationId?: number,
  ) {
    return (openSection?: SupportedSidebarSection | number) => {
      this.expandedSidebarSection = undefined;

      if (conversationId) {
        this.intercomEventService.trackAnalyticsEvent({
          action: openSection ? 'expanded' : 'collapsed',
          section: 'inbox',
          object: this.sidebarSectionTrackingName(sectionId),
          inbox_type: this.inboxState.activeInbox?.type,
          conversation_id: conversationId,
        });
      }

      if (openSection && !this.openSidebarSections.includes(openSection)) {
        this.openSidebarSections = [...this.openSidebarSections, sectionId];
      } else {
        this.openSidebarSections = this.openSidebarSections.reject(
          (openSectionId) => openSectionId === sectionId,
        );
      }

      this.persistOpenSections();
    };
  }

  @action toggleSidebarVisibility() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
  }

  @action updateSidebarSections(sections: SidebarSection[]) {
    this.sidebarSectionsLoader.update(sections);
    this.inboxApi.updateSidebarSections(sections);
  }

  @action previewConversation(
    conversation: Conversation | DuplicateConversationSummary,
    selectedTab?: string,
    shouldFocusComposer?: boolean,
  ) {
    this.inboxState.hideConversationList();
    this.isPreviewingLinkedItem = true;
    this.inboxState.expandRHSBtoHalfScreen();

    this.isViewingKnowledgeBase = false;
    this.toggleSidebarIfHidden();
    this.trigger(
      SidebarEvents.PreviewConversation,
      conversation.id,
      selectedTab,
      shouldFocusComposer,
    );
  }

  previewSideConversation(
    sideConversationId: number,
    parentConversationId: number,
    shouldFocusComposer?: boolean,
  ) {
    this.isViewingKnowledgeBase = false;
    this.isViewingNewSideConversationPanel = false;
    this.toggleSidebarIfHidden();
    this.isViewingSideConversation = true;
    this.inboxState.expandRHSBtoHalfScreen();
    this.inboxState.hideConversationList();
    this.trigger(
      SidebarEvents.PreviewSideConversation,
      sideConversationId,
      parentConversationId,
      shouldFocusComposer,
    );
  }

  previewMergedConversation(_mergedConversationId: number, _primaryConversationId: number) {
    console.info('previewMergedConversation');
  }

  @action previewConversationById(
    conversationId: number,
    args?: {
      isPreviewingFromCopilot?: boolean;
      selectedTab?: string;
      shouldFocusComposer?: boolean;
    },
  ) {
    this.isViewingKnowledgeBase = false;
    this.toggleSidebarIfHidden();
    this.trigger(
      SidebarEvents.PreviewConversation,
      conversationId,
      args?.selectedTab,
      args?.shouldFocusComposer,
      args?.isPreviewingFromCopilot,
    );
  }

  @action startLinkedConversation() {
    this.isViewingKnowledgeBase = false;
    // Close side conversation preview if open
    this.isViewingNewSideConversationPanel = false;
    this.toggleSidebarIfHidden();
    this.toggleIsViewingStartLinkedConversation({ visibility: true });
    this.inboxState.hideConversationList();
    this.inboxState.expandRHSBtoHalfScreen();
  }

  @action closeCreatingLinkedConveration() {
    this.inboxState.showConversationList();
    this.toggleIsViewingStartLinkedConversation({ visibility: false });
  }

  openNewSideConversationPanel() {
    this.isViewingKnowledgeBase = false;
    this.toggleSidebarIfHidden();
    this.isViewingNewSideConversationPanel = true;
    this.inboxState.hideConversationList();
    this.inboxState.expandRHSBtoHalfScreen();
    // Close side conversation preview if open
    this.isViewingSideConversation = false;
    // Close creating linked conversation if open
    this.toggleIsViewingStartLinkedConversation({ visibility: false });
    this.isPreviewingConversation = false;
  }

  @action closeNewSideConversationPanel() {
    this.isViewingNewSideConversationPanel = false;
    this.inboxState.showConversationList();
  }

  openKnowledgeBasePanel(conversationId?: Conversation['id'], activeParticipantId?: string) {
    this.isViewingKnowledgeBase = true;
    // Close side conversation preview if open
    this.isViewingNewSideConversationPanel = false;
    this.isViewingSideConversation = false;
    this.isPreviewingConversation = false;
    this.sidebarManager.openSheet({
      name: 'knowledge-panel',
      conversationId: conversationId ?? -1,
      activeParticipantId: activeParticipantId ?? '-1',
    });
    // Open the RHSB behind the knowledge sheet if it's not already open
    this.toggleSidebarIfHidden();
    this.inboxState.hideConversationList();
  }

  @action closeKnowledgeBasePanel() {
    this.sidebarManager.closeSheet('knowledge-panel');
    this.isViewingKnowledgeBase = false;
    this.inboxState.showConversationList();
  }

  @action
  openCopilotSourcePreview(entityId: number, entityType: EntityType) {
    this.knowledgeBaseContentArgs = {
      entityId,
      entityType,
    };

    this.toggleSidebarIfHidden();
    this.isViewingCopilotSourcePreview = true;
    this.isViewingNewSideConversationPanel = false;
    this.isViewingSideConversation = false;
    this.isPreviewingConversation = false;
  }

  @action closeCopilotSourcePreview() {
    this.isViewingCopilotSourcePreview = false;
    this.knowledgeBaseContentArgs = {};
  }

  @action openCopilot() {
    this.toggleSidebarIfHidden();

    if (this._activeTab !== 'copilot') {
      this._activeTab = 'copilot';
    }
  }

  @action openConversationDetails() {
    this.toggleSidebarIfHidden();
    this._activeTab = 'details';
  }

  @action async toggleIsViewingPhoneDetails(callSummary: CallSummary) {
    this.toggleSidebarIfHidden();

    if (!this.isViewingPhoneDetails) {
      this.isViewingPhoneDetails = true;
      // Handle the back button interaction which has no id
    } else if (!callSummary.id || this.sidebarCallDetails?.id === callSummary.id) {
      this.isViewingPhoneDetails = false;
      return;
    }

    this.callId = callSummary.id;
    await this.callingDetails(callSummary);
  }

  async callingDetails(callSummary: CallSummary) {
    if (!callSummary) {
      return;
    }

    this.sidebarCallDetails = new SidebarCallDetails(callSummary);

    let response = await request(
      `/ember/calling_details/${callSummary.id}?app_id=${this.session.workspace.id}`,
    );
    let wireCallDetails = (await response.json()) as CallingDetailWireFormat;
    await this.sidebarCallDetails.addCallDetails(CallingDetail.deserialize(wireCallDetails));
  }

  @action
  async deleteRecording() {
    if (!this.sidebarCallDetails) {
      return;
    }

    try {
      await ajaxDelete(
        `/ember/calling_details/${this.sidebarCallDetails.id}?app_id=${this.session.workspace.id}`,
      );
      this.snackbar.notify(this.intl.t('inbox.phone-details-sidebar.transcript.deleted'));
      this.sidebarCallDetails.recordingDeleted = true;
    } catch {
      this.snackbar.notifyError(
        this.intl.t('inbox.phone-details-sidebar.transcript.deletion-failed'),
      );
      this.sidebarCallDetails.recordingDeleted = false;
    }
  }

  @action toggleIsViewingStartLinkedConversation(options?: { visibility: boolean }) {
    if (options?.visibility !== undefined) {
      this.isViewingStartLinkedConversation = options.visibility;
    } else {
      this.isViewingStartLinkedConversation = !this.isViewingStartLinkedConversation;
    }
  }

  sidebarSectionTrackingName(sectionId: SupportedSidebarSection | number) {
    if (typeof sectionId === 'number' || !(sectionId in SupportedSidebarSection)) {
      return `inbox_app_${sectionId}`;
    }
    return this.sidebarSectionDetails()[sectionId]?.trackingName;
  }

  stopPreviewingConversation() {
    this.trigger(SidebarEvents.PreviewConversation, null);
  }

  @task
  *scrollToSection(sectionId: SupportedSidebarSection | number, waitBeforeScroll: boolean) {
    let container = document.querySelector('[data-sidebar-section-container]');
    let section = document.querySelector(`[data-sidebar-section-id="${sectionId}"]`);
    let timeoutMs = 250;
    if (!container) {
      return;
    }

    if (this.isSidebarCollapsed) {
      this.isSidebarCollapsed = false;
      timeoutMs = 350;
    }
    // If we scoll without waiting the position top of the section we want to scroll to
    // may have changed because the sections animate open/closed.
    // TODO - using a timeout isn't accurate. Ideally we could chain this scroll
    // to the end of the accordion section toggle animation
    if (waitBeforeScroll) {
      yield timeout(timeoutMs);
    }
    yield Velocity(container, 'stop');
    yield Velocity(section, 'scroll', {
      duration: 250,
      offset: 0,
      container,
    });

    this.focusSectionHeader(sectionId);
  }

  private toggleSidebarIfHidden() {
    if (this.isSidebarCollapsed) {
      this.toggleSidebarVisibility();
    }
  }

  private focusSectionHeader(sectionId: SupportedSidebarSection | number) {
    let header = document.querySelector(
      `[data-sidebar-section-header="${sectionId}"]`,
    ) as HTMLDivElement;
    if (!header) {
      return;
    }

    header.focus();
  }

  private get storageKey() {
    return `open-sidebar-sections-${this.session.workspace.id}`;
  }

  private persistOpenSections() {
    storage.set(this.storageKey, this.openSidebarSections);
  }

  private restoreOpenSections() {
    let sections = storage.get(this.storageKey);
    if (!sections) {
      return;
    }

    this.openSidebarSections = sections;
  }

  /** Returns a filtered list of sidebar sections that are supported (i.e. have been implemented by us) */
  private filterImplementedSections(sections: SidebarSection[]) {
    let supportedSections: string[] = Object.values(SupportedSidebarSection);

    return sections.filter((section) => {
      if (section.componentIdentifier === SupportedSidebarSection.LinkedTickets) {
        return false;
      }

      return (
        supportedSections.includes(section.componentIdentifier) ||
        section.componentIdentifier === 'app-card'
      );
    });
  }

  private availableSectionsLoader = useFunction(
    this,
    async (previous: SidebarSection[]) => {
      if (previous) {
        return previous;
      }

      return await this.inboxApi.listAvailableSidebarSections();
    },
    () => [],
  );
}

declare module '@ember/service' {
  interface Registry {
    inboxSidebarService: InboxSidebarService;
    'inbox-sidebar-service': InboxSidebarService;
  }
}
