/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import type SearchableDocument from 'embercom/objects/inbox/searchable-document';
import fuzzysort from 'fuzzysort';
import latinize from 'latinize';
import { get } from '@ember/object';

// This is the shape of the sort result coming out of the FuzzySort library
interface FuzzySortLibraryResult<T> {
  score: number;
  obj: T;
  target: string;
  highlight: (open: string, close: string) => string | undefined;
}

interface FuzzySortOptions {
  highlight?: boolean;
  threshold?: number;
  limit?: number;
}

export function prepareStringForFuzzySearch(str: string) {
  return fuzzysort.prepare(latinize(str).toLocaleLowerCase());
}

export default class FuzzySearch extends Service {
  // This method latinizes and localizes the search term and also the items being searched upon before performing a search
  // While returning the highlighted value, instead of returning the latinised lower cased item, it returns the original item
  search(
    searchTerm: string,
    items: SearchableDocument[],
    key: string,
    options: FuzzySortOptions = {},
  ): SearchableDocument[] {
    let highlight = options.highlight ?? true;
    // Change threshold to match library's 0-1 scale (where 0 means no match)
    let threshold = options.threshold ?? 0;
    let limit = options.limit ?? Infinity;

    let latinizedSearchTerm = this.toLatinizeLocaleLowerCase(searchTerm);

    let searchResults = fuzzysort.go(latinizedSearchTerm, items, {
      threshold,
      key: 'preparedFuzzySearchData',
      limit,
    });

    // Filter results more intelligently
    let results = searchResults.filter((result) => {
      // Get the actual matched text
      let originalText = result.target.toLowerCase();

      // If exact match (case insensitive), allow it
      if (originalText.includes(latinizedSearchTerm)) {
        return true;
      }

      // For very short searches (3 chars or less), only match start of words
      if (latinizedSearchTerm.length <= 3) {
        let words = originalText.split(' ');
        return words.some((word) => word.startsWith(latinizedSearchTerm));
      }

      // For typos in words (like 'rport' -> 'report')
      // Use more lenient threshold for longer search terms
      if (result.score > 0.3) {
        return true;
      }

      return false;
    });

    return results.map((item) =>
      highlight ? item.obj.withHighlight(this.getHighlightedValue(item, key)) : item.obj,
    );
  }

  private getHighlightedValue(item: FuzzySortLibraryResult<SearchableDocument>, key: string) {
    item.target = get(item.obj, key) as string;
    return item.highlight('<mark>', '</mark>') || undefined;
  }

  private toLatinizeLocaleLowerCase(value: string) {
    return latinize(value).toLocaleLowerCase();
  }
}

declare module '@ember/service' {
  interface Registry {
    fuzzySearch: FuzzySearch;
    'fuzzy-search': FuzzySearch;
  }
}
