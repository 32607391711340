/* RESPONSIBLE TEAM: team-workflows */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { later, cancel } from '@ember/runloop';
import { get } from 'embercom/lib/ajax';
import type Session from 'embercom/services/session';

import type WorkflowEditorService from 'embercom/services/workflow-editor-service';

const LOCALIZATION_POLLING_INTERVAL = 10000;
const MAX_POLLING_DURATION = 600000;

export default class WorkflowLocalizationService extends Service {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare contentEditorService: $TSFixMe;
  @service declare workflowEditorService: WorkflowEditorService;
  @service declare session: Session;
  @tracked selectedLocaleId: string | null = this.defaultLocaleId;
  @tracked isLoadingLocales = false;

  _pollingTimer: ReturnType<typeof later> | null = null;
  _pollingStartTime: number | null = null;
  _hasStartedPolling = false;

  get languages() {
    return this.store.peekRecord('messenger-settings/languages', this.appService.app.id);
  }

  get availableLocalesForEditor() {
    return (
      this.languages?.supportedLocales.filter(
        (locale) => locale.isPermitted || locale.localeId === this.defaultLocaleId,
      ) || []
    );
  }

  get defaultLocale() {
    return this.availableLocalesForEditor.find(
      (locale) => locale.localeId === this.defaultLocaleId,
    )!;
  }

  get defaultLocaleId() {
    return this.languages?.defaultLocale || 'en';
  }

  get selectedLocale() {
    return (
      this.availableLocalesForEditor.find((locale) => locale.localeId === this.selectedLocaleId) ||
      this.defaultLocale
    );
  }

  get selectedLocaleName() {
    return this.selectedLocale.name;
  }

  get isMultilingualEnabled(): boolean {
    return this.contentEditorService.activeObject?.botData?.multilingualEnabled ?? false;
  }

  @action
  selectLanguage(language: string) {
    this.selectedLocaleId = language;
  }

  @action
  async startPolling() {
    if (this._hasStartedPolling) {
      return;
    }
    this.pollForTranslations();
  }

  async pollForTranslations() {
    if (this.contentEditorService.isEditMode || !this.isMultilingualEnabled) {
      return;
    }

    if (this._pollingStartTime && Date.now() - this._pollingStartTime > MAX_POLLING_DURATION) {
      this.isLoadingLocales = false;
      return;
    }

    if (!this._hasStartedPolling) {
      this._hasStartedPolling = true;
      this._pollingStartTime = Date.now();
    }

    let localizationsStatusResponse = await this.checkLocalizationStatus();

    if (localizationsStatusResponse.localizations_ready) {
      this.isLoadingLocales = false;
      this.contentEditorService?.ruleset?.reload();
    } else {
      this.isLoadingLocales = true;
      this.scheduleNextPoll();
    }
  }

  async checkLocalizationStatus() {
    return get(
      `/ember/operator_custom_bots/${this.contentEditorService.parentRulesetId}/localization_status`,
      {
        app_id: this.session.workspace.id,
      },
    );
  }

  private scheduleNextPoll() {
    this._pollingTimer = later(this, this.pollForTranslations, LOCALIZATION_POLLING_INTERVAL);
  }

  willDestroy() {
    super.willDestroy();
    if (this._pollingTimer) {
      cancel(this._pollingTimer);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    workflowLocalizationService: WorkflowLocalizationService;
    'workflow-localization-service': WorkflowLocalizationService;
  }
}
