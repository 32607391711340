/* RESPONSIBLE TEAM: team-channels */
import type Admin from 'embercom/models/admin';
import { firstName } from 'embercom/helpers/first-name';
import HasFeatureHelper from 'embercom/helpers/has-feature';

// Define the shape of Admin properties we need
interface UserFacingAdmin extends Admin {
  alias_enabled: boolean;
  admin_enabled: boolean;
  alias_name: string;
  alias_email: string;
  first_name: string;
  name: string;
  email: string;
}

export default class UserFacingAdminProxy {
  private admin: UserFacingAdmin;
  private hasFeatureHelper: HasFeatureHelper;

  constructor(admin: Admin) {
    this.admin = admin as UserFacingAdmin;
    // @ts-ignore
    this.hasFeatureHelper = HasFeatureHelper.create(admin);
  }

  private get shouldUseAlias(): boolean {
    return Boolean(
      this.admin.alias_enabled &&
        this.admin.alias_name &&
        this.hasFeatureHelper.compute(['team-messenger-admin-alias']),
    );
  }

  get first_name(): string {
    if (this.shouldUseAlias) {
      return firstName(this.admin.alias_name);
    }
    return this.admin.first_name;
  }

  get name(): string {
    if (this.shouldUseAlias) {
      return this.admin.alias_name;
    }
    return this.admin.name;
  }

  get email(): string {
    if (this.shouldUseAlias) {
      return this.admin.alias_email || this.admin.email;
    }
    return this.admin.email;
  }

  get firstNameOrNameOrEmail(): string {
    return this.first_name || this.name || this.email;
  }

  get localized_sender_name(): string {
    if (this.shouldUseAlias) {
      let alias_first_name = this.first_name;
      let first_name = this.admin.first_name;
      let localized_sender_name = this.admin.localized_sender_name;
      return localized_sender_name.replace(first_name, alias_first_name);
    }
    return this.admin.localized_sender_name;
  }

  // Proxy all other properties directly to the admin object
  get(key: string): any {
    if (key in this) {
      return (this as any)[key];
    }
    return (this.admin as any)[key];
  }
}
