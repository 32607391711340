/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { getOwner } from '@ember/application';
import { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { minimumSeatsForSolutionId, PRICING_5_X_ADVANCED_SOLUTION_IDS } from 'embercom/lib/billing';

export default Service.extend({
  realTimeEventService: service(),
  cardlessTrialService: service(),
  store: service(),
  app: computed('refreshCount', function () {
    return getOwner(this).lookup('route:apps/app').get('app');
  }),

  refresh() {
    this.incrementProperty('refreshCount');
  },

  init() {
    this._super(...arguments);
    this.enableRealtimeHandlers();
  },

  async loadApp(appId) {
    if (!this.app) {
      let appRoute = getOwner(this).lookup('route:apps/app');
      let app = await appRoute.model({
        app_id: appId,
      });
      appRoute.set('app', app);
      this.refresh();
    }
  },

  enableRealtimeHandlers() {
    this.realTimeEventService.on('MentionCreated', this, 'updateUnreadMentionCounts');
  },

  minimumSeats(solutionId) {
    return Math.max(minimumSeatsForSolutionId(solutionId), this.app.humanAdmins?.length);
  },

  defaultSeatQueryParameterValue(solutionId) {
    let seatsIncludedInSolution = minimumSeatsForSolutionId(solutionId);
    let seatsUsedByTeammates = this.teammateSeats(solutionId);
    if (seatsUsedByTeammates > seatsIncludedInSolution) {
      return seatsUsedByTeammates;
    } else {
      return 0;
    }
  },

  teammateSeats(solutionId) {
    if (
      PRICING_5_X_ADVANCED_SOLUTION_IDS.includes(solutionId) &&
      !this.cardlessTrialService.trialHasEnded
    ) {
      return this.app.humanAdminsWithCoreSeat?.length || 0;
    }
    return this.app.humanAdmins?.length || 0;
  },

  updateUnreadMentionCounts(event) {
    if (event.adminId?.toString() === this.app.currentAdmin.id.toString()) {
      this.set('app.unread_mention_threads', event.unreadCount);
    }
  },

  scheduleAppCountersPoll: task(function* () {
    if (ENV.environment === 'test') {
      return;
    }
    yield timeout(ENV.APP._1M);
    this.app.refreshNavCounters();
    this.scheduleAppCountersPoll.perform();
  }).restartable(),
});
